<template>
  <PopWidget width="7.08rem" :isShow="isShowPopWidget" :isFull="1">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/activity/lingjiang/title.png" />
    </template>
    <template v-slot:content>
      <div class="report-content flex">
        <div class="card-item" v-for="(item,i) of dataList" 
          :class="{current:i == innerItemIndex,prev:i == innerItemPrvIndex}">
          <div class="inner-item p-relative" @click="innerItemPrvIndex = innerItemPrvIndex == i?-1:i"
            :class="{current:i == innerItemIndex || i == innerItemPrvIndex}">
            <div class="item-bg1 p-relative"></div>
            <div class="inner-item-content flex">
              <div class="des-item">第{{getNumberChinese(i)}}次</div>
              <div class="plus">+</div>
              <div class="des-item importants">
                {{item.gift_ratio}}%
                <span>奖励</span>
              </div>
              <div class="des-item">最小充值:{{item.min_recharge}}</div>
              <div class="des-item">最多可获取:{{item.max_amount}}</div>
            </div>
          </div>

          <div class="process" v-if="item.total > 0 && item.unlocked < item.total">
            <div class="inner"></div>
            <div class="value">
              {{item.unlocked?item.unlocked:0}}/{{item.total?item.total:0}}
            </div>
          </div>
          <div class="btn-item" @click="btnClickHandle(item,i)" v-else
            :class="{current:i == innerItemIndex,prev:i == innerItemPrvIndex,'gotted':item.is_get}">
            {{item.is_get == 1?'已获取':item.total > 0 && item.unlocked >= item.total ? '领取':'充值'}}
          </div>

        </div>
      </div>
    </template>
  </PopWidget>
</template>

<script>
import Request from '@/common/Request';
import Tool from '@/common/Tool';
import PopWidget from '../common/PopWidget.vue'
import { mapMutations } from 'vuex';
export default {
  props:{
    show:Number,
  },
  data(){
    return{
      isShowPopWidget:0,
      dataList:[],
      innerItemIndex:0,
      innerItemPrvIndex:-1,
    }
  },
  mounted(){},
  methods:{
    ...mapMutations(['showLoading','closeLoading']),
    getNumberChinese(i){
      let s = '一,二,三,四,五,六,七,八,九,十,十一,十二,十三,十四,十五,十六,十七,十八,十九,二十,二十一'
      return s.split(',')[i];
    },
    btnClickHandle(amountItem,i){
      if(amountItem.is_get == 1){
        return;
      }
      if(i == this.innerItemPrvIndex && i != this.innerItemIndex){
        return;
      }
      
      if(!amountItem.total){
        this.$emit('go_recharge');
        this.isShowPopWidget = 0;
      }else{
        if(amountItem.unlocked >= amountItem.total){

        }
      }
      
    },
    getDataApi(){
      this.showLoading();
      let cachData = Tool.getEventRecharge();
      if(cachData && cachData.length){
        this.dataList = cachData;
        this.closeLoading();
        return;
      }
      Request.post('/activity/event_recharge').then(res => {
        if(res.data.code == 0 && res.data.data){
          this.dataList = res.data.data;
          Tool.setEventRecharge(res.data.data);
        }
        this.closeLoading();
      }).catch(() => this.closeLoading())
    },
  },
  watch:{
    show(n){
      if(n){
        this.isShowPopWidget = n;
        this.getDataApi();
      }
    }
  },
  components:{
    PopWidget
  }
}
</script>

<style scoped lang="stylus">
@keyframes itemBgAnimation {
  from{
    transform scale(2) rotateZ(1deg)
  }
  to{
    transform scale(2) rotateZ(360deg)
  }
}
.report-content
  width 100%
  height 2.1rem
  padding .2rem
  overflow-y hidden
  overflow-x auto
  .card-item
    width 1.46rem
    height 1.66rem
    color #ffffff
    flex-shrink 0
    border-radius .2rem
    margin-left .2rem
    position relative
    &:after
      content ' '
      width 100%
      height .48rem
      background #ffffff50
      position absolute
      bottom 0
      left 0
      border-radius 0 0 .1rem .1rem
    &.prev,&.current
      &:after
        bottom .11rem
    .process
      width 100%
      line-height .2rem
      height .2rem
      border-radius .2rem
      background #a16515
      position relative
      overflow hidden
      .inner
        width 30%
        height 100%
        border-radius .2rem
        background #c27b1b
      .value
        width 100%
        height 100%
        position absolute
        left 0
        top 0
        z-index 1
    .btn-item
      width 74%
      margin auto
      line-height .28rem
      background-color #dd9c2f
      border-radius .2rem
      margin-top .43rem
      display none
      &.current
        display block
        background-color #dd9c2f !important
        color #ffffff !important
      &.prev
        display block
        background-color #a5a4a0
        color #d6d6d6
      &.gotted
        background-color #53514e
        color #a8a6a6
    .inner-item
      width 100%
      height 70%
      z-index 1
      margin-top .15rem
      background-size 100% 100%
      border-radius .2rem
      transition margin-top .2s
      .inner-item-content
        width 100%
        height auto
        z-index 2
        position absolute
        left 0
        top 0
        padding-top .08rem
        color #000
        flex-direction column
        .plus
          width .28rem
          height .28rem
          line-height .28rem
          font-size .28rem
          font-weight bold
          background-color #f6c648
          border-radius .04rem
          margin .15rem auto .1rem auto;
        .des-item
          height .14rem
          line-height .14rem
          text-align left
          color #545454c0
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          padding-left .08rem
          &:first-child
            color #545454
            text-align center
            height .22rem
            background transparent
            padding-left 0
          &.importants
            padding-left 0
            text-align center
            margin-bottom .14rem
            height .22rem
            font-size .18rem
            line-height .22rem
            color #000000
            span
              font-size .12rem
      .effect-coin
        display none
        position absolute
        z-index 2
        width .37rem
        right 0
        &.left
          left 0
          bottom .01rem
          width .36rem
      .item-bg1
        width 100%
        height 96%
        border-radius .2rem
        overflow hidden
        &:before
          width 2rem
          height 2rem
          top -.4rem
          left -.26rem
          transform scale(2)
          content ' '
          position absolute
          background-size 100% 100%
      &.current
        margin-top 0
        .item-bg1
          &:before
            animation itemBgAnimation 5s infinite linear
        .effect-coin
          display block
    &:first-child
      margin-left 0

    &:nth-child(4n)
      .inner-item
        background-image url(../../assets/activity/lingjiang/bg4.svg)
        .item-bg1
          &:before
            background-image url(../../assets/activity/lingjiang/mask4.png)
    &:nth-child(4n+1)
      .inner-item
        background-image url(../../assets/activity/lingjiang/bg1.svg)
        .item-bg1
          &:before
            background-image url(../../assets/activity/lingjiang/mask1.png)
    &:nth-child(4n+2)
      .inner-item
        background-image url(../../assets/activity/lingjiang/bg2.svg)
        .item-bg1
          &:before
            background-image url(../../assets/activity/lingjiang/mask2.png)
    &:nth-child(4n+3)
      .inner-item
        background-image url(../../assets/activity/lingjiang/bg3.svg)
        .item-bg1
          &:before
            background-image url(../../assets/activity/lingjiang/mask3.png)
</style>