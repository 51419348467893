<template>
  <PopWidget :isShow="isShowPopWidget" 
    :isFull="1" width="5.18rem" style="z-index:5;">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/email/email-detail-title.png" />
    </template>
    <template v-slot:content>
      <div class="m-t-c">
        <div class="msg-text-wrap">
          {{ email.msg_text }}
        </div>
      </div>
      <div>
        <img class="delete-btn ease-btn-effe" @click="emailDeleteClick"
          src="../../assets/email/delete-btn.png" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import PopWidget from '../common/PopWidget.vue'

export default{
  props:{
    email:Object,
    show:Number,
  },
  data(){
    return{
      isShowPopWidget:0,
    }
  },
  methods:{
    emailDeleteClick(){
      this.$emit('delete')
      this.isShowPopWidget = 0;
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget
  }
}
</script>

<style scoped lang="stylus">
.delete-btn
  width 1rem
  margin-bottom .12rem
.m-t-c
  width 100%
  padding-top 1px
.msg-text-wrap
  width 95%
  height 1.28rem
  padding .08rem
  text-align left
  border-radius .06rem
  margin .12rem auto .1rem auto
  background-color #776052
  color #FBDFC4
</style>