<template>
  <div class="home-game-menu" :class="{dzyy:invoke == 1}"
    :style="homeGameMenuStyle" @touchmove.stop="" >
    <div class="bg"></div>
    <div class="item-wrapper" ref="itemWrapScroll">

      <div class="item-menu" v-for="(data,i) of menuData"
        @click.stop="gameMenuClick(data,i)" :data-id="data.id"
        :style="{display:`${data.show ? 'block':'none'}`}">
        <div class="i-m-inner">
          <img class="item-ico" :src="data.ico" />
          <img class="item-img" :src="data.item" 
            v-if="data.item && data.id != menuSelected" />
          <img class="item-img" :src="data.itemFocus" v-if="
            data.itemFocus && data.id == menuSelected" />

          <div class="title" v-if="!data.item && data.title"
            :class="{focus:data.id == menuSelected}">
            {{ data.title }}
          </div>
        </div>
        <img class="item-border" src="../assets/home/game/menu_item_border.png" />
        <div class="focus-bg" v-if="data.id == menuSelected"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Tool from '@/common/Tool';
import { mapGetters, mapMutations } from 'vuex';
import Request from '../common/Request.js'
export default {
  props:{
    invoke:String | Number, //1 电子游艺, 3 棋牌
    preMenuId:Number|String,
  },
  data(){
    return {
      menuData:[],
      itemBorder:'',
      menuSelected:0,
      // 电子游艺菜单
      dzyyMenu:[],
      homeGameMenuStyle:{},
      dzyyMenuSource:[],
      qipaiMenuSource:[],
    }
  },
  mounted(){
    setTimeout(() => {
      if(!this.invoke){
        this.layout();
        this.getMainMenuList();
      }
    });
    
  },
  methods:{
    ...mapMutations(['popMsg','closeLoading','showLoading']),
    mainMenuDataHandle(dataList){
      this.menuData.forEach((menuItem,i) => {
        let found_i = false;
        dataList.forEach(code => {
          if(menuItem.id == code){
            found_i = true;
          }
        })
        if(found_i || menuItem.id == -1){
          menuItem.show = 1;
        }else{
          menuItem.show = 0;
        }
      })

    },
    // 0表示真人视讯，
    // 1表示电子游艺，
    // 2表示彩票游戏，
    // 3表示棋牌游戏，
    // 4表示体育赛事，
    // 5表示扑鱼游戏
    getMainMenuList(callback){
      const _this = this;
      let localMenuList = Tool.getLocalMainMenuList()
      if(localMenuList && localMenuList.length){
        _this.mainMenuDataHandle(localMenuList);
        if(callback) callback();
        _this.getMainMenuListUpdateCach();
        return;
      }
      Request.post('/index/index').then(res => {
        if(res.data && res.data.data && res.data.data.length){
          Tool.setLocalMainMenuList(res.data.data);
          _this.mainMenuDataHandle(res.data.data);
          if(callback) callback();
        }
      }).catch(error =>  {
        if(error.response.data.code == 1000006){
          Request.userOutted(_this)
          // _this.$router.push({name:'login'})
        }
      })
    },
    getMainMenuListUpdateCach(){
      let cacheKey = 'local-main-menu-list';
      let cachKeyDicts = Tool.getReloadIsUpdate();
      if(cacheKey in cachKeyDicts) return;

      Request.post('/index/index').then(res => {
        if(res.data && res.data.data && res.data.data.length){
          Tool.setLocalMainMenuList(res.data.data);
        }
      }).catch(error =>  {
        if(error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      })

    },
    //显示电子游艺或棋牌菜单
    getGameMenu(){
      if(Tool.getDzyyMenu(this.invoke)){
        this.dzyyMenu = Tool.getDzyyMenu(this.invoke);
        this.showDzyyMenu();
        this.getGameMenuUpdateCach();
        return;
      }
      this.showLoading();
      Request.post('/index/platform',{type:this.invoke}).then(res => {
        this.closeLoading()
        Tool.setDzyyMenu(res.data.data,this.invoke);
        this.dzyyMenu = res.data.data
        this.showDzyyMenu();
      }).catch(error =>  {
        this.closeLoading()
        if(error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      })
    },
    //更新电子游艺或棋牌菜单缓存
    getGameMenuUpdateCach(){
      let cacheKey = Tool.getDzyyMenuDataCachKey(this.invoke);
      if(cacheKey){
        let cachKeyDicts = Tool.getReloadIsUpdate();
        if(cacheKey in cachKeyDicts) return;

        Request.post('/index/platform',{type:this.invoke}).then(res => {
          Tool.setDzyyMenu(res.data.data,this.invoke);
        }).catch(error =>  {
          if(error.response.data.code == 1000006){
            Request.userOutted(this)
          }
        })

      }
    },
    showDzyyMenu(){
      let firstShowMenu = -1,prevI = -1;
      this.menuData = [];
      this.dzyyMenu.forEach((dzyy,i) => {

        let found = -1;
        let dzyyCode = dzyy.platform_code;

        let sourceMenu = [];
        if(this.invoke == 3){
          sourceMenu = this.qipaiMenuSource;
        }else if(this.invoke == 1){
          sourceMenu = this.dzyyMenuSource;
        }
        sourceMenu.forEach((mData,iSource) => {
          if(mData.id == dzyy.id){
            found = iSource;
          }
        });

        let menuData = {};
        if(found > -1){
          menuData = sourceMenu[found];
          menuData.show = 1;
          menuData.platformCode = dzyyCode;
          menuData.title = dzyy.alia_name||dzyy.plat_name;
        }else{
          menuData = {
            id:dzyy.id,
            ico:dzyy.icon || dzyy.label_img,
            item:'',
            itemFocus:'',
            title:dzyy.alia_name||dzyy.plat_name,
            show:1,
            platformCode:dzyyCode
          };
        }
        // 地址栏传递的预选菜单
        if(this.preMenuId && this.preMenuId == dzyy.id){
          prevI = i;
        }
        if(firstShowMenu == -1){
          firstShowMenu = i;
        }

        this.menuData.push(menuData);
      });

      // this.checkIsNewMenu()

      let select_i = 0;
      if(prevI != -1){
        select_i = prevI;
      }else if(firstShowMenu != -1){
        select_i = firstShowMenu;
      }
      setTimeout(() => {
        this.menuScrollToShow(select_i);
      })
      let defaultSelect = this.menuData[select_i];
      this.gameMenuClick(defaultSelect);

    },
    menuScrollToShow(i){
      const limit = 4,space = .38;
      if(i > limit){
        let total = i - (limit - 1);
        let spaceTotal = total * Tool.remToPx(space);
        let sTop = this.$refs.itemWrapScroll.scrollTop;
        if(sTop == 0){
          this.$refs.itemWrapScroll.scrollTop += spaceTotal;
        }
      }
    },
    checkIsNewMenu(){
      let newList = [];
      this.dzyyMenu.forEach((mData,m_i) => {
        let found = -1;
        this.menuData.forEach((dzyy,i) => {
          if(mData.id == dzyy.id){
            found = i;
          }
        });
        if(found == -1){
          let newItem = {
            id:mData.id,
            ico:'',
            item:require('../assets/dzyy/menu-images/dzyy-ag1.png'),
            itemFocus:require('../assets/dzyy/menu-images/dzyy-ag2.png'),
            show:1,
            platformCode:mData.platform_code,
            pltName:mData.plat_name
          }
          newList.push(newItem);
        }
      });
      if(newList.length){
        newList.forEach(nlItem => this.menuData.push(nlItem));
      }
    },
    dzyyShiyingLayout(){
      if([1,3].includes(this.invoke)){
        let m = innerHeight > innerWidth ? innerWidth : innerHeight
        let height = m - Tool.remToPx(1);
        this.homeGameMenuStyle = {
          height:`${height}px`
        };
      }
    },
    dzyyLayout(){
      this.itemBorder = require('../assets/home/game/menu_item_border.png');
      this.dzyyMenuSource = [
        {
          id:4,
          ico:require('../assets/dzyy/menu-images/dzyy-ag3.png'),
          item:require('../assets/dzyy/menu-images/dzyy-ag1.png'),
          itemFocus:require('../assets/dzyy/menu-images/dzyy-ag2.png'),
          show:0,
          platformCode:''
        },
        {
          id:5,
          ico:require('../assets/dzyy/menu-images/dzyy-bb3.png'),
          item:require('../assets/dzyy/menu-images/dzyy-bb1.png'),
          itemFocus:require('../assets/dzyy/menu-images/dzyy-bb2.png'),
          show:0,
        },
        {
          id:2,
          ico:require('../assets/dzyy/menu-images/dzyy-jdb3.png'),
          item:require('../assets/dzyy/menu-images/dzyy-jdb1.png'),
          itemFocus:require('../assets/dzyy/menu-images/dzyy-jdb2.png'),
          show:0,
        },
        {
          id:7,
          ico:require('../assets/dzyy/menu-images/dzyy-cq3.png'),
          item:require('../assets/dzyy/menu-images/dzyy-cq1.png'),
          itemFocus:require('../assets/dzyy/menu-images/dzyy-cq2.png'),
          show:0,
        },
        
        // {
        //   id:1,
        //   ico:require('../assets/dzyy/menu-images/dzyy-sw3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-sw1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-sw2.png'),
        //   show:0,
        // },
        // {
        //   id:3,
        //   ico:require('../assets/dzyy/menu-images/dzyy-pt3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-pt1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-pt2.png'),
        //   show:0,
        // },
        // {
        //   id:6,
        //   ico:require('../assets/dzyy/menu-images/dzyy-pp3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-pp1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-pp2.png'),
        //   show:0,
        // },
        // {
        //   id:8,
        //   ico:require('../assets/dzyy/menu-images/dzyy-mg3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-mg1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-mg2.png'),
        //   show:0,
        // },
        // {
        //   id:9,
        //   ico:require('../assets/dzyy/menu-images/dzyy-hb3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-hb1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-hb2.png'),
        //   show:0,
        // },
        // {
        //   id:10,
        //   ico:require('../assets/dzyy/menu-images/dzyy-fg3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-fg1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-fg2.png'),
        //   show:0,
        // },
        // {
        //   id:11,
        //   ico:require('../assets/dzyy/menu-images/dzyy-eg3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-eg1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-eg2.png'),
        //   show:0,
        // },
        // {
        //   id:12,
        //   ico:require('../assets/dzyy/menu-images/dzyy-dt3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-dt1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-dt2.png'),
        //   show:0,
        // }
        // ,{
        //   id:13,
        //   ico:require('../assets/dzyy/menu-images/dzyy-ae3.png'),
        //   item:require('../assets/dzyy/menu-images/dzyy-ae1.png'),
        //   itemFocus:require('../assets/dzyy/menu-images/dzyy-ae2.png'),
        //   show:0,
        // }
      ]
    },
    qipaiLayout(){
      // this.menuData = [
      this.qipaiMenuSource = [
        {
          id:1,
          ico:require('../assets/dzyy/qipai-menu-img/qp-ky3.png'),
          item:require('../assets/dzyy/qipai-menu-img/qp-ky1.png'),
          itemFocus:require('../assets/dzyy/qipai-menu-img/qp-ky2.png'),
          show:0,
          platformCode:''
        },
        {
          id:10,
          ico:require('../assets/dzyy/qipai-menu-img/qp-vg3.png'),
          item:require('../assets/dzyy/qipai-menu-img/qp-vg1.png'),
          itemFocus:require('../assets/dzyy/qipai-menu-img/qp-vg2.png'),
          show:0,
          platformCode:''
        },
        {
          id:12,
          ico:require('../assets/dzyy/qipai-menu-img/qp-ly3.png'),
          item:require('../assets/dzyy/qipai-menu-img/qp-ly1.png'),
          itemFocus:require('../assets/dzyy/qipai-menu-img/qp-ly2.png'),
          show:0,
          platformCode:''
        },
      ]
    },
    layout(){
      this.itemBorder = require('../assets/home/game/menu_item_border.png');
      this.menuData = [
        {
          id:-1,
          ico:require('../assets/home/game/m_game_hot_ico.png'),
          item:require('../assets/home/game/m_game_hot_item.png'),
          itemFocus:require('../assets/home/game/m_game_hot_item1.png'),
          show:2,
        },
        {
          id:3,
          ico:require('../assets/home/game/m_game_qipai_ico.png'),
          item:require('../assets/home/game/m_game_qipai_item.png'),
          itemFocus:require('../assets/home/game/m_game_qipai_item1.png'),
          show:1,
        },
        {
          id:5,
          ico:require('../assets/home/game/m_game_buyu_ico.png'),
          item:require('../assets/home/game/m_game_buyu_item.png'),
          itemFocus:require('../assets/home/game/m_game_buyu_item1.png'),
          show:1,
        },
        {
          id:1,
          ico:require('../assets/home/game/m_game_dzyy_ico.png'),
          item:require('../assets/home/game/m_game_dzyy_item.png'),
          itemFocus:require('../assets/home/game/m_game_dzyy_item1.png'),
          show:1,
        },
        {
          id:0,
          ico:require('../assets/home/game/m_game_zrsx_ico.png'),
          item:require('../assets/home/game/m_game_zrsx_item.png'),
          itemFocus:require('../assets/home/game/m_game_zrsx_item1.png'),
          show:1,
        },
        {
          id:2,
          ico:require('../assets/home/game/m_game_caip_ico.png'),
          item:require('../assets/home/game/m_game_caip_item.png'),
          itemFocus:require('../assets/home/game/m_game_caip_item1.png'),
          show:1,
        },
        {
          id:4,
          ico:require('../assets/home/game/m_game_ty_ico.png'),
          item:require('../assets/home/game/m_game_ty_item.png'),
          itemFocus:require('../assets/home/game/m_game_ty_item1.png'),
          show:1,
        }
      ];
      this.gameMenuClick(this.menuData[0],0);
    },
    gameMenuClick(data){
      //此处不能封装为方法
      if(Tool.isComputer()){// 电脑
      }else{
        if(document.documentElement.requestFullscreen){
          document.documentElement.requestFullscreen();
        }
      }
      if(!data){
        console.log(data,'home game !');
        return;
      }
      this.menuSelected = data.id;
      if(!this.invoke){
        this.$emit('gameChange',data.id);
      }else if([1,3].includes(this.invoke*1)){ // 1电子游艺 3棋牌
        this.$emit('dzyyChange',data);
        let path = this.$route.path
        let q = this.$route.query;
        let l_type = q.l_type || 1;
        path += `?id=${data.id}&l_type=${l_type}`
        this.$router.push(path)
      }
      
    }
  },
  watch:{
    invoke(){
      if(this.invoke == 1){//电子游艺
        this.dzyyShiyingLayout();
        this.dzyyLayout();
      }else if(this.invoke == 3){//棋牌
        this.dzyyShiyingLayout();
        this.qipaiLayout();
      }
      // window.addEventListener('resize',this.dzyyShiyingLayout)
      // window.addEventListener('orientationchange',this.dzyyShiyingLayout)
    },
    innerAnonymousLogined(n,o){
      if(n){
        this.getMainMenuList(() => {
          this.gameMenuClick(this.menuData[0],0);
        });
      }
    },
    preMenuId(){
      this.getGameMenu();
    }
  },
  computed:{
    ...mapGetters({
      innerAnonymousLogined:'getInnerAnonymousLogined'
    })
  }
}
</script>
<style scoped lang="stylus">
.home-game-menu
  margin-left .15rem
  height 2.52rem
  position relative
  &.dzyy
    height auto
    margin-top .1rem
  .bg
    width 1.4rem
    height 100%
    background-image url('../assets/home/game/menu-bg.png')
    background-size 100% 100%
    background-repeat no-repeat
  .item-wrapper
    width 1.5rem
    height 93%
    overflow auto
    position absolute
    padding 0 0 .68rem .1rem
    top .06rem
    left 0
    .item-menu
      width 100%
      height .38rem
      margin-bottom .01rem
      position relative
      .focus-bg
        width 100%
        height .48rem
        position absolute
        background-image url('../assets/home/game/m_game_item_b.png')
        background-size 100% 100%
        left -.09rem
        top 0

      .i-m-inner
        width 100%
        height 100%
        display flex
        align-items center
        position absolute
        z-index 1
        .title
          font-size .16rem
          color #FFF5C4
          &.focus
            color #FFFFFF
            font-size .17rem
            font-weight bold
            text-shadow 0 0 4px #A74C1D
            text-stroke .01rem #A74C1DAA
            -webkit-text-stroke .01rem #A74C1DAA
      .item-ico
        width .18rem
        margin-right .06rem
        margin-left .08rem
        transform translateY(-.01rem)
      .item-img
        width .66rem
      .item-border
        width 70%
        height .02rem
        position absolute
        bottom 0
        left .12rem
        z-index 1
</style>