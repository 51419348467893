import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import versionTood from '@/utils/versionUpdate';

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path:'/home',
    name:'main',
    component:HomeView,
  },
  {
    path:'/geren',
    name:'geren',
    component:() => import('../views/GerenZhongxin.vue'),
    children:[
      {
        path:'info',
        name:'userInfo',
        component:() => import('../components/GerenZhongxin/UserInfo.vue')
      },
      {
        path:'level',
        name:'userLevel',
        component:() => import('../components/GerenZhongxin/RankPrivilege.vue')
      },
      {
        path:'vdetails',
        name:'vipDetails',
        component:() => import('../components/GerenZhongxin/VipDetails.vue')
      },
      {
        path:'zjdetails',
        name:'zijinDetails',
        component:() => import('../components/GerenZhongxin/ZijinDetails.vue')
      },
      {
        path:'zdquery',
        name:'zdanQuery',
        component:() => import('../components/GerenZhongxin/ZdanQuery.vue')
      },
      {
        path:'report',
        name:'report',
        component:() => import('../components/GerenZhongxin/UserReport.vue')
      }
    ]
  },
  {
    path:'/dzyy',
    name:'dzyy',
    component:() => import('../views/DzyyView.vue'),
  },
  {
    path:'/favorite',
    name:'favorite',
    component:() => import('../views/FavoriteView.vue')
  },
  {
    path:'/play',
    name:'play',
    component:() => import('../components/PlayGame.vue')
  },
  {
    path:'/customer',
    name:'customer',
    component:() => import('../components/Customer.vue')
  },
  {
    path:'/maintenance',
    name:'maintenance',
    component:() => import('../views/QuanzWeihu.vue')
  },
  {
    path:'/test',
    name:'test',
    component:() => import('../views/Test.vue')
  },
  // {
  //   path:'/sgame',
  //   name:'smallGame',
  //   component:() => import('../views/SmallGameView.vue')
  // },
  // {
  //   path:'/splay',
  //   name:'smallPlay',
  //   component:() => import('../views/SmallPlayView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
let versionChecked = 0;
router.beforeEach((to, from, next) => {
  if(!versionChecked){
    versionTood.isNewVersion();
    versionChecked = 1;
  }
  if(window.localRouteEach && window.localRouteEach[0]){
    window.localRouteEach.forEach(fun => {
      fun(to,from);
    })
  }
  next()
})

export default router
