<template>
  <div class="op-duihuan-wrap" :class="{'is-no-wallert':isNoWallet}">
    <div class="flex p-relative">
      <div class="amount-title">可兑换金额</div>
      <div class="amount-dh">
        ￥{{withdrawInfo.max_draw?withdrawInfo.max_draw:0}}
      </div>
      <div class="record-link" @click="gotoRecordeClick">
        兑换记录
      </div>
    </div>
    <div class="flex info-des f-row" :class="{tixianchongzhi:wayId == 'way-2',wallet:wayId == 'way-3'}">
      温馨提示：需再投注<span>{{withdrawInfo.debt?withdrawInfo.debt:0}}</span>元，
      即可不扣除行政费用与优惠回收费!
    </div>
    <div v-if="wayId == 'way-2'" style="color:#847256;font-size:.12rem;text-align:left;">
      提现充值到账快，避免银行卡流水存取款次数过多导致银行卡风控！<br/>不用再担心存取款延迟问题导致影响游戏体验！
    </div>

    <div class="flex f-row" v-show="isNoWallet">
      <div class="filed-input">真实姓名：</div>
      <div class="alipy-account">
        {{nopayInfo.real_name}}
      </div>
    </div>

    <div class="flex f-row" v-show="isNoWallet">
      <div class="filed-input">选择账号：</div>
      <Selector defaultTxt="选择账号" style="width:2.22rem"
          :dataList="accountList" @change="dateSelecChange"
          :defaultIndex="-1" menuHeight=".78rem" />
    </div>

    <div class="flex f-row way-3" v-if="wayId == 'way-3'">
      <div class="lineaner" @click="walletClickHandle(i)"
        :class="{selected:currWalletIndex == i}" :data-id="item.id" v-for="(item,i) of walletThirds">
        {{item.name}}
      </div>
    </div>

    <div class="flex f-row" :class="wayId">
      <div class="filed-input">兑换金额：</div>
      <input class="amount-input" type="number" v-model="duihuanAmount"
        @beforepaste="amountBeforepaste" @input="amountInputing"
        :placeholder="`${wayId == 'way-2'?'':'提交金额'+amountMin+'-'+amountMax}`">
    </div>
    <!-- 免提直充，不显示账号信息 -->
    <div class="flex f-row" :class="wayId" v-if="wayId != 'way-2' && !isNoWallet">
      <div class="filed-input">{{receiveWay}}：</div>
      <div class="alipy-account">
        {{payWayAccount}}
      </div>
      <img class="show-alipay-btn ease-btn-effe" v-if="payWayAccount"
        src="../../assets/duihuan/show-alipay-btn.png"
        @click="showAlipayClick" />
      <img class="show-alipay-btn ease-btn-effe" v-else
        src="../../assets/duihuan/binding-alipay-btn.png" 
        @click="bandingAlipayClick" />
    </div>

    <img @click="duihuanSubClick"
      class="duihuan-sub-btn ease-btn-effe"
        src="../../assets/duihuan/duihuan-sub-btn.png" />
  </div>
</template>
<script>
import Tool from '../../common/Tool.js'
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
import Selector from '../common/Selector.vue'

export default{
  props:{
    userInfo:Object,
    currMenuItem:Object,
    withdrawInfo:Object,
    wayId:String|Number,
    walletThirds:Object,
  },
  data(){
    return{
      duihuanAmount:'',
      nopayInfo:{},
      accountList:[],
      currNowalletI:-1,
      currNoWallet:null,
      isNoWallet:0,
      currWalletIndex:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    walletClickHandle(i){
      this.currWalletIndex = i;
    },
    getNopayInfo(){
      let dataHandle = data => {
        this.accountList = data.ways.map(m => {
          return{
            ...m,
            name:`${m.bank_name}-${m.account}`,
          }
        })
      }
      let localData = Tool.getNopayInfo();
      if(process.env.NODE_ENV == 'development' && localData){
        this.nopayInfo = localData;
        dataHandle(localData);
        return;
      }
      Request.get('/user/nopay').then(res => {
        if(res.data && res.data.data){
          this.nopayInfo = res.data.data;
          Tool.setNopayInfo(res.data.data);
          dataHandle(res.data.data);
        }
      }).finally(() => {

      })
    },
    amountBeforepaste(){
      clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))
    },
    amountInputing(e){
      e.srcElement.value = e.srcElement.value.replace(/[^\d]/g,'');
    },
    gotoRecordeClick(){
      this.$emit('showRecordClick');
    },
    showAlipayClick(){
      this.$emit('showAlipayClick',this.wayId)
    },
    bandingAlipayClick(){
      this.$emit('bandingAlipayClick',this.wayId)
    },
    duihuanSubClick(){
      if(this.wayId != 'way-2'){
        if(!this.payWayAccount || !this.payWayAccount.trim()){
          let msg = '请绑定账号';
          if(this.wayId == 'way-0' || this.wayId == 'way-4'){
            msg = '请绑定支付宝';
          }else if(this.wayId == 'way-1'){
            msg = '请绑定银行卡';
          }else if(this.wayId == 'way-3' || this.currMenuItem.wallet == 1 || Tool.walletId.includes(this.wayId)){
            msg = '请绑定数字钱包';
          }
          if(this.isNoWallet){
            msg = '请选择账号';
          }
          this.popMsg(msg);
          return;
        }
      }
      let outputParam = {
        dhAmount:this.duihuanAmount,
      };
      let duihuanAmount = 1*this.duihuanAmount;
      if(this.wayId != 'way-2'){
        let amountMin = 1*this.amountMin;
        let amountMax = 1*this.amountMax;
        if(!(amountMin <= duihuanAmount && duihuanAmount <= amountMax)){
          this.popMsg(`请输入${this.amountMin}到${this.amountMax}之间的金额`)
          return;
        }
        outputParam.account = this.payWayAccount;
        if(this.isNoWallet){
          outputParam.noWalletType = this.currNoWallet.type;
        }
      }else if(!duihuanAmount || duihuanAmount < 1){
        this.popMsg('请输入有效金额')
        return;
      }
      let max_draw = this.withdrawInfo.max_draw?1*this.withdrawInfo.max_draw:0;debugger
      if(duihuanAmount > max_draw){
        this.popMsg(`您最多可兑换${max_draw} !`)
        return;
      }
      if(this.wayId == 'way-3'){
        let currWallet = this.getCurrWallet();
        if(currWallet){
          outputParam.wallet_id = currWallet.id;
        }
      }
      this.$emit('duihuanSubClick',outputParam)
    },
    getCurrWallet(){
      if(this.wayId == 'way-3' || this.currMenuItem.wallet == 1){
        if(this.userInfo.wallets && this.userInfo.wallets.length){
          let found = null;
          let selectedWallet = {};
          if(this.walletThirds && this.walletThirds[this.currWalletIndex]){
            selectedWallet = this.walletThirds[this.currWalletIndex]
          }
          this.userInfo.wallets.forEach(wallet => {
            if(wallet.third_id == selectedWallet.third_id){
              found = wallet;
            }
          })
          if(found){
            return found;
          }
        }
      }
    },
    dateSelecChange(e){
      this.currNowalletI = e;
      this.currNoWallet = this.accountList[this.currNowalletI]
    },
  },
  watch:{
    wayId(n){
      this.duihuanAmount = '';
    },
    currMenuItem(c){
      this.isNoWallet = c.id == 139 ? 1 : 0;
      if(c.id == 139){
        this.getNopayInfo();
      }
    }
  },
  computed:{
    payWayAccount(){
      if(this.wayId == 'way-0' || this.wayId == 'way-4'){
        return this.userInfo.alipay;
      }else if(this.wayId == 'way-1'){
        if(this.userInfo && this.userInfo.bank && this.userInfo.bank.bank_account){
          return this.userInfo.bank.bank_account;
        }
      }else if(this.wayId == 'way-3' || this.currMenuItem.wallet == 1){
        if(this.isNoWallet){
          return this.currNoWallet ? this.currNoWallet.account : '';
        }else{
          let currWallet = this.getCurrWallet();
          if(currWallet){
            return currWallet.address;
          }
        }
      }
    },
    receiveWay(){
      if(this.wayId == 'way-0'){
        return '收款支付宝';
      }else if(this.wayId == 'way-1'){
        return '收款银行卡';
      }else if(this.wayId == 'way-3'){
        return '数字钱包';
      }else if(this.wayId == 'way-4'){
        return '账号';
      }else if(this.currMenuItem.wallet == 1 || Tool.walletId.includes(this.wayId)){
        return '数字钱包';
      }
    },
    amountMin(){
      if(this.withdrawInfo.chargeout_setting){
        const setting = this.withdrawInfo.chargeout_setting;
        let pKey = '';
        if(this.wayId == 'way-0'){
          pKey = 'out_lower_limit_alipay';
        }else if(this.wayId == 'way-1'){
          pKey = 'out_lower_limit_bank';
        }else if(this.wayId == 'way-4'){
          pKey = 'out_lower_limit_alipay';
        }else if(this.wayId == 'way-3' || this.currMenuItem.wallet == 1 || Tool.walletId.includes(this.wayId)){
          pKey = 'out_lower_limit_wallet';
        }
        return setting[pKey];
      }
      return 0;
    },
    amountMax(){
      if(this.withdrawInfo.chargeout_setting){
        const setting = this.withdrawInfo.chargeout_setting;

        let pKey = '';
        if(this.wayId == 'way-0'){
          pKey = 'out_upper_limit_alipay';
        }else if(this.wayId == 'way-1'){
          pKey = 'out_upper_limit_bank';
        }else if(this.wayId == 'way-4'){
          pKey = 'out_upper_limit_alipay';
        }else if(this.wayId == 'way-3' || this.currMenuItem.wallet == 1 || Tool.walletId.includes(this.wayId)){
          pKey = 'out_upper_limit_wallet';
        }
        return setting[pKey];
      }
      return 0;
    },
  },
  components:{
    Selector
  }
}
</script>

<style scoped lang="stylus">
.op-duihuan-wrap
  &.is-no-wallert
    & > div
      &.f-row
        margin-bottom .09rem
.op-duihuan-wrap
  padding .12rem 0 0 .15rem
  .show-alipay-btn
    width .56rem
    margin-left .1rem
  & > div
    align-items center
    margin-bottom .1rem
    &.info-des
      font-size .12rem
      color #847256
      &.wallet
        margin-bottom .08rem
    &.f-row
      margin-bottom .2rem
      &.way-3
        width 4.14rem
        flex-wrap wrap
        margin-bottom .09rem
      &.tixianchongzhi
        margin-bottom 0
    .amount-title
      font-weight bold
    .amount-dh
      color #F1012D
      margin-left .2rem
    .record-link
      text-decoration underline
      color #9A6B35
      position absolute
      right .2rem
      top .02rem
    .filed-input
      width .88rem
      text-align right
    .alipy-account
      width 2.56rem
      height .3rem
      overflow hidden
      text-align left
      line-height .3rem
      border-radius .04rem
      background-color #BC8F63
      padding-left .04rem
      color #fff1df
    input
      width 2.56rem
      height .3rem
      border-radius .04rem
      background-color #BC8F63
      &.amount-input
        width 3.2rem
        padding-left .04rem
  .duihuan-sub-btn
    width 1.12rem
  .lineaner
    padding 0 .06rem
    width 1.3rem
    height .3rem
    margin-right .08rem
    margin-bottom .04rem
    text-align center
    line-height .3rem
    font-size .12rem
    border-radius .05rem
    box-shadow inset 0 0 5px 0 rgba(0,0,0,.5)
    background #bf8552
    color rgba(255,255,255,.8)
    flex-shrink 0
    position relative
    &.selected
      background #ee9b00
      color #fff
</style>