<template>
  <form class="l-c-w-ir" autocomplete="off">
    <input autocomplete="false" name="hidden" style="display:none;">
    <div class="l-label">短信验证码：</div>
    <input v-model="smsCode" class="sms-code" autocomplete="false"
      type="number" placeholder="请输入验证码"
      @keyup="smsCodeKeyup" />
    <img @click="getSmsCode" class="get_code_btn" v-show="!smsCodeCountingDown"
      src="../../assets/login/get-code-btn.png" alt="">
    <div v-show="smsCodeCountingDown" class="sms-code-counting-down">
      {{smsCodeCountingDown}}秒后可重发
    </div>
  </form>
</template>
<script>
import { mapMutations } from 'vuex';
import Request from '../../common/Request.js'

export default{
  props:{
    mobile:String,
  },
  data(){
    return{
      smsCode:'',
      smsCodeCountingDown:0,
      preSmsCode:{},
      codeTimeout:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    smsCodeKeyup(){
      this.$emit('smsCoding',this.smsCode)
    },
    runCountingDown(){
      this.smsCodeCountingDown = 60;
      const run = () => {
        clearTimeout(this.codeTimeout);
        if(this.smsCodeCountingDown < 1) return;

        this.codeTimeout = setTimeout(() => {
          this.smsCodeCountingDown--;
          if(this.smsCodeCountingDown < 1){
            this.smsCodeCountingDown = 0;
          }else{
            run();
          }
        },1000)
      }
      run();
    },
    getSmsCode(){
      let m_number = this.mobile;
      if(!m_number || !m_number.trim()){
        this.popMsg('请输入正确的手机号');
        return;
      }
      let params = {
        username:m_number
      }
      let now = new Date();
      let sub = now - this.preSmsCode.time;
      if(sub < 1000 * 60 && this.preSmsCode.mobile == m_number){
        let second = Math.floor((1000 * 60 - sub) / 1000);
        this.popMsg(`正在发送验证码请${second}秒后再试 !`);
        return;
      }
      this.preSmsCode = {
        time:now,
        mobile:m_number,
      }
      this.showLoading()
      Request.post(`/pub/sms_code`,params).then(res => {
        if(res.data.code == 0){
          this.popMsg('正在发送验证码 !');
        }
        this.runCountingDown();
        this.closeLoading()
      }).catch(error => {
        if(error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg);
        }
        this.closeLoading();
      });
    }
  }
}
</script>

<style scoped lang="stylus">
.l-c-w-ir
  width 3.82rem
  display flex
  justify-content flex-start
  margin-left .8rem
  align-items center
  .sms-code-counting-down
    font-size .12rem
    padding 0 .05rem
  .sms-code
    width 1.44rem
  .get_code_btn
    width .7rem
    transition transform .3s
    &:active
      transform scale(.8)
  .l-label
    width 1rem
    display flex
    justify-content flex-end
    align-items center
  .confirm-btn
    width 1.12rem
    margin-left .96rem
    transition transform .3s
    &:active
      transform scale(.9)
  input
    background-color #BEA37B
    border none
    border-radius .12rem
    width 2rem
    height .25rem
    color #fff
    padding-left .05rem
    &.captcha
      width 1.44rem
    &.agent-code
      width .8rem
</style>