import axios from 'axios'
import Tool from './Tool.js'

export default{
  getLines(){
    let url = window.globalConfig.hostListApiUrl
    if(!url){
      console.error(
        '请在项目根目录config/config.js中配置window.globalConfig.hostListApiUrl字段')
    }
    return axios.get(url);
  },
  getLinesLocal(){
    const hostListUrl = window.globalConfig.hostListApiUrl;
    if(!hostListUrl){
      console.error(
        '请在项目根目录config/config.js中配置window.globalConfig.hostListApiUrl字段')
    }
    return new Promise((res,rej) => {
      let localApiHost = Tool.getHostList();
      if(localApiHost){
        res(localApiHost);
      }else{
        axios.get(hostListUrl).then(res1 => {
          Tool.setHostList(res1.data);
          res(res1.data);
        })
      }
    });
  },
  getLocalApiHost(){
    return localStorage.getItem('api-host');
  },
  setApiHost(host){
    if(host){
      localStorage.setItem('api-host',host)
    }
  },
  getWsHost(){
    let apihost = '';
    if(window.globalConfig && window.globalConfig.websoketHost){
      apihost = window.globalConfig.websoketHost;
    }else{
      apihost = 'http://43.198.23.134:39012';
    }
    return apihost;
  },
  getLocalLang(){
    let lan = localStorage.getItem('language');
    if(lan) return lan;
    return 'pt-BR';
  },
  async getApiHost(method,isRecharge){
    let apihost = localStorage.getItem('api-host');
    if(!apihost){
      // let list = await this.getLinesLocal();
      // apihost = list[0].host;
      apihost = globalConfig.hostDefaultApiUrl;
    }
    if(isRecharge){
      let url = window.globalConfig.rechargeHost;
      if(!url){
        console.error(
          '请在项目根目录config/config.js中配置window.globalConfig.rechargeHost')
      }
      return url;
    }
    // if(process.env.NODE_ENV == 'development'){
    //   apihost = 'http://test.bytechcdn002.com';
    // }
    if(process.env.NODE_ENV == 'production'){
      return apihost;
    }
    if(process.env.NODE_ENV == 'development' && method == 'get'){
      return apihost;
    }
    return apihost;
    // return '/api';
  },
  checkInvalid(error){
    let other = 0;
    if(error.response && error.response.data){
      if([1000002,1000006].includes(1 * error.response.data.code)){
        other = 1;
        Tool.quit()
        location.href = '/'
      }
    }
    if(!other){
      return error;
    }
  },
  async get(path,isRecharge){
    let api_token = Tool.getApiToken();
    let pToken = `api_token=${api_token}`;
    let p = `lang=${this.getLocalLang()}`;

    if(path.indexOf('/pub/') != 0 && path.indexOf('/crash/') != 0){
      p = pToken +'&'+ pToken;
    }

    let path1 = path;
    if(path.indexOf('?') > -1){
      let splited = path.split('?');
      path1 = splited[0]
      let param1 = splited[1]
      p += ('&'+param1)
    }
    path1 += ('?'+p);
    let apiHost = await this.getApiHost('get',isRecharge);
    return axios.get(apiHost + path1)
    // .catch(error => {
    //   let err = this.checkInvalid(error);
    //   if(err){
    //     throw err;
    //   }
    // });
  },
  async post(path,params,isRecharge){
    if(!params) params = {};
    if(path.indexOf('/pub/') != 0 && path.indexOf('/crash/') != 0){
      params.api_token = Tool.getApiToken();
      if(!params.api_token){
        return new Promise((resl,rej) => {
          resl({});
        })
      }
    }
    if(path.indexOf('?') > -1){
      path+=`&lang=${this.getLocalLang()}`
    }else{
      path+=`?lang=${this.getLocalLang()}`
    }
    let apiHost = await this.getApiHost('post',isRecharge);
    return axios.post(apiHost + path,params).catch(error => {
      let err = this.checkInvalid(error);
      if(err){
        throw err;
      }
    });
  },
  //用户被挤下线
  userOutted(vm){
    if(vm.popMsg){
      vm.popMsg(Tool.loginInvalid);
    }else{
      console.error('mapMutation regist popMsg !')
      console.log(vm)
    }
    setTimeout(() => {
      Tool.quit();
      vm.$router.push({name:'login'})
    },2800)
  },
}