<template>
  <div class="activity-content">
    <div class="ac-c-inner" v-if="htmlContent" v-html="htmlContent">
    </div>
  </div>
  <div class="menu-wrap">
    <div class="inner-back"></div>
    <div class="menu-wrap-scroll flex">
      <div :key="menu.id" class="menu-btn"
        v-for="(menu,i) of menuList" :data-id="menu.id"
        @click="menuClick(i)" 
        :class="{select:menuSelectIndex==i}">
        <div class="menu-ico-poi">
        </div>
        <div class="normal-bg"></div>
        <div class="select-bg"></div>
        <div class="menu-text">
          {{ menu.title}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    mList:Array,
  },
  data(){
    return {
      htmlContent:'',
      menuList:[],
      menuSelectIndex:0
    }
  },
  methods:{
    menuClick(i){
      this.menuSelectIndex = i;
      this.htmlContent = this.menuList[this.menuSelectIndex].mobile_content;
    },
  },
  watch:{
    'mList.length'(){
      this.menuList = this.mList;
      this.menuClick(0)
    }
  }
}
</script>

<style scoped lang="stylus">
.activity-content
  width 3.99rem
  height 2.28rem
  overflow auto
  position relative
  .kuisun-jiuyuan
    background white
    width auto
    height .25rem
    padding 0 .2rem
    border-radius .05rem
    line-height .25rem
    position absolute
    right .1rem
    bottom .1rem
  .ac-c-inner
    width 100%
    padding .06rem

.menu-wrap
  width 1.24rem
  height 2.28rem
  position relative
  z-index 1
  &::-webkit-scrollbar
    width 0
  .inner-back
    width 1.23rem
    height 100%
    background linear-gradient(#EACD9B, #D8A96B)
    box-shadow 0 0 10px 0 rgba(0,0,0,.6)
    padding-bottom .3rem
  .menu-wrap-scroll
    width 1.33rem
    height 100%
    position absolute
    overflow auto
    top 0
    right 0
    flex-direction column
    align-items flex-end
    padding .12rem 0
    .menu-btn
      width 1.25rem
      height .44rem
      flex-shrink 0
      margin 0 .04rem .07rem 0
      position relative
      .menu-ico-poi
        width .24rem
        height .35rem
        background-size 100% 100%
        top 0
        left .16rem
        z-index 2
        position absolute
        &.hot
          background-image url(../../assets/activity/activity-m-hot.png)
        &.recom
          background-image url(../../assets/activity/activity-m-recom.png)
      & > div
        position absolute
        right 0
        top 0
      .select-bg
        display none
      &.select
        .normal-bg
          display none
        .select-bg
          display block
      .normal-bg
        width 1.11rem
        height 100%
        background-image url(../../assets/geren/vip-menu-btn.png)
        background-repeat no-repeat
        background-size 100% 100%
      .select-bg
        width 100%
        height 100%
        background-image url(../../assets/activity/act-menu-se-btn.png)
        background-repeat no-repeat
        background-size 100% 100%
      .menu-text
        width 1.11rem
        height 100%
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        font-size .14rem
        font-weight bold
        padding-left .05rem
        line-height .4rem
        color #842404
        z-index 1
</style>