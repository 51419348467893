<template>
  <PopWidget :isShow="isShowPopWidget" :isFull="1" style="z-index:4;" 
    :width="containerWidth">
    <template v-slot:title>
      <img class="l-t-title" v-if="!isOut"
        src="../../assets/yuebao/pop-input-title.png" />
      <img class="l-t-title" v-else
        src="../../assets/yuebao/pop-output-title.png" />
    </template>
    <template v-slot:content>
      <div class="yeb-io-wrap" :class="{isout:isOut}">
        <div class="row-yeb flex">
          <img class="yeb-io-icode" src="../../assets/yuebao/yeb-io-icode.png" />
          {{getTitleWord()}}
        </div>
        <div class="row-yeb flex amount" :class="{out:isOut}">
          <span v-if="isOut">
            ￥{{yebData.balance?yebData.balance:'0.00'}}
          </span>
          <span v-else>
            ￥{{amountCoin?amountCoin:'0.00'}}
          </span>
        </div>
        <div class="row-yeb flex">
          <div>{{getTransferLabel()}}</div>
          <div class="yeb-wrapper">
            <input class="yeb-io-input" :placeholder="getTransferPlaceholder()" 
              v-model="zhuanruAmount" />
            <img @click="allBtnClick" class="all-out-btn ease-btn-effe" src="../../assets/yuebao/all-out-btn.png" />
          </div>
        </div>
        <div class="row-yeb flex amount-des" v-if="!isOut">
          <div class="got-des">{{$t('yebInOut.firstIncomeDes')}}</div>
          <div class="got-time">{{interestTime}}</div>
        </div>
        <div class="rule-descipt" v-if="!isOut">
          <img src="../../assets/yuebao/rule-ico1.png" alt="">{{$t('yebInOut.inRule1')}}<br>
          <img src="../../assets/yuebao/rule-ico2.png" alt="">{{$t('yebInOut.inRule2')}}<br>
          <img src="../../assets/yuebao/rule-ico3.png" alt="">{{$t('yebInOut.inRule3')}}<br>
        </div>
        <div class="rule-descipt isout" v-if="isOut">
          <img src="../../assets/yuebao/rule-ico1.png" alt="">{{$t('yebInOut.outRule')}}<br>
        </div>
        <img class="confirm-btn ease-btn-effe" src="../../assets/confirm-btn.png" 
          @click="confirmClick" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import { mapMutations } from 'vuex';
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'

export default{
  props:{
    show:Number,
    isOut:Number,
    yebData:Object,
  },
  data(){
    return {
      isShowPopWidget:0,
      zhuanruAmount:'',
      amountCoin:0,
      interestTime:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    getTransferPlaceholder(){
      if(this.isOut){
        return this.$t('yebInOut.transferOPlaceholder');
      }else{
        return this.$t('yebInOut.transferIPlaceholder');
      }
    },
    getTransferLabel(){
      if(this.isOut){
        return this.$t('yebInOut.transferOLabel');
      }else{
        return this.$t('yebInOut.transferILabel');
      }
    },
    getTitleWord(){
      if(this.isOut){
        return this.$t('yebInOut.yebBalance');
      }else{
        return this.$t('yebInOut.accountBalance');
      }
    },
    getBalance(){
      let localUserInfo = Tool.getLocalUserinfo();
      if(localUserInfo){
        this.amountCoin = localUserInfo.coin;
        return;
      }
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.amountCoin = res.data.data.coin;
          Tool.setLocalUserinfo(res.data.data);
        }
      });
    },
    confirmClick(){
      if(!this.zhuanruAmount){
        let tip = '';
        if(this.isOut){
          tip = this.$t('yebInOut.transferOutEnptyDes');
        }else{
          tip = this.$t('yebInOut.transferInEnptyDes');
        }
        this.popMsg(tip);
        return;
      }
      let amount = 1 * this.zhuanruAmount;
      if(isNaN(amount)){
        this.popMsg(`只能输入数字！`)
        return;
      }
      if(this.isOut){
        if(amount> this.yebData.balance){
          this.popMsg(`转出金额大于余额宝内金额！`)
          return;
        }
        console.log('*************************emit')
        this.$emit('outConfirm',amount);
      }else{
        let param = {
          amount:amount,
        }
        this.showLoading();
        Request.post('/yeb/transfer_in',param).then(res => {
          this.closeLoading();
          if(res.data.code == 0){
            this.popMsg(this.$t('yebInOut.transferInSuccess'))
            this.isShowPopWidget = 0;
            this.$emit('updateYuebao')
          }else if(res.data.msg){
            this.popMsg(res.data.msg)
          }
        }).catch(err => {
          this.closeLoading();
          if(err.response.data && err.response.data.msg){
            this.popMsg(err.response.data.msg)
          }
        });
      }
    },
    getInterestTime(){
      Request.post('/yeb/interest_time').then(res => {
        if(res.data.data && res.data.data.next){
          this.interestTime = res.data.data.next;
        }
      })
    },
    allBtnClick(){
      this.zhuanruAmount = 
        this.isOut ? this.yebData.balance:this.amountCoin;
    }
  },
  watch:{
    show(n){
      if(n){
        this.getBalance();
        if(!this.isOut){
          this.getInterestTime();
        }
      }
      this.isShowPopWidget = n;
      this.zhuanruAmount = '';
    }
  },
  computed:{
    containerWidth(){
      let w = this.isOut?'5.6rem':'6.6rem';
      return w;
    }
  },
  components:{
    PopWidget,
  }
}
</script>

<style scoped lang="stylus">
.yeb-io-wrap
  width 100%
  height 2.5rem
  padding-top .06rem
  &.isout
    padding-top .22rem
  .confirm-btn
    width 1.2rem
    margin-top .04rem
  .yeb-io-icode
    width .2rem
    margin-right .08rem
  .row-yeb
    color #A4682A
    font-size .18rem
    justify-content center
    align-items center
    &.amount
      margin .08rem 0
      color red
      font-size .24rem
      &.out
        margin .16rem 0 .1rem 0
    .yeb-wrapper
      position relative
    .yeb-io-input
      width 2.68rem
      height .32rem
      padding-left .1rem
      margin-left .2rem
      border-radius .14rem
      background-color #BCA078
    .all-out-btn
      position absolute
      right -.04rem
      top 0
      width .92rem
    .got-des
      color #86745E
    .got-time
      color #98281A
    &.amount-des
      font-size .14rem
      margin .08rem 0
  .rule-descipt
    padding-left .6rem
    text-align left
    width 6rem
    img
      width .12rem
      margin-right .08rem
    &.isout
      padding-left 0
      width 100%
      text-align center
      margin .2rem auto
      width 80%
</style>