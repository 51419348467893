<template>
  <div class="sign-act-wrapper">
    <div class="flex des">
      <div>活动介绍：</div>
      <div class="activity-des" :class="{hide:isHide}"
        v-html="localDesc">
      </div>
      <div @click="hideClick">
        {{ isHide==1?'详情':'隐藏' }}
      </div>
    </div>

    <div class="item-wrap" v-if="isHide==1">
      <div class="item-inner flex" v-for="item of localRuleList">
        <div class="coin-ico-wrap">
          <img class="coin-ico" src="../../assets/activity/coin-ico.png" />
          <div class="co-amount" v-if="activity.type != 'bet_number'">
            {{beautifyAmount(item.amount)}}
          </div>
        </div>
        <div class="ac-des flex">
          {{getBunosDes(item)}}
        </div>
        <div>
          <img class="arrived" v-show="item.status == 1" 
            src="../../assets/activity/no-arrived.png" />
          <img class="arrived" v-show="item.status == 2" 
            @click="receiveClick(item.id)"
            src="../../assets/activity/can-received.png" />
          <img class="arrived" v-show="item.status == 3" 
            src="../../assets/activity/aready-received.png" />
          <img class="arrived" v-show="item.status == 4" 
            src="../../assets/activity/under-reviewing.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
export default {
  props:{
    activity:Object,
  },
  data(){
    return{
      isHide:1,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    getBunosDes(item){
      const type = this.activity.type;
      let result = item.value;
      switch(type){
        case 'attendance':
          result = `累计签到${item.days}天`
          break;
        case 'bet':
          result = `有效投注${item.value}`
          break;
        case 'bet_number':
          result = `注单尾号${item.value}`
          break;
        case 'recharge':
          result = `充值${item.value}`
          break;
      }
      return result;
    },
    receiveClick(rule_id){
      let p = {
        id:this.activity.id,
        rule_id
      }
      Request.post('/activity/apply',p).then(res => {
        if(res.data.code == 0){
          this.popMsg('领取成功')
        }else if(res.data.msg){
          this.popMsg(res.data.msg)
        }
      }).catch(error => {
        if(error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg)
        }else{
          this.popMsg('出错，请联系客服')
        }
      })
    },
    beautifyAmount(amount){
      return Math.floor(amount * 1);
    },
    hideClick(){
      this.isHide = this.isHide == 1?0:1
    }
  },
  computed:{
    localRuleList(){
      let isByValue = () => {
        if(this.activity && this.activity.rule && this.activity.rule.length){
          if(this.activity.rule[0].hasOwnProperty('value')){
            return 1;
          }
        }
      }
      if(this.activity && this.activity.rule){
        let sortKey = ''
        if(this.activity.type == 'attendance'){
          sortKey = 'days'
        }else if(isByValue()){
          sortKey = 'value'
        }
        if(sortKey){
          this.activity.rule.sort((a,b) => a[sortKey] - b[sortKey])
          return this.activity.rule;
        }
        return this.activity.rule;
      }
      return []
    },
    localDesc(){
      if(this.activity && this.activity.mb_desc){
        return this.activity.mb_desc.replaceAll('\r\n','<br/>');
      }else{
        return ''
      }
    }
  },
}
</script>

<style lang="stylus">
.activity-des
  color #A49377
  font-size .12rem
</style>
<style scoped lang="stylus">
.sign-act-wrapper
  width 100%
  .des
    padding .12rem 0 0 .17rem
  .item-wrap
    width 93%
    margin 0 auto
    padding-top .1rem
    .item-inner
      width 100%
      height .38rem
      box-shadow 0 2px 3px 0 rgba(0,0,0,.3)
      margin-bottom .09rem
      border 1px solid #D6C08E
      border-radius .05rem
      background-image linear-gradient(#FFF3CB,#F9EDC3,#F5E8BC,#EBDBAA,#E9D7A5)
      .coin-ico-wrap
        padding .04rem 0 0 .03rem
        position relative
        .co-amount
          position absolute
          bottom 0
          right 0
          color white
          font-size .12rem
      .coin-ico
        width .3rem
      .arrived
        width .78rem
      .ac-des
        width 2.6rem
        padding-left .12rem
        justify-content flex-start
        align-items center
  .activity-des
    width 2.37rem
    margin 0 .12rem
    text-align left
    padding-bottom .15rem
    &.hide
      height .17rem
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
</style>