import { createStore } from 'vuex'
export default createStore({
  state: {
    popMsgParam:'',
    testAnL:0,
    // 内部游客登录成功
    innerAnonymousLogined:0,
    alertMsg:'',
    isShowReporting:0,
    isPopRecharge:0,
    isShowLoading:'',
    musicSwitch:0,
    envelopShow:0,
    isUpdateAmountAndYeb:0,
    isUserInfoLoaded:0,
    isUpdateUserCoin:0,
    isUserLoginSuccess:0,
    msgAlertClose:0,
    isUserInfoApiDataUpdated:0,
    language:'',
    preloaded:0,
  },
  getters: {
    getPreloaded(state){
      return state.preloaded;
    },
    getIsUserInfoApiDataUpdated(state){
      return state.isUserInfoApiDataUpdated;
    },
    getUserInfoLoaded(state){
      return state.isUserInfoLoaded;
    },
    getUpdateAmountAndYeb(state){
      return state.isUpdateAmountAndYeb;
    },
    getEnvelopShow(state){
      return state.envelopShow;
    },
    getRedpackCountingDownTime(state){
      return state.redpackCountingDownTime
    },
    getMusicSwitch(state){
      return state.musicSwitch
    },
    getShowLoading(state){
      return state.isShowLoading
    },
    getLanguage(state){
      return state.language
    },
    getShowReporting(state){
      return state.isShowReporting
    },
    getAlertMsg(state){
      return state.alertMsg
    },
    getMsgAlertClose(state){
      return state.msgAlertClose;
    },
    getPopMsgParam(state){
      return state.popMsgParam;
    },
    getAnonymousLogin(state){
      return state.testAnL;
    },
    getInnerAnonymousLogined(state){
      return state.innerAnonymousLogined;
    },
    getIsPopRecharge(state){
      return state.isPopRecharge;
    },
    getIsUpdateUserCoin(state){
      return state.isUpdateUserCoin;
    },
    getIsUserLoginSuccess(state){
      return state.isUserLoginSuccess;
    },
  },
  mutations: {
    setPreloaded(state){
      state.preloaded = Math.random()
    },
    setLanguage(state,value){
      state.language = value;
    },
    userInfoApiDataUpdated(state){
      state.isUserInfoApiDataUpdated = Math.random();
    },
    userLoginSuccess(state){
      state.isUserLoginSuccess = Math.random();
    },
    IsupdateUserCoin(state){
      state.isUpdateUserCoin = Math.random();
    },
    userInfoLoaded(state){
      state.isUserInfoLoaded = Math.random()
    },
    updateAmountAndYeb(state){
      state.isUpdateAmountAndYeb = Math.random();
    },
    setEnvelopShow(state,value){
      state.envelopShow = value;
    },
    setMusicSwitch(state,value){
      state.musicSwitch = value;
    },
    showLoading(state){
      let timeout = state.isShowLoading.split('-')[0];
      clearTimeout(timeout)
      let loadingTimeout = setTimeout(() => {
        this.commit('closeLoading')
      },1000*15);
      state.isShowLoading = `${loadingTimeout}-${Math.random()}`;
    },
    closeLoading(state){
      let timeout = state.isShowLoading.split('-')[0];
      clearTimeout(timeout)
      state.isShowLoading = '';
    },
    popRecharge(state){
      state.isPopRecharge = Math.random();
    },
    showReporting(state){
      state.isShowReporting = Math.random();
    },
    alert(state,msg){
      state.alertMsg = `${msg}-16888-${Math.random()}`
    },
    msgAlertClose(state){
      state.msgAlertClose = Math.random()
    },
    storeUserInfo(state,obj){
      state.userInfo = obj;
    },
    innerAnnoLogined(state){
      state.innerAnonymousLogined = Math.random();
    },
    anonymousLogin(state){
      state.testAnL = Math.random();
    },
    popMsg(state,data){
      let msg = '',time = 0;
      if(typeof data == 'object'){
        msg = data.msg;
        time = data.time;
      }else if(typeof data == 'string'){
        msg = data;
      }
      let show = Math.random();
      let msgJoin = `${show}-${msg}`;
      if(time){
        msgJoin += `-${time}`;
      }
      state.popMsgParam = msgJoin;
    },
  },
  actions: {
  },
  modules: {
  }
})
