import zn from "./zn.js"
import ptBr from "./pt-br.js"
import { createI18n } from 'vue-i18n'

let localLan = localStorage.getItem('language');
if(!localLan || process.env.NODE_ENV == 'production'){
  localStorage.setItem('language',globalConfig.language);
}
const i18n = createI18n({
  legacy: false,
  locale:localStorage.getItem('language')||'zn',
  messages: {
    "zn":zn,
    "pt-BR":ptBr
  }
})

export default i18n;
