export default {
  signUp:{
    labelTelephone:'telefone',
    telPlaceholder:'input tel mobile',
    smsCode:'sms code',
    smsPlaceholder:'input sms code',
    smsCodeCountingdown:'after 7d7 second could be resent',
    inputPwd:'input pwd',
    pwdPlaceholder:'pwd 6-20 length',
    inputConfirmPwd:'confirm pwd',
    confirmPwdPlaceholder:'confirm pwd place',
    inviteCode:'invite code',
    mobileError:'mobile error',
    picValidCode:'picture v code',
    picVCodeHolder:'pic code p holder'
  },
  userCenter:{
    menu:{
      userMessage:'个人信息',
      levelEquity:'等级特权',
      vipDetails:'VIP详情',
      amountDetails:'资金明细',
      betQuery:'注单查询',
      userReport:'个人报表',
    },
    info:{
      nickname:'alcunha',
      gameid:'Id do jogo',
      gender:'sexo',
      music:'música',
      musicOn:'li',
      musicOff:'des',
      labelTelephone:'telefone',
      bankAccount:'conta bancária',
      alipay:'alipay',
      wallet:'carteira digital',
      genderMale:'Masculino',
      genderFemale:'Feminino',
      telPlaceholder:'Preencha o número do celular',
      bankPlaceholder:'Preencha a conta bancária',
      alipayPlaceholder:'Preencha Alipay',
      bound:'vinculado',
      unbound:'nãoVinculado',
      bind:'ligar',
      quitConfirm:'Tem certeza que deseja sair?',
      nicknameInputLabel:'Insira um apelido',
    },
    vip:{
      subDesc:'sub',
      subDesc1:'code sheng ji dao',
      vipDescription:'Caro membro VIP7d7, você pode desfrutar dos seguintes direitos',
      currLevelReceive:'curr level receive',
      promoteAmount:'jinji caijin',
      weekAmount:'zhou lijin',
      monthAmount:'monthAmount',
      extraTopupOffer:'extraTopupOffer',
      amountUnit:'￥',
      extraUnit:'%',
    },
    amountDetails:{
      filterDate:'Date',
      filterToday:'Today',
      filterYesterdy:'Yesterday',
      dateChoice:'date choice',
      filterType:'Type',
      typeChoice:'Type choice',
      filterAllType:'All type',
      tbDatetime:'Date time',
      tbTurnoverAmount:'liushuijine',
      afterAmount:'zhihhoujine',
      tbIOtype:'shouzhileixing',
      amounttype:'amount type',
      remargik:'beizhu'
    },
    bet:{
      tabQipai:'tabQipai',
      tabBuyu:'tabBuyu',
      tabDianzi:'tabDianzi',
      tabRealMan:'tabRealMan',
      tabCaipiao:'tabCaipiao',
      tabSport:'tabSport',
      platform:'platform',
      platformChoice:'platformChoice',
      allPlatform:'allPlatform',
      game:'game',
      gameChoice:'gameChoice',
      gameAll:'game all',
      tbStartTime:'tbStartTime',
      tbEndTime:'tbEndTime',
      juHao:'juHao',
      gameType:'gameType',
      activeBet:'activeBet',
      windLostAmount:'amount',
      summation:'Sum'
    }
  },
  recharge:{
    zxsf:'专享闪付',
    quickRecharge:'quick recharge',
    lineChoicer:'Seleção de linhas',
    receiveBank:'receiveBank',
    receiveName:'receivename',
    receiveAccount:'receiveAccount',
    reAmount:'Quantidade de recarga',
    amountInputTip:'input min 7min7 and,max 7max7',
    payMan:'payMan',
    payManInputTip:'input pay man tip',
  },
  yuebao:{
    totalAmount:'total amount',
    confirmAmount:'confirm amount',
    beforeConfirm:'before confirm',
    lastRoundEarnings:'lst earnings ($)',
    cumulativeIncome:'cumulative income',
    interestRatePerRound:'rate per round ($)',
    dailyIncome:'daily income ($)',
  },
  yebDetail:{
    all:'All',
    income:'Income',
    transferIn:'Transfer in',
    transferOut:'Transfer out',
  },
  yebInOut:{
    yebBalance:"Yu'e Bao balance ($)",
    accountBalance:'account balance ($)',
    transferOLabel:'out amount',
    transferILabel:'in amount',
    transferOPlaceholder:'please full out amount',
    transferIPlaceholder:'please full in amount',
    firstIncomeDes:'first income des:',
    inRule1:'Transfer amount must be ≥ 1',
    inRule2:'No transfer limit;',
    inRule3:"amount can be accumulated, and the amount transferred to Yu'e Bao is calculated at the daily interest",
    outRule:"If the amount transferred out is greater than the amount in Yu'E Bao, it cannot be transferred out.",
    transferInSuccess:'transfer in success!',
    securitDes:'For your account security, please enter your',
    yuebaoPwd:"Yu'e Bao transaction password",
    yebTranserPwd:'transfer password',
    pwdPlaceholder:'Please enter the 6-digit transaction password',
    customerOnLine:'customer online',
    yebEmptyDes:'Please enter the transaction password',
    transferOutEnptyDes:'Please enter out amount',
    transferInEnptyDes:'Please enter in amount',
  },
  nodata:'no data',
};
