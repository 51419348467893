<template>
  <div class="login-wide" :class="{show:show}" @click.stop="bgClickHandle"
    :style="{opacity:`${hidden?0:1}`}" 
    @touchmove.stop="" @touchstart.stop="">
    <div class="login-w-border" :style="{width:width}"
      :class="[language,`${borderShow?'show':''}`,`${isVictRotate?'v-rotate':''}`]"
      @click.stop="">
      <div class="login-w-i">
        <div class="login-t-bar flex" :class="language">
          <slot name="first-title">
          </slot>
          <div class="container-title" :class="language">
            <slot name="titletext">
            </slot>
          </div>
          <slot name="title">
          </slot>
          <img @click="closeClick" class="l-t-close" 
            v-if="!language || language == 'zn'"
            src="../../assets/login/login-close.png" />
          <img @click="closeClick" class="l-t-close" 
            :class='language' v-if="language == 'pt-BR'"
            src="../../assets/login/login-close-pt-BR.png" />
        </div>
        <div class="login-c-w">
          <div class="o-background" :class="language">
            <div class="i-background" :class="[isFull?'full':'',`${language}`]">
              <slot name="content">
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tool from '../../common/Tool.js'
export default {
  props:{
    bgClickClose:Number,
    isShow:Number,
    isFull:{
      type:Number,
      require:false
    },
    width:{
      type:String,
      require:false
    }
  },
  data(){
    return{
      show:0,
      borderShow:0,
      hidden:0,
      isVictRotate:0,
    }
  },
  mounted(){
    this.horizontalVerctCheck();
  },
  methods:{
    horizontalVerctCheck(){
      let frame = () => {
        let noNeedShuping = Tool.noNeedShupingRotate();
        if(location.href.indexOf('/customer') > -1){
          noNeedShuping = false;
        }
        let gameRotate = localStorage.getItem('game-is-shuping');
        if(gameRotate){
          gameRotate =  1 * gameRotate;
        }else{
          gameRotate =  0;
        }

        if(innerHeight > innerWidth && noNeedShuping && !gameRotate){
          this.isVictRotate = 1;
        }else{
          this.isVictRotate = 0;
        }
      }
      frame();
      window.addEventListener('orientationchange',frame);
      window.addEventListener('resize',frame);
    },
    bgClickHandle(){
      if(this.bgClickClose){
        this.closeClick();
      }
    },
    closeClick(){
      this.hidden = 1;
      this.$emit('hideClosed')
      setTimeout(() => {
        this.borderShow = 0
        this.show = 0
        this.$emit('closed')
      },600)
    }
  },
  watch:{
    isShow(n,o){
      const _this = this;
      this.hidden = 0;
      if(n){
        this.show = n;
        setTimeout(() => {
          _this.borderShow = n
        })
      }else{
        this.closeClick();
      }
      
    }
  },
  computed:{
    language(){
      return localStorage.getItem('language')
    }
  }
}
</script>
<style scoped lang="stylus">
.login-wide
  width 100%
  height 100%
  position fixed
  background-color rgba(0,0,0,.25)
  z-index 3
  top 0
  left 0
  display none
  &.show
    display flex
  .login-w-border
    width 4.9rem
    height auto
    margin auto
    padding .01rem 0
    border 2px solid rgba(141,129,120,.8)
    border-radius 5px
    display flex
    &.v-rotate
      transform rotateZ(90deg)
    &.pt-BR
      border none
    .login-w-i
      width 100%
      height auto
      margin auto
      text-align center
      .login-t-bar
        width calc(100% - .02rem)
        height .35rem
        margin auto
        background-image url('../../assets/login/top-tool-bar.png')
        background-size cover
        position relative
        &:deep(.l-t-title)
          width 2.14rem
          margin -0.04rem auto 0 auto
        .l-t-close,:deep(.l-t-close)
          width .38rem
          height .35rem
          right 0
          top .01rem
          position absolute
          &.pt-BR
            width .35rem
        &.pt-BR
          background-image url('../../assets/login/widget-title-bg.png')
          background-repeat no-repeat
          background-repeat repeat-x
          background-size auto
        .container-title
          display none
          height .3rem
          min-width 2.14rem
          margin 0 auto
          background-size 100% 100%
          justify-content center
          align-items center
          &.pt-BR
            display flex
            background-image url(../../assets/recharge/recharge-title-pt-BR.png)
      .login-c-w
        width calc(100% - .02rem)
        margin .01rem auto 0 auto
        position relative
        .o-background
          padding .1rem
          background-image url('../../assets/login/sign-bg1.png')
          background-size 100% 100%
          &.pt-BR
            background #F5D2B2
          .i-background
            background-image url('../../assets/login/sign-bg.png')
            background-size 100% 100%
            padding-bottom .2rem
            text-align center
            &.full
              padding-bottom 0
            &.pt-BR
              background none
        .l-c-bg1
          width 100%
          height 2.14rem
          display block
        .l-c-bg
          width 4.66rem
          height 1.94rem
          display block
          position absolute
          top 50%
          left 50%
          transform translateX(-50%) translateY(-50%)
        .l-c-w-i
          width 100%
          border-top 1px solid transparent
          padding-top .2rem
          .l-c-w-ir
            width 100%
            display flex
            margin-top .18rem
            justify-content center
            align-items center
            color #8D6935
            &.reg
              font-size .12rem
              margin-top .1rem
              font-weight bold
              justify-content flex-end
              & > div
                margin-right .18rem
                &.forget-link
                  margin-right .4rem
            .l-label
              font-weight bold
            .confirm-btn
              width 1.12rem
            input
              background-color #BEA37B
              border none
              border-radius .12rem
              width 2rem
              height .25rem
              color #fff
              padding-left .05rem
</style>