import lottie from 'lottie-web'
import JSZip from 'jszip'
import PounchDbHandle from './PounchDbHandle.js'

export default {
  showIco(zipurl,name,element,widthHeightRatio,callback) {
    lottie.destroy(name)
    lottieAnimate(zipurl,name,element,callback)

    async function fetchZip(zipUrl) {
      async function getBlocb(){

        let localBlob = await PounchDbHandle.getAnimateBlob(zipUrl)
        if(localBlob && localBlob.size){
          return localBlob;
        }
        const response = await fetch(zipUrl);
        const blob = await response.blob();
        await PounchDbHandle.addAnimateBlob(zipUrl,blob)

        return blob;
      }

      const blob = await getBlocb();
      const zip = new JSZip();
      await zip.loadAsync(blob);
      return zip;
    }

    async function getImageFiles(zip) {
      const imageFiles = [];
      zip.forEach((relativePath, zipEntry) => {
        if (zipEntry.name.endsWith('.png')) {
          imageFiles.push(zipEntry);
        }
      });
      return imageFiles;
    }

    async function getSortedImageFiles(imageFiles, data) {
      const oldData = JSON.parse(data);
      return oldData.assets
        .filter(asset => asset.id.startsWith('image_'))
        .map(asset => {
          const zipEntry = imageFiles.find(entry => entry.name.endsWith(asset.p));
          if (!zipEntry) {
            console.error('Zip entry not found for asset:', asset.p);
            return null;
          }
          return zipEntry;
        })
        .filter(entry => entry !== null);
    }

    async function updateAnimationAssets(animation, loadImageData) {
      animation.assets.forEach((obj, index) => {
        if (obj.id.startsWith('image_')) {
          animation.assets[index] = {
            id: obj.id,
            w: obj.w,
            h: obj.h,
            u: "data:image/png",
            p: ';base64,' + loadImageData[index]
          };
        }
      });
    }

    async function lottieAnimate(zipUrl,animateName,conElement, callback) {
      try {
        zipUrl = zipUrl.replace('http:','https:');
        const zip = await fetchZip(zipUrl);
        const imageFiles = await getImageFiles(zip);
        const dataFile = zip.file('data.json');
        if(!dataFile)return;
        const data = await dataFile.async('text');
        const animation = JSON.parse(data);
        const sortedImageFiles = await getSortedImageFiles(imageFiles, data);
        const loadImageData = await Promise.all(sortedImageFiles.map(file => file.async('base64')));
        await updateAnimationAssets(animation, loadImageData);

        let lottieObj = null;
        if(conElement){
          conElement.innerHTML = '';
          let options = {
            container: conElement,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name:animateName,
            animationData: animation
          };
          if(widthHeightRatio){
            options.rendererSettings = {
              progressiveLoad: false,
              preserveAspectRatio: "xMidYMid slice"
            }
          }
          lottieObj = lottie.loadAnimation(options);
        }
        if(callback)callback(lottieObj)
      } catch (error) {
        if(callback)callback()
        console.log(error);
      }
    }

  },
}
