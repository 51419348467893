<template>
  <div class="recharge-active flex" @touchmove.stop="" v-show="localShow">
    <div class="main-wrap p-relative" ref="rechargeActMainWrap">
      <div class="h-w-wrap">
        <div class="w-h flex">
          <div class="total-amount-w">
            <div style="white-space: nowrap;text-overflow:ellipsis;overflow:hidden;">
              总金额:{{rechargeBonusInfo.total_amount}}&nbsp;&nbsp;未解锁:{{rechargeBonusInfo.can_unlock}}
            </div>
            <div class="p-relative">
              已领取:{{rechargeBonusInfo.total_claimed}} <a @click="showDetails" class="amount-details-link">详情</a>
            </div>
          </div>
          <div class="flex unlock-w">
            <div>已解锁:{{rechargeBonusInfo.can_claim}}</div>
            <div @click="receiveClickHandle" class="receive-btn flex" :class="{disabled:receiveIsDisable}">
              领&nbsp;取
            </div>
          </div>
        </div>
        <div class="recharge-bonus-des p-relative">
          <div class="max-gift-r-value">{{maxGiftRatioV}}%&nbsp;</div>
          <a class="amount-details-link" @click="showDetails2">详情</a>
        </div>
        <div class="flex r-b-l-con">
          <div class="r-b-i-wrap flex p-relative" :class="`bg-${i}`" v-for="(item,i) of rechargeBonusList">
            <img class="arrow" src="../../assets/activity/czq_img_jt.png" />
            <div class="r-bonus-title">
              {{item.gift_ratio}}%奖金
            </div>
            <img class="anim-ico" v-if="i == 0" src="../../assets/activity/tg_img_ta1.png" />
            <img class="anim-ico" v-if="i == 1" src="../../assets/activity/tg_img_ta2.png" />
            <img class="anim-ico" v-if="i == 2" src="../../assets/activity/tg_img_ta3.png" />
            <img class="anim-ico" v-if="i == 3" src="../../assets/activity/tg_img_ta4.png" />
            <div>第{{item.recharge_count}}次充值</div>
          </div>
        </div>
        <div class="go-recharge-btn flex" @click="goRecharge" :class="`no-${rechargeCount}`">
          去充值
        </div>
      </div>
      <img class="close-wid-btn" @click="bonusClickHandle" src="../../assets/activity/act-bonus-close.png" />
    </div>

    <!-- 活动详情 -->
    <div class="bonus-act-details flex" v-show="bonusDetailsShow">
      <div class="b-a-d-wrapper p-relative">
        <div class="czq-img-tt">
          详情
        </div>
        <img class="czg-btn-gb" @click="bonusCloseClick" src="../../assets/activity/czg_btn_gb.png" />
        <div class="b-a-w-content flex">
          <div class="b-a-w-border">
            <div class="big-info-w p-relative flex">
              <div class="already-unlock">已解锁：<span>{{rechargeBonusInfo.can_claim}}</span></div>
              <div @click="receiveClickHandle" class="receive-btn flex" :class="{disabled:receiveIsDisable}">
                领&nbsp;取
              </div>
            </div>
            <div class="big-info-w two p-relative flex">
              <div class="total-amount-field">总金额：<span>{{rechargeBonusInfo.total_amount}}</span></div>
              <div class="how-to-unlock" @click="unlockLinkClick">如何解锁</div>
            </div>
            <div class="t-row hd">
              <div>时间</div>
              <div>充值金额</div>
              <div>奖励金额</div>
              <div>过期时间</div>
            </div>
            <div class="t-r-scroll" @scroll="pageScrollerScrolling">
              <div class="t-row" v-for="item of rechargeBonusPageData">
                <div>{{item.created_at}}</div>
                <div>{{item.recharge_amount}}</div>
                <div>{{item.total}}</div>
                <div>{{item.expire_at}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="how-to-unlock-bg flex" v-show="toUnlockTouristShow" @click="toUnlockTouristShow = 0">
        <div class="back" @click.stop="" v-html="getPretyHtml(rechargeBonusInfo.detail_unlock)">
        </div>
      </div>
    </div>

    <!-- 充值送 -->
    <div class="send-bonus-recharge flex" v-show="bonusSendShow">
      <div class="b-a-d-wrapper p-relative">
        <div class="czq-img-tt">
          充值送送
        </div>
        <img class="czg-btn-gb" @click="sendBonusCloseClick" src="../../assets/activity/czg_btn_gb.png" />
        <div class="b-a-w-content flex">
          <div class="b-a-w-border">
            <div class="r-b-i-desc">{{rechargeBonusInfo.desc}}</div>
            <div class="r-b-item-wrap flex">
              <div class="r-b-i-wrap flex p-relative" :class="`bg-${i}`" v-for="(item,i) of rechargeBonusList">
                <div>第{{item.recharge_count}}次充值</div>
                <div class="r-bonus-title">
                  {{item.gift_ratio}}%
                  <div>奖金</div>
                </div>
                <div class="recharge-row">
                  <div class="flex">
                    <div>最低存款额：</div><div>{{item.min_recharge}}元</div>
                  </div>
                  <div class="flex">
                    <div>最高奖励：</div><div>{{item.max_amount}}元</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Tool from '../../common/Tool.js';
import Request from '../../common/Request.js';
import { mapMutations } from 'vuex';

export default{
  props:{
    show:Number
  },
  data(){
    return{
      rechargeBonusInfo:{},
      rechargeCount:1,
      rechargeBonusList:[],
      rechargeBonusDataPager:{},
      rechargeBonusPageData:[],
      localShow:0,
      bonusDetailsShow:0,
      toUnlockTouristShow:0,
      bonusSendShow:0,
      bonusListPage:1,
      bonusListPageGetting:0,
    }
  },
  mounted(){
    this.layout();
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    getPretyHtml(source){
      if(!source) return source;
      return Tool.replaceAll(source,'font-size:','t');
    },
    goRecharge(){
      let found = 0;
      this.rechargeBonusList.forEach(b => {
        if(b.recharge_count == this.rechargeCount){
          found = 1;
        }
      })
      if(!found) return;
      
      this.localShow = 0
      this.$emit('goToRecharge')
    },
    unlockLinkClick(){
      this.toUnlockTouristShow = Math.random();
    },
    sendBonusCloseClick(){
      this.bonusSendShow = 0;
    },
    bonusClickHandle(){
      this.localShow = 0
    },
    showDetails(){
      this.bonusDetailsShow = Math.random()
      this.bonusListPage = 1;
      this.rechargeBonusPageData = []
      this.getBonusListPager()
    },
    showDetails2(){
      this.bonusSendShow = Math.random()
    },
    getBonusListPager(){
      //假数据
      let shide = () => {
        this.rechargeBonusPageData = []
        let i = 0;
        while(i < 10){
          this.rechargeBonusPageData.push({
            created_at:'2023-8-5',
            recharge_amount:123,
            total:312,
            expire_at:'2023-8-5',
          })
          i++
        }
      }
      shide();
      return
      this.showLoading()
      Request.get(`/pay/bonus_list?page=${this.bonusListPage}`).then(res => {
        this.rechargeBonusDataPager = res.data.data;
        this.rechargeBonusPageData = this.rechargeBonusPageData.concat(this.rechargeBonusDataPager.data)
        
        

      }).finally(() => {
        this.bonusListPageGetting = 0
        this.closeLoading()
      })
    },
    pageScrollerScrolling(e){
      let sTop = e.target.scrollTop;
      let scrollSpace = sTop + e.target.clientHeight
      let sub = e.target.scrollHeight - scrollSpace
      let {per_page,total} = this.rechargeBonusDataPager;
      let maxPage = Math.ceil(total / per_page)

      if(sub < 5 && this.bonusListPage < maxPage && !this.bonusListPageGetting){
        this.bonusListPageGetting = 1

        this.bonusListPage++
        this.getBonusListPager();
      }
    },
    getRechargeBonusList(){
      let dataHandle = (data) => {
        this.rechargeBonusInfo = data;
        this.rechargeCount = data.recharge_count;
        this.rechargeBonusList = data.rules;
      }
      if(process.env.NODE_ENV == 'development'){
        this.rechargeBonusInfo.desc = 'HY电玩城每周提供四次【加密货币】【所有数字钱包】专属充值奖励活动，每次充值时送出对应比例的奖励！';
        this.rechargeBonusList = [
          {
            gift_ratio:2,
            recharge_count:1,
            min_recharge:100,
            max_amount:1000,
          },
          {
            gift_ratio:2,
            recharge_count:2,
            min_recharge:100,
            max_amount:1000,
          },
          {
            gift_ratio:2,
            recharge_count:3,
            min_recharge:100,
            max_amount:1000,
          },
          {
            gift_ratio:2,
            recharge_count:4,
            min_recharge:100,
            max_amount:1000,
          }
        ]
        return;

        let localData = Tool.getPayBonusInfo()
        if(localData){
          dataHandle(localData)
          return;
        }
      }
      this.showLoading()
      Request.get('/pay/bonus_info').then(res => {
        Tool.setPayBonusInfo(res.data.data)
        dataHandle(res.data.data)
      }).finally(() => {
        this.closeLoading()
      })
    },
    layout(){
      Tool.getBase64Image('/layout/recharge-act-bg.png').then(res => {
        this.$refs.rechargeActMainWrap.style.backgroundImage = `url(${res})`;
      })
    },
    bonusCloseClick(){
      this.bonusDetailsShow = 0
    },
    receiveClickHandle(){
      if(this.receiveIsDisable){
        return;
      }
      this.showLoading()
      Request.post('/pay/bonus_info_claim').then(res => {
        if(res.data && res.data.code == 0){
          this.popMsg('领取成功!');
          this.getRechargeBonusList();
        }
      }).catch(err => {
        let msg = '领取失败，请联系客服';
        if(err && err.response && err.response.data && err.response.data.msg){
          msg = err.response.data.msg;
        }
        this.popMsg(msg)
      }).finally(() => {
        this.closeLoading()
      })
    },
  },
  watch:{
    show(n){
      if(n){
        this.getRechargeBonusList();
      }
      this.localShow = n;
    }
  },
  computed:{
    maxGiftRatioV(){
      let maxGiftRatio = 0
      this.rechargeBonusList.forEach(rItem => {
        if(rItem.gift_ratio > maxGiftRatio){
          maxGiftRatio = rItem.gift_ratio
        }
      })
      return maxGiftRatio;
    },
    receiveIsDisable(){
      let {can_claim,total_claimed,total_amount} = this.rechargeBonusInfo;

      if(can_claim > 4 || 
        can_claim > 0 && (can_claim + total_claimed) == total_amount){
        return 0; // 可以领
      }else{
        return 1; // 不能领
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.recharge-active
  width 100%
  height 100%
  left 0
  top 0
  z-index 4
  position absolute
  background-color rgba(0,0,0,.25)
  .main-wrap
    width 5.88rem
    height 3.28rem
    background-size 100% 100%
    margin auto
    .close-wid-btn
      position absolute
      width .44rem
      right .02rem
      top .14rem
    .h-w-wrap
      width 5.08rem
      height auto
      margin .5rem 0 0 .4rem
      .w-h
        width 100%
        height .68rem
        align-items center
        border-radius .06rem
        border .02rem solid #F6E5B7
        background-color rgba(0,0,0,.1)
        color #8E4F26
        font-size .15rem
        font-weight bold
        padding-left .14rem
        .total-amount-w
          width 46%
          & > div
            line-height .25rem
          .amount-details-link
            color #368C2B
            position absolute
            right .28rem
            bottom 0
            font-weight bold
            font-size .14rem
            border-bottom 1px solid
            line-height .22rem
        .unlock-w
          width 54%
          height 78%
          border-left .01rem solid #C6A57A
          justify-content center
          align-items center
        .receive-btn
          background-image url(../../assets/tuiguang/receive-btn-bg.png)
          width 1.18rem
          height .36rem
          background-size 100% 100%
          margin-left .16rem
          font-size .16rem
          font-weight bold
          line-height .34rem
          justify-content center
          &.disabled
            background-image url(../../assets/activity/bonus-r-disabled.png)
            color #888888
      .recharge-bonus-des
        width 2rem
        height .26rem
        margin .06rem auto .07rem 1.13rem
        background-image url(../../assets/activity/recharge-bonus.png)
        background-size 100% 100%
        .max-gift-r-value
          width .87rem
          height .42rem
          position absolute
          right -.94rem
          bottom -.08rem
          font-size .36rem
          font-weight bold
          font-style italic
          background-image linear-gradient(#E99129,#E08329,#C85E26)
          -webkit-background-clip text 
          letter-spacing -.03rem
          -webkit-text-fill-color transparent
        .amount-details-link
          color #368C2B
          position absolute
          right -1.3rem
          bottom 0
          font-weight bold
          font-size .14rem
          border-bottom 1px solid
      .go-recharge-btn
        background-image url(../../assets/tuiguang/receive-btn-bg.png)
        width 1.08rem
        height .3rem
        background-size 100% 100%
        justify-content center
        align-items center
        font-size .15rem
        font-weight bold
        line-height .34rem
        color #8e4f26
        margin-top .05rem
        &.no-2
          margin-left 1.33rem
        &.no-3
          margin-left 2.63rem
        &.no-4
          margin-left 3.94rem
        &:not(.no-1):not(.no-2):not(.no-3):not(.no-4)
          margin-left 3.94rem
          background-image url(../../assets/activity/bonus-r-disabled.png)
          color #515151
  .r-b-l-con
    margin-left .03rem
  .r-b-i-wrap
    width 1.05rem
    height 1.16rem
    background-size 100% 100%
    margin-left .26rem
    flex-direction column
    align-items center
    color #0C0703
    &:nth-child(1)
      margin-left 0
      .arrow
        display none
    &.bg-0
      background-image url(../../assets/activity/r-act-bg1.png)
    &.bg-1
      background-image url(../../assets/activity/r-act-bg2.png)
    &.bg-2
      background-image url(../../assets/activity/r-act-bg3.png)
    &.bg-3
      background-image url(../../assets/activity/r-act-bg4.png)
    .arrow
      position absolute
      width .23rem
      left -.24rem
      top 50%
      transform translateY(-50%)
    .r-bonus-title
      padding-top .1rem
      font-size .16rem
    .anim-ico
      height .6rem
  .send-bonus-recharge
    .b-a-w-border
      background-color #CCC7A1
      .r-b-i-desc
        padding .05rem
        color #6E2F0E
        font-size .14rem
        padding .05rem .05rem .12rem .05rem
      .r-b-item-wrap
        width 100%
        justify-content space-around
      .r-b-i-wrap
        padding .03rem 0
        width 1.23rem
        height 1.73rem
        margin-left 0
        background-size 100% 100%
        flex-direction column
        align-items center
        color #0C0703
        &.bg-0
          background-image url(../../assets/activity/r-b-item-bg1.png)
        &.bg-1
          background-image url(../../assets/activity/r-b-item-bg2.png)
        &.bg-2
          background-image url(../../assets/activity/r-b-item-bg3.png)
        &.bg-3
          background-image url(../../assets/activity/r-b-item-bg4.png)
        .r-bonus-title
          position absolute
          top .86rem
          width .52rem
          text-align center
          padding 0
          line-height .15rem
          font-size .16rem
          font-weight bold
          & > div
            font-weight 100
            font-size .12rem
        .recharge-row
          width 100%
          position absolute
          bottom .08rem
          left .01rem
          font-size .12rem
          color #F0E5D1
          .flex
            justify-content space-between
            padding 0 .06rem 0 .04rem
  .send-bonus-recharge,
  .bonus-act-details
    width 100%
    height 100%
    left 0
    top 0
    z-index 1
    position absolute
    background-color rgba(0,0,0,.25)
    .how-to-unlock-bg
      position absolute
      width 100%
      height 100%
      top 0
      left 0
      background-color rgba(0,0,0,.25)
      .back
        width 2.88rem
        height 3.32rem
        margin auto
        background-image url(../../assets/activity/how-to-unlock-bg.png)
        background-size 100% 100%
        color #FFFFE0
        padding .14rem .14rem 0 .14rem
        font-size .15rem
        line-height .24rem
    .b-a-d-wrapper
      width 5.68rem
      margin auto
      .czq-img-tt
        width 100%
        height .39rem
        line-height .35rem
        font-size .16rem
        text-align center
        color #FFF2A2
        background-image url(../../assets/activity/czq_img_tt.png)
        background-size 100% 100%
      .b-a-w-content
        width 100%
        height 2.5rem
        margin-top -.03rem
        background-color #DBD4A6
        border-bottom 1px solid #C5BD8F
        border-left 1px solid #C5BD8F
        border-right 1px solid #C5BD8F
        border-radius 0 0 .06rem .06rem
        font-size .14rem
        .b-a-w-border
          width 97%
          height 93%
          margin auto
          border 1px solid #B2AD83
          color #7B3513
          .big-info-w
            background-color #CCC7A1
            width 100%
            margin auto
            height .5rem
            align-items center
            padding-left .42rem
            &:after
              content ' '
              display block
              width 90%
              height .01rem
              background-color #ece4b3
              position absolute;
              bottom 0
              left 50%
              transform translateX(-50%)
            &.two
              &:after
                display none
            span
              color #2F763E
            .receive-btn
              background-image url(../../assets/tuiguang/receive-btn-bg.png)
              width 1.18rem
              height .36rem
              background-size 100% 100%
              font-size .16rem
              font-weight bold
              line-height .34rem
              justify-content center
              &.disabled
                background-image url(../../assets/activity/bonus-r-disabled.png)
                color #515151
            .how-to-unlock
              color #2f763e
              border-bottom 1px solid
            .already-unlock
              width 3.3rem
            .total-amount-field
              width 3.59rem
          .t-r-scroll
            height 1.01rem
            overflow-x hidden
            overflow-y auto
          .t-row
            display flex
            text-align center
            font-size .12rem
            line-height .27rem
            &.hd
              background-color #EDE6B2 !important
              font-size .14rem
            & > div
              width 25%
            &:nth-child(even)
              background-color #EDE6B2
            &:nth-child(odd)
              background-color #DAD3A5
      .czg-btn-gb
        position absolute
        width .2rem
        right .08rem
        top .08rem
</style>

<style lang="stylus">
.how-to-unlock-bg
  .back
    dl
      width 100%
      height 2.82rem
      overflow-x hidden
      overflow-y auto
    dt
      font-size .16rem
      margin-top .14rem
      &:first-child
        margin-top 0
    dd
      margin-left .22rem
</style>