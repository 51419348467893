<template>
  <div class="task-w-back" v-show="showBack"
    @touchstart.stop="" @touchmove.stop="">
    <div class="task-wrapper" :class="{show:showFore}">
      <div class="task-nav">
        <div v-for="(task,i) of taskList" @click="taskExchange(i)"
          class="task-nav-item" :class="{focus:i==selectedIndex}">
          {{ task.activity_name }}
        </div>
      </div>
      <img class="back" src="../../assets/task/task-background.png" />
      <img @click="closeClick"
        class="close-btn" src="../../assets/task/task-close-ico.png" />
      <div class="task-l-wrap">
        <div class="flex task-des">
          <div class="task-descrip" :class="{details:isDetails}"
            v-html="getMbDesHtml()">
          </div>
          <div class="task-d-btn ease-btn-effe"
          :class="{details:isDetails}"
            @click="showDetailsClick">
          </div>
        </div>
        <div v-if="!isDetails">
          <div v-for="(tItem,i) of ruleList" class="flex task-item">
            <div class="flex">
              <div class="coin-wrapper">
                <img class="coin"
                  src="../../assets/task/task-coin-ico.png" />
                <div class="ammount">
                  {{tItem.info.bonus}}
                </div>
              </div>
              <div class="task-des-text" :data-type="tItem.type">
                <span v-if="tItem.type == 3" >
                  充值<span class="amount">{{tItem.info.recharge}}</span>
                </span>
                <span v-if="tItem.type == 1">
                  在{{tItem.info.game_name}}中打码
                  <span class="amount">{{tItem.info.value}}</span>
                </span>
                <span v-if="tItem.type == 2">
                  推荐直属会员
                  <span class="amount">{{tItem.info.user_count}}</span>人
                </span>
              </div>
            </div>
            <div class="opera-wrapper" :class="{'already-re':i == 4}">
              <div v-if="tItem.status != 3" :data-t="tItem.type"
                style="display: flex;justify-content: flex-end;">
                <div v-if="tItem.type==3">
                  {{tItem.info.current}}/{{tItem.info.recharge}}
                </div>
                <div v-if="tItem.type==1">
                  {{tItem.info.current}}/{{tItem.info.value}}
                </div>
                <div v-if="tItem.type==2">
                  {{tItem.info.current}}/{{tItem.info.user_count}}
                </div>
              </div>
              
              <!-- 领奖-->
              <img v-if="tItem.status == 2" 
                @click="getRewardClick(tItem)"
                class="opera-btn ease-btn-effe" 
                src="../../assets/task/receive-reward.png" />
              <!-- 已领取 -->
              <img v-else-if="tItem.status == 3" 
                class="opera-btn already-re ease-btn-effe" 
                src="../../assets/task/alredy-received.png" />
              <div v-else :data-type="tItem.type">
                <img v-if="tItem.type == 3" 
                  class="opera-btn ease-btn-effe"
                  @click="rechargeClick"
                  src="../../assets/task/top-up-btn.png" />
                <img v-if="tItem.type == 1" 
                  class="opera-btn ease-btn-effe"
                  @click="goTogame(tItem.info)"
                  src="../../assets/task/goto-game.png" />
                <img v-if="tItem.type == 2" 
                  @click="goToRecomment"
                  class="opera-btn ease-btn-effe"
                  src="../../assets/task/goto-recomm.png" />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations} from 'vuex'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'

export default {
  props:{
    show:Number,
  },
  data(){
    return {
      showBack:0,
      showFore:0,
      selectedIndex:0,
      taskList:[],
      isDetails:0,
      ruleList:[],
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    getRewardClick(tItem){
      let id = tItem.activity_id;
      let rule_id = tItem.id;
      let query = `id=${id}&rule_id=${rule_id}`
      Request.get(`/activity/apply?${query}`).then(res => {
        if(res.data.code == 0){
          this.popMsg('领取成功')
        }
        tItem.status = 3;
      }).catch(err => {
        this.popMsg(err.response.data.msg)
      })
    },
    rechargeClick(){
      this.$emit('excharge')
    },
    goToRecomment(){
      this.$emit('recomment')
    },
    goTogame(gameInfo){
      this.$emit('goGame',gameInfo)
    },
    showDetailsClick(){
      this.isDetails = this.isDetails == 0 ? 1: 0;
    },
    getData(callback){
      let tList = null // Tool.getActivities()
      if(tList){
        callback(tList)
      }else{
        this.showLoading()
        Request.get('/activity/mission').then(res => {
          this.closeLoading()
          let tList = res.data.data;
          Tool.setActivities(tList)
          callback(tList)
        }).catch(() => {
          this.closeLoading()
        })
      }
    },
    desHandle(list){
      list.forEach(task => {
        task.mb_desc = task.mb_desc.replaceAll('\r\n','<br>')
      });
      return list;
    },
    getActivityRules(){
      if(this.taskList && this.taskList[this.selectedIndex]){
        let task = this.taskList[this.selectedIndex];
        this.ruleList = task.rule
      }
    },
    getMbDesHtml(){
      let r = '';
      if(this.taskList && this.taskList[this.selectedIndex]){
        r = this.taskList[this.selectedIndex].mb_desc;
      }
      return r;
    },
    taskExchange(selectedIndex){
      this.selectedIndex = selectedIndex;
      this.getActivityRules();
    },
    closeClick(){
      this.showBack = 0
    }
  },
  watch:{
    show(n){
      if(n){
        this.showBack = n;
        setTimeout(() => {
          this.showFore = n;
        });
        this.getData(tList => {
          this.taskList = this.desHandle(tList)
          this.getActivityRules();
        })
      }else{
        this.showFore = 0;
        setTimeout(() => {
          this.showBack = 0;
        });
      }
    }
  }
}
</script>
<style scoped lang="stylus">
.task-w-back
  width 100%
  height 100%
  position absolute
  background-color rgba(0,0,0,.4)
  top 0
  left 0
  z-index 3
.task-wrapper
  position absolute
  z-index 2
  top 50%
  left 50%
  transform translateX(-50%) translateY(-52%)
  .task-l-wrap
    width 4rem
    height 2.15rem
    overflow auto
    position absolute
    top .59rem
    left .4rem
    padding 0 0 .1rem .1rem
    .task-des
      margin-bottom .05rem
    .task-item
      width 100%
      height .48rem
      justify-content space-between
      align-items start
      padding-top .03rem
      margin-bottom .1rem
      background-image url(../../assets/task/task-item-back.png)
      background-size 100% 100%
      .task-des-text
        font-weight bold
        color #964E10
        font-size .12rem
        margin .08rem 0 0 .16rem
        .amount
          color #289917
      .opera-wrapper
        font-size .12rem
        width .54rem
        text-align center
        margin-right .08rem
        &.already-re
          margin-right .04rem
      .opera-btn
        width .54rem
        display block
        &.already-re
          width .42rem
          margin-top -.02rem
      .coin-wrapper
        width .4rem
        height .4rem
        margin-left .14rem
        position relative
        .ammount
          width 100%
          font-size .12rem
          position absolute
          bottom 0
          right 0
          text-align right
          color #fff
        .coin
          width 100%
          height 100%
          display block
    .task-descrip
      width 3.31rem
      height .22rem
      line-height .22rem
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
      font-size .12rem
      &.details
        height auto
        overflow auto
        text-overflow initial
        white-space normal
    .task-d-btn
      width .57rem
      height .22rem
      background-image url(../../assets/task/task-d-btn.png)
      background-size 100% 100%
      &.details
        background-image url(../../assets/task/task-d-btn1.png)
  .back
    width 4.9rem
  .close-btn
    width .46rem
    position absolute
    right .06rem
    top .34rem
  .task-nav
    width .3rem
    height auto
    top .5rem
    left .06rem
    position absolute
    .task-nav-item
      width 100%
      height .67rem
      line-height .14rem
      margin-bottom .05rem
      background-image url(../../assets/task/task-item-focus.png)
      background-size 100% 100%
      display flex
      flex-direction column
      justify-content center
      align-items center
      color #F2AF61
      padding 0 .09rem
      font-size .12rem
      position relative
      &.focus
        color #B45B05
        background-image url(../../assets/task/task-item-normal.png)
      
</style>