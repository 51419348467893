<template>
  <div class="qiandao-wrapper" @touchmove.stop=""
    :class="{show:localShow}">
    <div class="qd-container" :class="{show:containerShow}">
      <img class="qd-background" src="../../assets/qiandao/qiandao-bg.png" />
      <img class="qiandao-title" src="../../assets/qiandao/qiandao-title.png" />
      <img class="qiandao-close ease-btn-effe" @click="closeClick"
        src="../../assets/qiandao/qiandao-close.png" />
      <img class="qiandao-dec" src="../../assets/qiandao/qiandao-decorate.png" />
      <div class="qd-content-wrap">
        <div class="qd-item-wrap p-relative flex">
          <div class="qd-item-c flex">
            <div class="qd-item-6" v-for="(item,i) of dayList"
              :data-at="item.isAfterToday"
              :class="{'qd-item-7':i==dayList.length-1,
              status0:!item.isAfterToday,
              status1:item.is_today == 1 && item.status!=1,
              status2:item.isAfterToday}">
              <div class="last-day-cover" 
                v-if="item.status==1 && i==dayList.length-1">
              </div>
              <div class="sign-status" 
                v-if="item.status==1 && item.is_today != 1">
                已签到
              </div>
              <div class="sign-status" 
                v-if="item.status==1 && item.is_today == 1">
                今日已签到
              </div>
              <div class="sign-status" 
                v-if="item.status!=1 && item.is_today == 1">
                今日未签
              </div>
              <div class="sign-status" v-if="item.isAfterToday">
                待签到
              </div>
              <div class="day-number">
                第{{item.day}}天
              </div>
            </div>
          </div>
        </div>
        <div class="des">连续签到天数越多，获得奖励越丰富哦！</div>
        <div @click="actSignin" class="get-jiangli-btn" 
          :class="{'today-signed':todayIsSign,'ease-btn-effe':!todayIsSign}">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tool from '@/common/Tool'
import { mapMutations } from 'vuex';
import Request from '../../common/Request.js'
export default {
  props:{
    show:Number,
  },
  data(){
    return {
      localShow:0,
      containerShow:0,
      dayList:[],
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    actSignin(){
      let today = this.dayList.filter(item => item.is_today == 1)[0];
      if(!today || today.status == 1){
        return;
      }
      let p = {
        id:today.Activity_id
      }
      this.showLoading()
      Request.post('/activity/sign_in',p).then(res => {
        if(res.data.code != 0){
          this.popMsg(res.data.msg)
        }else{
          this.popMsg(res.data.msg)
          this.updateLocalStatus();
        }
      }).finally(() => {
        this.closeLoading()
      })
    },
    updateLocalStatus(){
      Request.post('/activity/get_status_sign').then(res => {
        Tool.setStatusSign(res.data.data);
        // this.dayList = this.statusListHandle(res.data.data);
        this.dayList = res.data.data;
      })
    },
    // statusListHandle(list){
    //   let todayIndex = 0;
    //   list.forEach((item,i) => {
    //     if(item.is_today == 1){
    //       todayIndex = i;
    //     }
    //   })
    //   list.forEach((item,i) => {
    //     if(i > todayIndex){
    //       item.isAfterToday = 1;
    //     }
    //   })
    //   let orderList = [1,4,2,5,3,6,7];
    //   return orderList.map(num => {
    //     return list.filter(lItem => lItem.day == num)[0];
    //   })
    // },
    getData(){
      let dataList = Tool.getStatusSign();
      if(dataList){
        // this.dayList = this.statusListHandle(dataList);
        this.dayList = dataList;
        return;
      }
      Request.post('/activity/get_status_sign').then(res => {
        Tool.setStatusSign(res.data.data);
        // this.dayList = this.statusListHandle(res.data.data);
        this.dayList = res.data.data;
      })
    },
    closeClick(){
      this.containerShow = 0;
      this.localShow = 0
    }
  },
  watch:{
    show(n){
      if(n){
        this.localShow = n;
        setTimeout(() => {
          this.containerShow = n;
        });
        this.getData();
      }
    }
  },
  computed:{
    todayIsSign(){
      let today = this.dayList.filter(item => item.is_today == 1)[0];
      if(today && today.status == 1){
        return 1
      }
      return 0
    }
  },
}
</script>
<style lang="stylus" scoped>
.qiandao-wrapper
  width 100%
  height 100%
  font-size .1rem
  position absolute
  align-items center
  background-color rgba(0,0,0,.25)
  z-index 3
  top 0
  left 0
  display none
  &.show
    display flex
  .qd-container
    margin 0 auto
    position relative
    &.show
      transform scale(1)
    .qd-background
      width 4.73rem
      display block
    .qiandao-title
      width 3.1rem
      position absolute
      top 0
      left 50%
      transform translateX(-50%)
    .qiandao-close
      position absolute
      right .51rem
      top .21rem
      width .3rem
    .qiandao-dec
      left 0
      bottom 0
      width 1.70rem
      position absolute
    .qd-content-wrap
      width 3.2rem
      height 2.37rem
      position absolute
      padding-top .02rem
      top .65rem
      left .92rem
      .qd-item-wrap
        width 100%
        .sign-status
          width 89%
          text-align center
          line-height .18rem
          font-size .12rem
          background-color rgba(0,0,0,0.4)
          color #f6e7d5
          position absolute
          bottom .05rem
          left .03rem
        .day-number
          width 100%
          height .18rem
          line-height .18rem
          font-size .12rem
          text-align center
          border-radius .1rem
          background-color #D32D3D
          color #F9E4E2
          position absolute
          bottom -.19rem
          left 50%
          transform translateX(-50%)
        .qd-item-c
          width 2.25rem
          height 1.38rem
          padding-left .09rem
          flex-wrap wrap
      .qd-item-6
        width .6rem
        height .51rem
        position relative
        margin-right .08rem
        margin-bottom .2rem
        background-size 100% 100%
        border-radius .04rem
        &.status0
          background-image url(../../assets/qiandao/qiandao-before-today.png)
        &.status1
          background-image url(../../assets/qiandao/qiandao-today.png)
        &.status2
          background-image url(../../assets/qiandao/qiandao-after-day.png)
        &.qd-item-7
          width .97rem
          height 1.2rem
          margin-right 0
          right .12rem
          position absolute
          background-image url(../../assets/qiandao/qdi-7-qiandao.png)
          background-size 100% 100%
          .sign-status
            width 94%
          .last-day-cover
            width 100%
            height 100%
            border-radius .04rem
            position absolute
            top 0
            left 0
            background-color rgba(0,0,0,.4)
      .des
        color #A26E1B
        font-size .12rem
        width 100%
        text-align center
        margin-top .12rem
      .get-jiangli-btn
        margin .1rem auto 0 auto
        width 1.08rem
        height .33rem
        background-image url(../../assets/qiandao/liji-qiandao-btn.png)
        background-size 100% 100%
        &.today-signed
          background-image url(../../assets/qiandao/liji-qiandao-disable.png)
</style>