import Tool from './Tool.js'
import Request from './Request.js'

export default {
  config(){
    let echo = {};
    let api_token = Tool.getApiToken();

    let wsUrl = Request.getWsHost();
        
    if(api_token){
      const authDic = {
        'Authorization': 'Bearer ' + api_token,
      }
      echo = new window.Echo({
        namespace: 'app.events',
        broadcaster: 'socket.io',
        host:wsUrl,
          auth: {
            headers: authDic,
          },
      });
    }
    return echo;
  },
  configSocket(app){
    if(!app.config.globalProperties.$socket){
      app.config.globalProperties.$socket = this.config();
    }
  }
}