<template>
  <PopWidget width="5.5rem" :isShow="isShowPopWidget" :isFull="1" style="z-index:2"
    @closed="popCloseHandle">
    <template v-slot:title>
      <div class="act-tab flex">
        <div class="activity-hot" :class="{select:tabSelect == 0}"
          @click="tabClick(0)">
        </div>
        <div class="act-notice" :class="{select:tabSelect == 1}"
          @click="tabClick(1)">
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="main-content flex" v-show="tabSelect == 0">
        <div class="activity-content" @click="actContentClick">
          <div class="ac-c-inner" v-show="htmlContent" v-html="htmlContent">
          </div>
          <!-- 亏损救援 -->
          <div class="kuisun-jiuyuan" 
            @click="kuisnClick"
            :class="{'ease-btn-effe':currentActivity.status == 2}"
            v-show="['online','reimburse'].includes(currentActivity.type)">
            <!--status 1未达成  2正常可用  3已领取 4审核中 -->
            {{ getKuisunBtnText() }}
          </div>
          <SignItemWrap :activity="currentActivity"
            v-show="[
            'attendance',
            'bet',
            'bet_number',
            'recharge'
          ].includes(currentActivity.type)" />
        
          <div class="get-bonus-wrap flex" 
            v-show="isShowBonusBtn && currentActivity.type == 'other' && 1*currentActivity.money > 0">
            <div class="act-amount">
              {{currentActivity.money}}
            </div>
            <div class="receive-btn" @click.stop="getBonusClick"></div>
          </div>

        </div>
        <div class="menu-wrap">
          <div class="inner-back"></div>
          <div class="menu-wrap-scroll flex">
            <div :key="menu.id" class="menu-btn"
              v-for="(menu,i) of menuList" :data-id="menu.id"
              @click="menuClick(i)" 
              :class="{select:menuSelectIndex==i}">
              <div class="menu-ico-poi" 
                :class="{
                  recom:menuSelectIndex!=i&&menu.status==1,
                  hot:menuSelectIndex!=i&&menu.status==2
                }">
              </div>
              <div class="normal-bg"></div>
              <div class="select-bg"></div>
              <div class="menu-text">
                {{ menu.activity_name }}
              </div>
              <div class="pop-sign" 
                v-show="1*menu.money > 0 || menu.rule && menu.rule.map(r => 1*r.status).includes(2)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content flex" v-show="tabSelect == 1">
        <Notice :mList="noticeList" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
import PopWidget from '../common/PopWidget.vue'
import SignItemWrap from './SignItemWrap.vue'
import Notice from './Notice.vue'
import Tool from '@/common/Tool'

export default{
  props:{
    show:Number,
  },
  data(){
    return{
      tabSelect:0,
      isShowPopWidget:0,
      menuSelectIndex:0,
      menuList:[],
      noticeList:[],
      isShowBonusBtn:1,
    }
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    popCloseHandle(){
      Request.post('/user/apply_activity').then(res => {
        if(res.data.code == 0){
          Tool.setActivityApply(res.data.data.apply_activity)
        }
      })
    },
    actContentClick(){
      const currMenu = this.menuList[this.menuSelectIndex];
      if(currMenu.link){
        if(currMenu.link.startsWith("http")){
          window.open(currMenu.link)
        }else if(currMenu.link.startsWith("xmqipai")){
          const code = currMenu.link.split('xmqipai:')[1];
          this.$emit('linkChange',code);
        }
      }
    },
    getKuisunBtnText(){
      // 1未达成  2正常可用  3已领取 4审核中
      let status = 1;
      if(this.currentActivity && this.currentActivity.rule && this.currentActivity.rule.length){
        status = this.currentActivity.rule[0].status;
      }
      if(status == 1){
        return '未达成'
      }else if(status == 2){
        return '领取'
      }else if(status == 3){
        return '已领取'
      }else if(status == 4){
        return '审核中'
      }
    },
    kuisnClick(){
      let rule_id = '';
      if(this.currentActivity.rule && this.currentActivity.rule[0]){
        rule_id = this.currentActivity.rule[0].id;
      }
      if(!rule_id) return;

      let p = {
        id:this.currentActivity.id,
        rule_id
      }
      Request.post('/activity/apply',p).then(res => {
        if(res.data.code == 0){
          this.popMsg('领取成功')
        }else if(res.data.msg){
          this.popMsg(res.data.msg)
        }
      }).catch(error => {
        if(error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg)
        }else{
          this.popMsg('出错，请联系客服')
        }
      })
    },
    menuClick(i){
      this.menuSelectIndex = i;
    },
    getMenuList(isUpdate){
      let actList = Tool.getActivitiesList()
      if(process.env.NODE_ENV == 'development' && actList && !isUpdate){
        this.menuList = actList;
        return;
      }
      this.menuList = [];
      this.showLoading()
      Request.post('/activity/index').then(res => {
        this.menuList = res.data.data;
        let isUnread = 0;
        for(let i = 0; i < this.menuList.length;i++){
          let menu = this.menuList[i];
          let found = -1;
          if(menu.rule && menu.rule.length){
            for(let j = 0; j < menu.rule.length;j++){
              if(menu.rule[j].status == 2){
                found = j;
                break;
              }
            }
          }
          if(found > -1){
            isUnread = 1;
            break;
          }
        }
        if(isUnread){
          Tool.setActivityApply(1)
        }
        Tool.setActivitiesList(res.data.data);
        this.closeLoading()
      }).catch(() => {
        this.closeLoading()
      })
    },
    tabClick(index){
      this.tabSelect = index;
      if(index == 1){
        this.getPubliNotice()
      }
    },
    getPubliNotice(){
      if(this.noticeList.length) return;
      Request.get('/pub/notice').then(res => {
        if(res.data && res.data.data && res.data.data.data){
          this.noticeList = res.data.data.data;
        }
      })
    },
    getBonusClick(){
      let actId = this.currentActivity.id;
      this.showLoading()
      Request.get(`/activity/apply_other?id=${actId}`).then(res => {
        if(res.data && res.data.code == 0){
          this.isShowBonusBtn = 0;
          let msg = '领取成功';
          if(res.data.data && res.data.data.info){
            msg = res.data.data.info;
          }
          this.getMenuList('update');
          this.$emit('bonusGotted',msg)
        }
        console.log(res.data)
        this.closeLoading()
      }).catch((eRes) => {
        this.getMenuList('update');
        let msg = '领取失败';
        if(eRes.response && eRes.response.data && eRes.response.data.msg){
          msg = eRes.response.data.msg;
        }
        this.popMsg(msg);
        this.closeLoading()
      });
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.getMenuList();
    }
  },
  computed:{
    currentActivity(){
      let act = this.menuList[this.menuSelectIndex];
      if(!act) act = {};
      return act;
    },
    htmlContent(){
      let act = this.menuList[this.menuSelectIndex];
      if(!act) return ''
      /*attendance累计签到,recharge充值，reimburse亏损救援
        online在线活动*/
      if(!['attendance','recharge','reg','bet','bet_number'].includes(act.type)){
        return act.mb_content;
      }
      return ''
    }
  },
  components:{
    PopWidget,
    SignItemWrap,
    Notice,
  }
}
</script>
<style lang="stylus">
.activity-content
  img
    display block
    width 100%
</style>
<style scoped lang="stylus">
.act-tab
  width 100%
  justify-content center
  & > div
    width 1.4rem
    height .3rem
    background-size 100% 100%
  .activity-hot
    background-image url(../../assets/activity/activity-hot.png)
    &.select
      background-image url(../../assets/activity/activity-hot1.png)
  .act-notice
    background-image url(../../assets/activity/activity-notice.png)
    &.select
      background-image url(../../assets/activity/activity-notice1.png)
.main-content
  justify-content space-between
  .activity-content
    width 3.99rem
    height 2.28rem
    overflow auto
    position relative
    .kuisun-jiuyuan
      background white
      width auto
      height .25rem
      padding 0 .2rem
      border-radius .05rem
      line-height .25rem
      position absolute
      right .1rem
      bottom .1rem
    .ac-c-inner
      width 100%
      padding .06rem
    .get-bonus-wrap
      width 1.9rem
      height .43rem
      line-height .43rem
      text-align center
      position absolute
      right .14rem
      bottom .06rem
      .act-amount
        width 50%
        background-color gray
        color white
        border-radius .1rem 0 0 .1rem
      .receive-btn
        width 50%
        background-size 100% 100%
        background-image url(../../assets/activity/can-received.png)
        border-radius 0 .1rem .1rem 0
  .menu-wrap
    width 1.24rem
    height 2.28rem
    position relative
    z-index 1
    &::-webkit-scrollbar
      width 0
    .inner-back
      width 1.23rem
      height 100%
      background linear-gradient(#EACD9B, #D8A96B)
      box-shadow 0 0 10px 0 rgba(0,0,0,.6)
      padding-bottom .3rem
    .menu-wrap-scroll
      width 1.33rem
      height 100%
      position absolute
      overflow auto
      top 0
      right 0
      flex-direction column
      align-items flex-end
      padding .12rem 0
      .menu-btn
        width 1.25rem
        height .44rem
        flex-shrink 0
        margin 0 .04rem .07rem 0
        position relative
        .pop-sign
          width .12rem
          height .12rem
          border-radius 50%
          background-image url(../../assets/activity/active-tip-poi.png)
          background-size 100% 100%
          position absolute
          top -.04rem
          right 0
          border 1px solid #fff
        .menu-ico-poi
          width .24rem
          height .35rem
          background-size 100% 100%
          top 0
          left .16rem
          z-index 2
          position absolute
          &.hot
            background-image url(../../assets/activity/activity-m-hot.png)
          &.recom
            background-image url(../../assets/activity/activity-m-recom.png)
        & > div
          position absolute
          right 0
          top 0
        .select-bg
          display none
        &.select
          .normal-bg
            display none
          .select-bg
            display block
        .normal-bg
          width 1.11rem
          height 100%
          background-image url(../../assets/geren/vip-menu-btn.png)
          background-repeat no-repeat
          background-size 100% 100%
        .select-bg
          width 100%
          height 100%
          background-image url(../../assets/activity/act-menu-se-btn.png)
          background-repeat no-repeat
          background-size 100% 100%
        .menu-text
          width 1.11rem
          height 100%
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          font-size .14rem
          font-weight bold
          padding-left .05rem
          line-height .4rem
          color #842404
          z-index 1

</style>