<template>
  <div class="game-list-wrapper" 
    :class="[browserBrand,
    `${isHp?'horizontal':''}`,
    `${invoke=='favorite'?'favorite':''}`,
    ]">
    <div class="ann-wrapper" v-if="invoke=='home'">
      <div class="a-b-wrapper">
        <img class="ann-w-ico" src="../assets/home/game/announ-ico.png" alt="">
        <div class="ann-content">
          <Marquee />
        </div>
      </div>
      <div class="official-url">
        <div class="url">
          <img class="sepretor" src="../assets/home/game/ann-sepretor.png" alt="">
          <input class="home-url" ref="homeUrlInput"
            v-model="agentYeildInfo.url" readonly />
        </div>
        <img class="ann-copy-btn" @click="copyUrl"
          src="../assets/home/game/ann-copy-btn.png" alt="">
      </div>
    </div>
    <div v-if="[1,3].includes(invoke*1)" 
      class="dzyy-description" :class="{hidden:!mtitle}">
      {{mtitle}}，总共{{gameList.length}}款小游戏
    </div>
    <div class="g-list-scroller" ref="gameListScroller"
      :class="{
        'visibility-hiden':showAnnounce,
        favorite:invoke=='favorite',
        dzyy:[1,3].includes(invoke*1)}"
        >
      <div class="g-list-container" 
        :class="{ 'single-page': gameList.length < 5 || [0,3].includes(gameid),
        qipai:[0,3].includes(gameid) }">
        <div class="g-list-item" v-for="(game,gIndex) of gameList" 
          :data-type="gameid" :data-id="game.id"
          :class="{
            favorite:invoke=='favorite',
            'ainmate-ico':game.mobile_fimg_file,
            'single-page': gameList.length < 5 || [0,3].includes(gameid), 
            qipai:[0,3].includes(gameid),
            'big-margin-right':[0,2].includes(gameid),
            dzyy: game.type == 1,
            sport:game.local_type == 4,
          }"
          @click="goGameClick(game)"
          @touchstart="touchStartHandle(game.id)">
          <div class="selector" v-if="isEditing" 
            :class="{selected:gameListSelectDic[game.id]}"
            @click.stop="selectClick(game.id)">
          </div>
          <div class="game-tap-cover"></div>
          <!-- 游戏动画图标 -->
          <div class="ani-wrapper p-relative" 
            v-if="game.mobile_fimg_file">
            <div :id="`animate-ico-${game.id}`" class="animation-ico">
            </div>
            <div class="pre-img-an" v-show="!game.isLoaded" :data-t="game.isLoaded">
              <div class="none-ico">
                <div>
                  {{game.name}}
                </div>
              </div>
            </div>
          </div>
          <!-- 静态图标 -->
          <div class="item-ico-wrap p-relative" v-else-if="!game.mobile_fimg_file && getGameImg(game)">
            <div class="pre-img-an" v-show="!gameImageLoadDict[game.id]" :data-l="game.id +':'+ gameImageLoadDict[game.id]">
              <div class="none-ico">
                <div>
                  {{game.name}}
                </div>
              </div>
            </div>
            <img :class="{'loaded-show':gameImageLoadDict[game.id]}"
              @load="gameImgLoaded(gIndex,game.id)" class="g-item-ico" :src="getGameImg(game)" />
          </div>
          <!-- 无动画无静态图标，默认图标 -->
          <div v-else class="none-ico">
            <div>
              {{game.name}}
            </div>
          </div>
        </div>
        <div class="pager-loading p-relative" :class="{show:pageDataLoading}">
          loading...
        </div>

      </div>
    </div>
    <Regist :invokSource="1" :isShow="isShowBindMobile" />
  </div>
</template>

<script>
import { mapMutations,mapGetters } from 'vuex'
import JSZip from 'jszip'
import lottie from 'lottie-web'
import Request from '../common/Request.js'
import Tool from '../common/Tool.js'
import PounchDbHandle from '../common/PounchDbHandle.js'
import Regist from './Regist.vue'
import Marquee from './duobao/Marquee.vue'
import Loading from './common/Loading.vue'

export default {
  props: {
    gameid: String | Number,
    platformCode: String,
    mtitle: String,
    invoke:String | Number, // 1 电子游艺 2收藏夹
    isSearch:String,
    isTaskGame:String,
    isEditing:Number,
    removeLocalFavorite:Number,
    isScrollerScroll:Number,
  },
  data() {
    return {
      gameImageLoadDict:{},
      userInfo: {},
      gameList: [],
      gameListSource:[],
      isShowBindMobile: 0,
      favoriteTime: 0,
      agentYeildInfo:{},
      gameListSelectDic:{},
      animationNameList:{},
      startPoi:0,
      winTouchPoi:0,
      endPoi:0,
      invalidFavorite:false,
      touchstartTime:0,
      showAnnounce:0,
      browserBrand:'',
      isHp:0,
      mouseMoving:0,
      mouseDownX:0,
      dataPagerObj:{
        current_page:1,
        per_page:20,
        total:0,
        pageMax:0
      },
      pageDataLoading:0,
      scrollEndHanding:0,
      endHandingTimeout:0,
    }
  },
  mounted() {
    this.browserBrand = Tool.getMobileBrand();
    this.getUserInfoData();
    this.getHostUrl();
    if(this.invoke == 'favorite'){
      this.getFavoriteList();
    }
    this.mouseEventHandle();
    window.addEventListener('touchstart',(event) => {
      this.startPoi = 0;
      this.winTouchPoi = 0;
      this.endPoi = 0;

      this.winTouchmove(event)
    });
    window.addEventListener('touchmove',(event) => {
      clearTimeout(this.favoriteTime);
      this.invalidFavorite = true;
      this.winTouchmove(event)
    });
    window.addEventListener('touchend',() => {
      clearTimeout(this.favoriteTime);
      this.invalidFavorite = false;
      this.winTouchend()
    });
    window.addEventListener('orientationchange',() => {
      this.hvLayout();
    });
    window.addEventListener('resize',() => {
      this.hvLayout();
    });
    this.hvLayout();
  },
  methods: {
    ...mapMutations(['popMsg','showLoading','closeLoading','alert']),
    getUserInfoData(){
      this.userInfo = Tool.getLocalUserinfo();
    },
    gameImgLoaded(index,id){
      this.gameImageLoadDict[id] = 1;
      // if(!this.gameList[index]) return
      // this.gameList[index].isLoaded = 1;
    },
    mouseEventHandle(){
      if(Tool.isComputer()){
        window.addEventListener('mousedown',this.winMouseDownHandle)
        window.addEventListener('mouseup',this.winMouseupHandle)
        window.addEventListener('mousemove',this.winMousemove)
      }else{
        window.removeEventListener('mousedown',this.winMouseDownHandle)
        window.removeEventListener('mouseup',this.winMouseupHandle)
        window.removeEventListener('mousemove',this.winMousemove)
      }
    },
    winMouseDownHandle(event){
      this.mouseDownX = event.clientX;
    },
    winMousemove(event){
      if(this.mouseDownX){
      this.mouseMoving = 1;
      this.invalidFavorite = true;
      let p = {
        touches:[{
          clientX:event.clientX,
          clientY:event.clientY
        }]
      }
      this.winTouchmove(p);
    }
    },
    winMouseupHandle(){
      clearTimeout(this.favoriteTime);
      setTimeout(() => {
        this.mouseMoving = 0;
      },300)
      this.mouseDownX = 0;
      this.invalidFavorite = false;
      this.winTouchend()
    },
    hvLayout(){
      this.isHp = innerWidth > innerHeight;
    },
    winTouchmove(event){
      if(!event.touches[0]) return;
      if(this.isShowLoading) return;

      let poiKey = innerWidth > innerHeight ? 'clientX' : 'clientY';
      if(!this.startPoi){
        this.touchstartTime = new Date().getTime();
        this.startPoi = event.touches[0][poiKey];
      }
      this.endPoi = event.touches[0][poiKey];

      if(this.winTouchPoi && this.$refs.gameListScroller){
        let deltaFrame = this.endPoi - this.winTouchPoi;
        this.$refs.gameListScroller.scrollLeft += (-deltaFrame);
        this.scrollIsEnd();
      }
      this.winTouchPoi = event.touches[0][poiKey];
    },
    winTouchend(){
      let now = new Date().getTime();
      let subTime = now - this.touchstartTime;
      if(subTime < 400){
        let dirPoi = this.endPoi - this.startPoi;
        if(Math.abs(dirPoi) > 80){
          if(dirPoi > 0){
            this.scrollToContainer(1)
          }else if(dirPoi < 0){
            this.scrollToContainer(-1)
          }
        }
      }
      this.startPoi = 0;
      this.winTouchPoi = 0;
      this.endPoi = 0;
    },
    scrollToContainer(dir){
      let _this = this,
          target = Tool.remToPx(5),
          unit = 20,
          flag = 0;
      const scrollFrame = () => {
        flag += unit;
        if(_this.$refs.gameListScroller){
          let source = _this.$refs.gameListScroller.scrollLeft,
            unitTar = 0;
          if(dir < 0){
            unitTar = source + unit
          }else if(dir > 0){
            unitTar = source - unit
          }
          _this.$refs.gameListScroller.scrollLeft = unitTar;
          _this.scrollIsEnd();
        }
        if(flag < target){
          requestAnimationFrame(scrollFrame)
        }
      };
      scrollFrame();
    },
    scrollIsEnd(){
      let {scrollLeft,scrollWidth,clientWidth} = this.$refs.gameListScroller;
      let totalS = scrollLeft + clientWidth;

      if(scrollWidth - totalS < 1 && !this.scrollEndHanding && this.dataPagerObj.current_page < this.dataPagerObj.pageMax){
        this.scrollEndHanding = 1;

        clearTimeout(this.endHandingTimeout);
        this.endHandingTimeout = setTimeout(() => {
          this.scrollEndHanding = 0;
        },2000);
        
        this.dataPagerObj.current_page++;

        if(this.invoke == 'home'){
          if([-1,5,0,2,4].includes(this.gameid)){
            this.getHomeGameListByPager();
          }
        }else{
          this.getDzyyListByPager(this.platformCode);
        }
        
      }
    },
    selectClick(gameId){
      this.gameListSelectDic[gameId] = this.gameListSelectDic[gameId]?0:1;
      const list = Object.keys(this.gameListSelectDic).filter(key => {
        return this.gameListSelectDic[key];
      });
      this.$emit('favSelectChange',list);
    },
    touchStartHandle(id){
      clearTimeout(this.favoriteTime);
      if(this.invoke == 'home'){
        if(![-1,5].includes(this.gameid))return;
      }else{
        if('favorite' == this.invoke) return;
      }
      if(this.invalidFavorite) return;
      this.favoriteTime = setTimeout(() => {
        if(this.userInfo && this.userInfo.is_tourist){
          this.isShowBindMobile = Math.random();
          this.popMsg({
            msg:'请先绑定手机',
            time:1200
          });
          return; 
        }
        if(this.invalidFavorite) return;
        let p = {game_id:id}
        Request.post('/index/collection_add',p).then(res => {
          if(res.data.code == 0){
            this.popMsg({
              msg:'收藏成功',
              time:1000
            })
          }
        })
      },1000);
    },
    copyUrl(){
      const input = this.$refs.homeUrlInput
      Tool.copy(input)
      this.popMsg('已复制!')
    },
    getHostUrl(){
      let cach = Tool.getAgentYieldInfo();
      if(cach){
        this.agentYeildInfo = cach;
        return;
      }
      Request.post('/agent/yield_info').then(res => {
        if(!res.data || !res.data.data) return;
        
        this.agentYeildInfo = res.data.data;
        Tool.setAgentYieldInfo(res.data.data);
      })
    },
    searchGameList(searchTxt){
      this.gameList = this.gameListSource
        .filter(game => game.name.indexOf(searchTxt) > -1);
      setTimeout(() => {
        this.showGameAnimateIco();
      });
    },
    //跳转到游戏
    goGameClick(game) {
      if(this.mouseMoving) return;

      if(!(game.type == 1 || [0,2,3].includes(this.gameid))){

        if (!this.userInfo || !this.userInfo.mobile) {
          this.isShowBindMobile = Math.random();
          this.popMsg({
            msg:'请先绑定手机',
            time:1200
          });
          return;
        }
        
      }
      
      if(this.isEditing){ //收藏夹编辑模式
        this.selectClick(game.id)
        return;
      }
      
      if(game.type == 1){
        this.$router.push(`/dzyy?id=${game.id}&l_type=${game.type}`)
        return;
      }else if([3].includes(this.gameid)){
        this.$router.push(`/dzyy?id=${game.id}&l_type=${this.gameid}`)
        return;
      }
      
      let param = `platform_played_id=${game.code}&platform_type=${game.plat_code}`
      this.showLoading();
      Request.get(`/platform/goGame?${param}`).then(res => {
        this.closeLoading();
        if (res.data.code == 0) {
          if(res.data.data){
            let {title,content,status} = res.data.data;
            
            if(status == 1){
              let msg = '游戏正在维护,请稍后再试 !';
              if(title){
                msg = title;
              }
              if(content){
                if(title){
                  msg += ('<br/>' + content);
                }else{
                  msg = content;
                }
              }
              this.alert(msg);
              this.showAnnounce = 1;
            }else if (res.data.data.url) {
              Tool.setGameUrl(res.data.data.url)
              this.$router.push({
                name:'play',
                query: {
                  platform_type:game.plat_code,
                  rotate:game.screen_rotate
                }
              });
            }
          }
        }
      }).catch(error => {
        this.closeLoading();
        let msg = '出错了！'
        if (error.response && error.response.data) {
          if (!this.userInfo || !this.userInfo.mobile) {
            this.isShowBindMobile = Math.random();
            this.popMsg({
              msg:'请先绑定手机',
              time:1200
            });
            return;
          }
          if(error.response.data.code == 1000006){
            Request.userOutted(this);
            msg = '';
          }else if(error.response.data.msg){
            msg = error.response.data.msg;
          }
        }
        if(msg){
          this.popMsg(msg);
        }
      })
    },
    getGameImg(game) {
      let url = '';
      if (game.pc_img) {
        url = game.pc_img;
      }else if (game.list_img) {
        url = game.list_img;
      }else if (game.label_img){
        url = game.label_img;
      }
      if(url){
        return Tool.urlProtocalHandle(url);
      }
    },

    getPlatformList(gameType){
      if([-1,5,0,2,4].includes(gameType)) return;
      
      let url = `/index/platform?type=${gameType}`;
      const cacheKey = `local-platform-game-list-${gameType}`;
      const localGameList = Tool.getGameListLocal(cacheKey);
      
      if(this.$refs.gameListScroller){
        this.$refs.gameListScroller.scrollLeft = 0;
      }
      if(localGameList && localGameList.length){
        this.gameList = localGameList;
        setTimeout(() => {
          this.showGameAnimateIco();
        })
        this.getPlatformListUpdateCache(gameType);
        return;
      }
      this.gameList = [];
      this.showLoading();
      Request.post(url).then(res => {
        this.closeLoading();
        if (res.data && res.data.data) {
          //假数据
          (() => {
            let f = res.data.data[0];
            let data = JSON.parse(JSON.stringify(f));
            data.id = 989898;
            data.alia_name = '测试名';
            res.data.data.push(data);
          })
          let pagerData = res.data.data;
          pagerData.forEach(game => game.isLoaded = 0);
          this.gameList = pagerData;
          Tool.setGameListLocal(cacheKey,pagerData);
          Tool.updateReloadIsUpdate(cacheKey);
          if (this.gameList.length) {
            setTimeout(() => {
              this.showGameAnimateIco();
            })
          }
        }
      }).catch(error =>  {
        this.closeLoading();
        if(error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      });
    },
    // 调用接口更新缓存与界面数据
    getPlatformListUpdateCache(gameType){
      const cacheKey = `local-platform-game-list-${gameType}`;
      let cachKeyDicts = Tool.getReloadIsUpdate();
      if(cacheKey in cachKeyDicts) return;
      
      let url = `/index/platform?type=${gameType}`;
      Request.post(url).then(res => {
        if (res.data && res.data.data) {
          let pagerData = res.data.data;
          pagerData.forEach(game => game.isLoaded = 0);
          Tool.setGameListLocal(cacheKey,pagerData);
          Tool.updateReloadIsUpdate(cacheKey);
        }
      }).catch(error =>  {
        if(error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      });
    },

    getGameListUrl(gameType){
      let url = '',page = this.dataPagerObj.current_page;
      if (gameType == -1) {
        url = `/index/hot?page=${page}`;
      } else if (gameType == 5) {//捕鱼游戏链接
        url = `/index/hunter?page=${page}`;
      } else if([0,2,4].includes(gameType)){
        url = `/index/games?type=${gameType}&page=${page}`
      }
      return url;
    },
    getGameListCach(gameType){
      let flag = false;
      const cacheKey = `local-game-list-${gameType}`;
      const pagerLocalData = Tool.getGameListLocal(cacheKey);
      if(pagerLocalData){
        this.setPagerDataObj(pagerLocalData);
        let localGameList = pagerLocalData.data;
        if(localGameList && localGameList.length){
          this.gameList = localGameList;
          setTimeout(() => {
            this.showGameAnimateIco();
          })
          flag = true;
        }
      }
      return flag;
    },
    getGameListInvokApi(url,cacheKey,callback){
      Request.post(url).then(res => {
        if (res.data && res.data.data) {
          //假数据
          (() => {
            let f = res.data.data[0];
            let data = JSON.parse(JSON.stringify(f));
            data.id = 989898;
            data.alia_name = '测试名';
            res.data.data.push(data);
          })
          let pagerData = res.data.data;
          this.setPagerDataObj(pagerData);
          pagerData.data.forEach(game => game.isLoaded = 0);
          Tool.setGameListLocal(cacheKey,pagerData);
          Tool.updateReloadIsUpdate(cacheKey);
          if(callback) callback(pagerData.data);
        }else{
          if(callback) callback();
        }
      }).catch(error =>  {
        if(callback) callback();
        if(error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      });
    },
    getGameListApi(gameType){
      const cacheKey = `local-game-list-${gameType}`;
      let url = this.getGameListUrl(gameType);
      if(!url) return;
      this.gameList = [];
      this.showLoading();
      this.getGameListInvokApi(url,cacheKey,dataList => {
        this.closeLoading();
        if(dataList){
          this.gameList = dataList;
          if (this.gameList.length) {
            setTimeout(() => {
              this.showGameAnimateIco();
            })
          }
        }
      });
    },
    getGameList(gameType) {
      if(this.$refs.gameListScroller){
        this.$refs.gameListScroller.scrollLeft = 0;
      }
      let isCacheData = this.getGameListCach(gameType);
      if(!isCacheData){
        this.getGameListApi(gameType);
      }else{
        this.getGameListUpdataCach(gameType);
      }
    },
    getGameListUpdataCach(gameType){
      const cacheKey = `local-game-list-${gameType}`;
      let cachKeyDicts = Tool.getReloadIsUpdate();
      if(!(cacheKey in cachKeyDicts)){
        let url = this.getGameListUrl(gameType);
        if(!url) return;
        this.getGameListInvokApi(url,cacheKey)
      }
    },


    showGameAnimateIco(gameList) {
      let localGameList = gameList || this.gameList;
      const _this = this;
      let index = 0;

      localGameList.forEach(game => {
        const name = `ico-animate-${game.id}`
        lottie.destroy(name);
        delete this.animationNameList[name]
      })

      let iteration = () => {
        if (index > localGameList.length - 1) return;
        
        if(localGameList[index]){
          // localGameList[index].isLoaded = 0;
          lottieAnimate(localGameList[index], () => {
            if(localGameList[index]){
              let idFind = localGameList[index].id;
              if(this.gameList.filter(item => item.id == idFind)[0]){
                this.gameList.filter(item => item.id == idFind)[0].isLoaded = 1;
              }
            }
            index++
            iteration();
          })
        }else{
          index++
          iteration();
        }
      }
      iteration();

      async function fetchZip(zipUrl) {
        async function getBlocb(){

          let localBlob = await PounchDbHandle.getAnimateBlob(zipUrl)
          if(localBlob){
            return localBlob;
          }
          const response = await fetch(zipUrl);
          const blob = await response.blob();
          await PounchDbHandle.addAnimateBlob(zipUrl,blob)

          return blob;
        }
        
        const blob = await getBlocb();
        const zip = new JSZip();
        await zip.loadAsync(blob);
        return zip;
      }

      async function getImageFiles(zip) {
        const imageFiles = [];
        zip.forEach((relativePath, zipEntry) => {
          if (zipEntry.name.endsWith('.png')) {
            imageFiles.push(zipEntry);
          }
        });
        return imageFiles;
      }

      async function getSortedImageFiles(imageFiles, data) {
        const oldData = JSON.parse(data);
        return oldData.assets
          .filter(asset => asset.id.startsWith('image_'))
          .map(asset => {
            const zipEntry = imageFiles.find(entry => entry.name.endsWith(asset.p));
            if (!zipEntry) {
              console.error('Zip entry not found for asset:', asset.p);
              return null;
            }
            return zipEntry;
          })
          .filter(entry => entry !== null);
      }

      async function updateAnimationAssets(animation, loadImageData) {
        animation.assets.forEach((obj, index) => {
          if (obj.id.startsWith('image_')) {
            animation.assets[index] = {
              id: obj.id,
              w: obj.w,
              h: obj.h,
              u: "data:image/png",
              p: ';base64,' + loadImageData[index]
            };
          }
        });
      }

      async function lottieAnimate(game, callback) {
        let container_id = `animate-ico-${game.id}`,
          zipUrl = game.img || game.mobile_fimg_file;
        if(!zipUrl){
          callback();
          return;
        }
        
        try {
          zipUrl = zipUrl.replace('http:','https:');
          const zip = await fetchZip(zipUrl);
          const imageFiles = await getImageFiles(zip);
          const dataFile = zip.file('data.json');
          if(!dataFile)return;
          const data = await dataFile.async('text');
          const animation = JSON.parse(data);
          const sortedImageFiles = await getSortedImageFiles(imageFiles, data);
          const loadImageData = await Promise.all(sortedImageFiles.map(file => file.async('base64')));
          await updateAnimationAssets(animation, loadImageData);
          
          const animationName = `ico-animate-${game.id}`
          _this.animationNameList[animationName] = 1;
          const conElement = document.getElementById(container_id);
          if(conElement){
            conElement.innerHTML = '';
            lottie.loadAnimation({
              container: conElement,
              renderer: 'svg',
              loop: true,
              autoplay: true,
              name:animationName,
              animationData: animation
            });
          }
          callback()
        } catch (error) {
          callback()
          console.log(error);
        }
      }

    },
    getFavoriteList(){
      let url = '/index/collections'
      this.gameList = [];
      Request.post(url).then(res => {
        this.gameListSource = res.data.data;
        this.gameList = this.gameListSource.map(item => item);
        if (this.gameList.length) {
          setTimeout(() => {
            this.showGameAnimateIco();
          })
        }
      })
    },
    dataPageDataHandle(dataList){
      if(!dataList || !dataList.length)return;

      dataList.forEach(game => {
        game.isLoaded = 0;
      });

      this.gameListSource = this.gameListSource.concat(dataList);
      this.gameList = this.gameList.concat(dataList);

      if (dataList.length) {
        setTimeout(() => {
          this.showGameAnimateIco(dataList);
        })
      }

    },
    getHomeGameListByPagerUpdateCach(url,cacheKey){
      let cachKeyDicts = Tool.getReloadIsUpdate();
      if(cacheKey in cachKeyDicts) return;

      Request.post(url).then(res => {
        if (res.data && res.data.data) {
          let pagerData = res.data.data;
          this.setPagerDataObj(pagerData);
          pagerData.data.forEach(game => game.isLoaded = 0);
          Tool.setGameListLocal(cacheKey,pagerData);
          Tool.updateReloadIsUpdate(cacheKey);
        }
      }).catch(error =>  {
        if(error.response && error.response.data && error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      });
    },
    getHomeGameListByPager(){
      let gameType = this.gameid;
      let page = this.dataPagerObj.current_page;

      let url = '';
      if (gameType == -1) {
        url = `/index/hot?page=${page}`;
      } else if (gameType == 5) {//捕鱼游戏链接
        url = `/index/hunter?page=${page}`;
      } else if([0,2,4].includes(gameType)){
        url = `/index/games?type=${gameType}&page=${page}`
      }
      
      const cacheKey = `local-game-list-${gameType}-${page}`;
      const pagerLocalData = Tool.getGameListLocal(cacheKey);
      if(pagerLocalData){
        let localGameList = pagerLocalData.data;
        if(localGameList && localGameList.length){
          this.gameList = this.gameList.concat(localGameList);
          setTimeout(() => {
            this.showGameAnimateIco(localGameList);
          })
          this.getHomeGameListByPagerUpdateCach(url,cacheKey);
          return;
        }
      }
      this.showLoading();
      this.pageDataLoading = 1;
      Request.post(url).then(res => {
        this.closeLoading();
        this.pageDataLoading = 0;
        if (res.data && res.data.data) {
          //假数据
          (() => {
            let f = res.data.data[0];
            let data = JSON.parse(JSON.stringify(f));
            data.id = 989898;
            data.alia_name = '测试名';
            res.data.data.push(data);
          })
          
          let pagerData = res.data.data;
          this.setPagerDataObj(pagerData);
          pagerData.data.forEach(game => game.isLoaded = 0);
          this.gameList = this.gameList.concat(pagerData.data);
          Tool.setGameListLocal(cacheKey,pagerData);
          Tool.updateReloadIsUpdate(cacheKey);

          if (pagerData.data.length) {
            setTimeout(() => {
              this.showGameAnimateIco(pagerData.data);
            })
          }
        }
      }).catch(error =>  {
        this.closeLoading();
        this.pageDataLoading = 0;
        if(error.response && error.response.data && error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      });

    },

    // 电子游艺与棋牌游戏分页列表缓存更新
    getDzyyListByPagerUpdateCach(url,apiParams,cacheKey){
      let cachKeyDicts = Tool.getReloadIsUpdate();
      if(cacheKey in cachKeyDicts) return;
      Request.post(url,apiParams).then(res => {
        if(res.data && res.data.data && res.data.data.data){
          let dataList = res.data.data.data;
          Tool.setGameListLocal(cacheKey,dataList);
          Tool.updateReloadIsUpdate(cacheKey)
        }
      }).catch(error =>  {
        if(error.response && error.response.data && error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      })
    },
    // 电子游艺与棋牌游戏分页列表
    getDzyyListByPager(code){
      let page = this.dataPagerObj.current_page;
      let url = `/index/games?page=${page}`
      let apiParams = {
        type:this.invoke,
        platform:code
      }
      const cacheKey = `local-dzyy-game-list-${this.invoke}-${code}-${page}`;
      const localGameList = Tool.getGameListLocal(cacheKey);
      if(localGameList && localGameList.length){
        this.dataPageDataHandle(localGameList);
        this.getDzyyListByPagerUpdateCach(url,apiParams,cacheKey);
        return;
      }
      this.showLoading()
      this.pageDataLoading = 1
      Request.post(url,apiParams).then(res => {
        this.closeLoading()
        this.pageDataLoading = 0;
        if(res.data && res.data.data && res.data.data.data){
          let dataList = res.data.data.data;
          Tool.setGameListLocal(cacheKey,dataList);
          this.dataPageDataHandle(dataList);
          Tool.updateReloadIsUpdate(cacheKey)
        }
      }).catch(error =>  {
        this.pageDataLoading = 0
        this.closeLoading();
        if(error.response && error.response.data && error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      })
    },
    // 电子游艺与棋牌游戏列表
    getDzyyGameList(code){
      let page = this.dataPagerObj.current_page;
      let url = `/index/games?page=${page}`
      let apiParams = {
        type:this.invoke,
        platform:code
      }
      if(this.$refs.gameListScroller){
        this.$refs.gameListScroller.scrollLeft = 0;
      }
      const cacheKey = `local-dzyy-game-list-${this.invoke}-${code}-${page}`;
      const localGamePager = Tool.getGameListLocal(cacheKey);
      if(localGamePager && localGamePager.data && localGamePager.data.length){
        this.setPagerDataObj(localGamePager);

        let localGameList = localGamePager.data;
        this.gameListSource = localGameList;
        this.gameList = localGameList.map(item => item);
        setTimeout(() => {
          this.showGameAnimateIco();
        })
        this.getDzyyGameListUpdateCach(url,apiParams,cacheKey);
        return;
      }

      this.gameList = [];
      this.showLoading()
      Request.post(url,apiParams).then(res => {
        this.closeLoading()
        if(res.data && res.data.data){
          let pagerData = res.data.data;
          this.setPagerDataObj(pagerData);

          if(pagerData.data){
            this.gameListSource = pagerData.data;
            Tool.setGameListLocal(cacheKey,pagerData);
            Tool.updateReloadIsUpdate(cacheKey);

            this.gameList = this.gameListSource.map(item => item);
            if (this.gameList.length) {
              setTimeout(() => {
                this.showGameAnimateIco();
              })
            }
          }
        }
      }).catch(error =>  {
        this.closeLoading();
        if(error.response && error.response.data && error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      })
    },
    // 电子游艺棋牌缓存更新
    getDzyyGameListUpdateCach(url,apiParams,cacheKey){
      let cachKeyDicts = Tool.getReloadIsUpdate();
      if(cacheKey in cachKeyDicts) return;

      Request.post(url,apiParams).then(res => {
        if(res.data && res.data.data){
          let pagerData = res.data.data;
          this.setPagerDataObj(pagerData);
          if(pagerData.data){
            this.gameListSource = pagerData.data;
            Tool.setGameListLocal(cacheKey,pagerData);
            Tool.updateReloadIsUpdate(cacheKey);
          }
        }
      }).catch(error =>  {
        if(error.response && error.response.data && error.response.data.code == 1000006){
          Request.userOutted(this)
        }
      })
    },
    setPagerDataObj(pagerData){
      let {per_page,total} = pagerData;
      this.dataPagerObj.per_page = per_page;
      this.dataPagerObj.total = total;
      this.dataPagerObj.pageMax = Math.ceil(total/per_page);
    }
  },
  computed:{
    ...mapGetters({
      msgAlertClose:'getMsgAlertClose',
      isUserInfoApiDataUpdated:'getIsUserInfoApiDataUpdated',
      isShowLoading:'getShowLoading',
    })
  },
  watch: {
    msgAlertClose(){
      this.showAnnounce = 0;
    },
    isScrollerScroll(n){
      if(n){
        this.scrollToContainer(-1)
      }
    },
    isTaskGame(n){
      if(n){
        let jsonStr = n.split('^')[0];
        let game = JSON.parse(jsonStr);
        let p = {
          code:game.game_code,
          plat_code:game.plat_code
        };
        this.goGameClick(p)
      }
    },
    removeLocalFavorite(n){
      if(n){
        const _this = this;
        Object.keys(_this.gameListSelectDic).forEach(key => {
          const idSelect = _this.gameListSelectDic[key];
          if(idSelect){
            let found = -1;
            _this.gameList.forEach((game,index) => {
              if(game.id == key){
                found = index;
              }
            })
            if(found > -1){
              _this.gameList.splice(found,1)
              setTimeout(() => {
                _this.showGameAnimateIco();
              })
            }
          }
        });
      }
    },
    
    gameid(n, o) {
      this.dataPagerObj.current_page = 1;
      if([-1,5,0,2,4].includes(n)){
        this.getGameList(n);
      }else{
        this.getPlatformList(n)
      }
    },
    platformCode(n){
      this.dataPagerObj.current_page = 1;
      this.getDzyyGameList(n);
    },
    isSearch(n){
      let searchTxt = n.split('-')[0];
      this.searchGameList(searchTxt);
    },
    isUserInfoApiDataUpdated(n){
      if(n){
        this.getUserInfoData();
      }
    }
  },
  components: {
    Regist,
    Marquee,
    Loading
  }
}
</script>
<style scoped lang="stylus">
.game-list-wrapper
  width calc(100% - 2.35rem)
  user-select none
  &.favorite
    width auto !important
  &.horizontal
    width calc(100% - 2.02rem)
  &.mb2345browser,&.vivo
    width calc(100% - 2.4rem)
  .g-list-scroller
    width 96%
    height 2.28rem
    margin 0 0 0 .12rem
    overflow hidden
    &.visibility-hiden
      visibility hidden
    &.dzyy
      margin-top 0
    &.favorite
      width 6.9rem
      margin 0 auto
    .g-list-container
      width auto
      height 2.16rem
      display flex
      align-content flex-start
      flex-direction column
      flex-wrap wrap
      &.single-page
        flex-direction row
        align-items center
        align-content center
        flex-wrap nowrap
      &.qipai
        height 2.1rem
      .pager-loading{
        width .5rem
        height 100%
        visibility hidden
        color #000
        display flex
        align-items center
        &.show{
          visibility visible
        }
      }
      .g-list-item
        min-width 1.02rem
        width auto
        height 1.02rem
        margin-right .06rem
        display flex
        justify-content center
        align-items center
        position relative
        &.favorite
          margin-right .14rem
        &.ainmate-ico
          width 1.02rem
        &.sport
          height 1.3rem
          transform scale(1.5)
          margin auto
        .game-tap-cover
          background rgba(255,255,255,0)
          position absolute
          width 100%
          height 100%
          z-index 1
          top 0
          left 0;
        .ani-wrapper
          min-width .94rem
          height 100%
          .pre-img-an
            left 0
            top 0
            z-index 1
            overflow hidden
            width .94rem
            height 1.02rem
            position absolute
            .none-ico
              & > div
                bottom 0
        .animation-ico
          height 100%
          overflow hidden
        &.single-page
          flex-shrink 0
          &.qipai
            width 1.33rem
            height 1.66rem
            margin-right .06rem
          &.dzyy
            height 1.08rem
        &.big-margin-right
          height 1.66rem
          margin-right .2rem
        .item-ico-wrap
          width 100%
          height 100%
          border-top 1px solid transparent
          .pre-img-an
            width 1.05rem
            height 1.15rem
            position absolute
            z-index 1
            left 50%
            top 50%
            transform translate(-50%,-50%)
        .g-item-ico
          display block
          width auto
          max-height 100%
          height 100%
          opacity 0
          margin auto
          &.loaded-show
            opacity 1
        .selector
          width .24rem
          height .24rem
          z-index 10
          bottom 0
          right 0
          position absolute
          background-image url(../assets/home/favorite-select.png)
          background-size 100% 100%
          &.selected
            background-image url(../assets/home/favorite-selected.png)
        .none-ico
          color #fff
          width 100%
          height 100%
          background-image url(../assets/favicon.png)
          background-size 100% auto
          background-repeat no-repeat
          font-size .14rem
          position relative
          &>div
            width .88rem
            text-align center
            position absolute
            bottom .04rem
            left 50%
            transform translateX(-50%)
  .ann-wrapper
    width 90%
    height .25rem
    background-image url('../assets/home/game/announc_bg.png')
    background-size 100% 100%
    background-repeat no-repeat
    margin auto
    display flex
    align-items center
    justify-content space-between
    .a-b-wrapper
      width 59%
      display flex
      align-items center
    .ann-w-ico
      width .17rem
      height auto
      position relative
      left .3rem
      z-index 1
    .ann-content
      width calc(100% - .52rem)
      position relative
      left .35rem
    .official-url
      height 100%
      display flex
      align-items center
      .url
        color white
        width 1.54rem
        height 100%
        position relative
        display flex
        align-items center
        justify-content center
        .sepretor
          width .02rem
          height 100%
          position absolute
          top 0
          left 0
        .home-url
          width 1.5rem
          font-size .14rem
          text-align center
          background none
          user-select none
    .ann-copy-btn
      width .48rem
      height .25rem
  .dzyy-description
    width auto
    height .25rem
    margin .1rem 0 0 .3rem
    color #ffffff
    &.hidden
      visibility hidden
</style>