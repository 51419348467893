<template>
  <div class="m-quee-wrap flex" ref="historyScoller">
    <div class="marquee-item" v-for="mitem of marqueeList">
      <span class="uname">{{mitem.nickname}}</span>在{{mitem.game_name}}中奖<span class="amount">{{mitem.win_amount}}</span>元！
    </div>
  </div>
</template>

<script>
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'

export default {
  data(){
    return{
      preMarqScrollLeft:0,
      stopMarquee:0,
      marqueeList:[],
    }
  },
  mounted(){
    this.getMarquee();
  },
  methods:{
    restartMarquee(){
      this.stopMarquee = 0;
      this.marqueeGo();
    },
    getMarquee(){
      let list = Tool.getMarqueeList();
      if(list){
        this.marqueeList = list;
        this.restartMarquee();
        return;
      }
      Request.post('/pub/marquee').then(res => {
        this.marqueeList = res.data.data;
        Tool.setMarqueeList(res.data.data);
        this.restartMarquee();
      });
    },
    marqueeGo(){
      let ele = this.$refs.historyScoller;
      if(!ele) return;
      
      let run = () => {
        ele.scrollLeft+=1;
        if(this.preMarqScrollLeft == ele.scrollLeft){
          ele.scrollLeft = 0
        }
        this.preMarqScrollLeft = ele.scrollLeft;
        
        if(!this.stopMarquee){
          requestAnimationFrame(run)
        }
      };
      run();
    },
  }
}
</script>
<style scoped lang="stylus">
.m-quee-wrap
  width 100%
  padding-left 1.8rem
  flex-wrap nowrap
  overflow-x auto
  overflow-y hidden
  .marquee-item
    flex-shrink 0
    font-size .12rem
    color white
    margin-right 1.8rem
    display flex
    align-items center
    .uname
      color #00FFCF
    .amount
      color #FFF31E
</style>