<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/geren/binding-bank-title.png" />
    </template>
    <template v-slot:content>
      <div class="bind-bank-scroll">
        <div class="l-c-w-i">
          <div class="bank_field flex">
            <img class="f-title" src="../../assets/geren/acc-f-title.png" />
            <input v-model="accName" placeholder="请输入账号姓名" />
          </div>
          <div class="bank_field flex">
            <img class="f-title" src="../../assets/geren/acc-c-title.png" />
            <input v-model="account" placeholder="请输入银行卡账号" />
          </div>
          <div class="bank_field flex">
            <img class="f-title" src="../../assets/geren/acc-p-title.png" />
            <input v-model="zhihang" placeholder="请输入开户行或者开户省份" />
          </div>
        </div>
        <div class="input-tips">
          请输入正确的银行卡账号及持卡人姓名，否则会导致兑换失败。
        </div>
        <div class="bank-l-wrap flex">
          <div class="bank-l-ceil" @click="backListClick(i)"
            v-for="(bank,i) of bankList">
            <img :src="bank.image_url" />
            <img class="border-img" v-if="i == backSelectI"
              src="../../assets/geren/banck-list-border.png" />
          </div>
        </div>
      </div>
      <img @click="confirmClick()"
        class="l-confirm-btn" src="../../assets/login/confirm-btn.png" />
    </template>
  </PopWidget>
</template>
<script>
import {mapMutations} from 'vuex'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import PopWidget from '../common/PopWidget.vue'

export default {
  props:{
    isShow:Number,
    bank:{}
  },
  data(){
    return{
      isShowPopWidget:0,
      select_i:0,
      backSelectI:-1,
      bankList:[],
      accName:'',
      account:'',
      zhihang:'',
    }
  },
  mounted(){
  },
  methods:{
    ...mapMutations([
      'popMsg','closeLoading','showLoading'
    ]),
    backListClick(i){
      this.backSelectI = i;
    },
    confirmClick(){
      if(!this.accName){
        this.popMsg('账号姓名不能为空');return;
      }
      if(!this.account){
        this.popMsg('银行卡账号不能为空');return;
      }
      if(!this.zhihang){
        this.popMsg('请输入开户行或开户省份');return;
      }
      if(this.backSelectI == -1){
        this.popMsg('请输选择银行');return;
      }
      let param = {
        bank_username:this.accName,
        bank_name:this.bankList[this.backSelectI].name,
        bank_account:this.account,
        bank_branch:this.zhihang,
      }
      this.showLoading()
      Request.post('/user/bank',param).then(res => {
        if(res.data.code == 0){
          this.popMsg(res.data.msg)
          this.updateUserInfo();
          this.isShowPopWidget = 0;
        }
      }).catch(e => {
        this.popMsg(e.response.data.msg)
      }).finally(() => {
        this.closeLoading()
      })
    },
    updateUserInfo(){
      Tool.removeLocalUserInfo();
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          Tool.setLocalUserinfo(res.data.data);
          this.$emit('bindSuccess')
        }
      });
    },
    getBankList(callback){
      let localBank = Tool.getBankList();
      if(localBank){
        this.bankList = localBank;
        if(callback)callback();
        return;
      }
      Request.get('/pub/bank').then(res => {
        let cach = {
          date:new Date(),
          bankArray:res.data.data,
        }
        Tool.setBankList(cach);
        this.bankList = cach.bankArray;
        if(callback)callback();
      })
    },
    showBankInfo(){
      if(this.bank){
        this.accName = this.bank.bank_username;
        this.account = this.bank.bank_account;
        this.zhihang = this.bank.bank_branch;
        let found_i = -1;
        this.bankList.forEach((bank,i) => {
          if(bank.name == this.bank.bank_name){
            found_i = i;
          }
        })
        this.backSelectI = found_i;
      }
    }
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
      if(n){
        this.showBankInfo();
        this.getBankList(() => {
          this.showBankInfo();
        });
      }
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.l-t-title
  width 2.14rem
  margin-top -.04rem
.l-confirm-btn
  width .94rem
  margin-bottom -.16rem
  transition transform .3s
  &:active
    transform scale(.8)
.bind-bank-scroll
  width 99%
  height 1.76rem
  overflow auto
.l-c-w-i
  display flex
  width 100%
  border-top 1px solid transparent
  padding 0 .2rem
  flex-wrap wrap
  .bank_field
    width 50%
    flex-direction column
    align-items center
    .f-title
      width 1.2rem
      display block
      margin .02rem 0 .02rem 0
    input
      width 1.9rem
      height .3rem
      border none
      border-radius .16rem
      padding-left .08rem
      display block
      color #fee8ca
      background-color #B29369
.input-tips
  margin .02rem 0
  font-size .12rem
  text-align left
  color #7C6F53
  padding-left .2rem
.bank-l-wrap
  width 100%
  padding 0 0 0 .25rem
  flex-wrap wrap
  .bank-l-ceil
    width .96rem
    height .28rem
    margin-right .1rem
    margin-bottom .05rem
    position relative
    .border-img
      width 100%
      height 100%
      position absolute
      z-index 1
      top 0
      left 0
    img
      width 100%
      height 100%
      display block
</style>