<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/recharge/reporting-title.png" />
    </template>
    <template v-slot:content>
      <div class="report-content">
        <div class="text">
          1.如果您在充值中发现有代理充值人员，私自向您推荐其他游戏产品或其他目的。<br>
          2.请您拍视频留档，并向我们举报。<br>
          3.查证后立即奖励<span style="color:#E1882B">{{promoInfo.report_bonus}}</span>金币
        </div>
        <div class="func-btn flex">
          <img @click="reportingCancel"
            class="reporting-btn ease-btn-effe"
            src="../../assets/recharge/reporting-cancel.png" />
          <img @click="reportingClick" class="reporting-btn ease-btn-effe"
            src="../../assets/recharge/reporting-btn.png" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import Tool from '../../common/Tool.js'
import PopWidget from '../common/PopWidget.vue'
export default{
  props:{
    show:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      promoInfo:{},
    }
  },
  methods:{
    getPromoInfo(callback){
      let localPromo = Tool.getPromLocalInfo();
      if(localPromo){
        this.promoInfo = localPromo;
        if(callback)callback()
        return;
      }
      Request.post('/promo/info').then(res => {
        if(res.data.data){
          this.promoInfo = res.data.data;
          Tool.setPromLocalInfo(res.data.data);
          if(callback)callback()
        }else{
          this.promoInfo = {}
        }
      })
    },
    reportingCancel(){
      this.isShowPopWidget = 0;
    },
    reportingClick(){
      this.$emit('reporting')
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.getPromoInfo();
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style lang="stylus" scoped>
.report-content
  width 100%
  .text
    width 80%
    line-height .24rem
    font-size .16rem
    color #897C62
    text-align left
    margin auto
    padding .2rem 0
.func-btn
  width 68%
  justify-content space-around
  margin 0 auto
.reporting-btn
  width 1.1rem
</style>