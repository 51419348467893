<template>
  <BankInfo :isShow="isShowBankInfo" style="z-index:4"
    :bank="userInfo && userInfo.bank?userInfo.bank:{}"
    :aliplay="alipayInfo"  />

  <BindAlipay :isShow="isShowBindAlipay" style="z-index:4" 
    @bindSuccess="alipayBindSuccess"
    :aliyName="userInfo?userInfo.name:''" 
    :alipay="userInfo?userInfo.alipay:''" />

  <BindBankCard :isShow="isShowBindBank" style="z-index:4"
    @bindSuccess="accBindSuccess" />
  
  <BindDigitalWallet :isShow="isShowBindDigitalWallet" style="z-index:4"
    @bindSuccess="walletBindSuccess" />

  <WalletInfo :isShow="isShowWalletInfo" style="z-index:4"
    :wallets="userInfo.wallets && userInfo.wallets.length?userInfo.wallets:null" />
  
  <DuihuanConfirm :withdrawInfo="withdrawInfo" :show="confirmShow" 
    :dhAmount="dhAmount" @confirm="confirmSubmit" />
    
  <DuihuanRecord :show="isShowRecord" />

  <DuihuanSmsCheck :show="smsCheckShow" @smsConfirm="confirmSmsSubmit" />

  <PopWidget width="6rem" :isFull="1" :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/duihuan/duihuan-title.png" />
    </template>
    <template v-slot:content>
      <div class="main-content flex">
        <div class="menu-wrap">
          <div class="inner-back">
            <div class="menu-wrap-scroll">
              <div :key="menu.id" class="menu-btn" v-for="(menu,i) of menuItems"
                @click="menuClick(i)" :class="{select:menuSelectIndex==i}"
                v-show="menu.show" :data-id="menu.id">
                <div class="normal-bg"></div>
                <div class="select-bg"></div>
                <div class="menu-text">
                  {{ menu.name }}
                </div>

                <div class="w-recharge-rate" 
                  v-show="menu.id == 'way-2' && userInfo.w_recharge_rate && userInfo.w_recharge_rate > 0">
                  <div class="value-w">
                    {{userInfo.w_recharge_rate}}%
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="m-w-page" v-if="menuInited">
          <DuihuanAmount :userInfo="userInfo" :wayId="currentMenuId"
            :withdrawInfo="withdrawInfo" :currMenuItem="currMenuItem"
            :walletThirds="walletThirds"
            @showAlipayClick="showAlipayClick"
            @bandingAlipayClick="bandingAlipayClick" 
            @duihuanSubClick="duihuanSubClick"
            @showRecordClick="showRecordClick" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import BankInfo from '../GerenZhongxin/BankInfo.vue'
import BindAlipay from '../GerenZhongxin/BindAlipay.vue'
import BindBankCard from '../GerenZhongxin/BindBankCard.vue'
import BindDigitalWallet from '../GerenZhongxin/BindDigitalWallet.vue'
import WalletInfo from '../GerenZhongxin/WalletInfo.vue'
import DuihuanAmount from './DuihuanAmount.vue'
import DuihuanConfirm from './DuihuanConfirm.vue'
import DuihuanRecord from './DuihuanRecord.vue'
import DuihuanSmsCheck from './DuihuanSmsCheck.vue'
import { mapMutations } from 'vuex'

export default{
  emits:['duihuanSuccess'],
  props:{
    show:Number,
  },
  data(){
    return{
      userInfo:{},
      menuItems:[//way-2 免提直冲
        {
          id:'way-0',
          name:'兑换到支付宝',
          show:0,
        },
        {
          id:'way-1',
          name:'兑换到银行卡',
          show:0,
        },
        {
          id:'way-2',
          name:'免提直充',
          show:1,
        },
        {
          id:'way-3',
          name:'兑换到数字钱包',
          show:1,
        },
        {
          id:'way-4',
          name:'加密货币',
          show:0,
        }
      ],
      isShowPopWidget:0,
      menuSelectIndex:0,
      promoInfo:{},
      walletThirds:[],
      /*
      chargeout_setting:{
        out_lower_limit_alipay 支付宝最小提现值
        out_upper_limit_alipay 支付宝最大提现值
        out_lower_limit_bank 银行卡最小提现值
        out_upper_limit_bank 银行卡最大提现值
        out_lower_limit_wallet 数字钱包最小提现值
        out_upper_limit_wallet 数字钱包最大提现值
        out_lower_limit_w_recharge 直充最小提现值
        out_upper_limit_w_recharge 直充最大提现值
      }
      */
      withdrawInfo:{},
      dhAmount:0,
      isShowBankInfo:0,
      isShowBindAlipay:0,
      isShowBindBank:0,
      isShowWalletInfo:0,
      isShowBindDigitalWallet:0,
      confirmShow:0,
      alipayInfo:{},
      payWayAccount:'',
      noWalletType:'',
      seletWalletId:0,
      requesting:false,
      isShowRecord:0,
      currMenuItem:{},
      smsCheckShow:0,
      menuInited:0,
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading','popMsg']),
    showRecordClick(){
      this.isShowRecord = Math.random();
    },
    tixianChongzhiSubmit(sms_code){
      if(this.requesting) return;
      let smsCheck = 1 * this.withdrawInfo.sms_check;
      if(smsCheck == 1 && !sms_code){
        this.smsCheckShow = Math.random();
        return;
      }
      this.showLoading();
      this.requesting = true;
      let p = {
        amount:this.dhAmount,
      }
      if(sms_code){
        p.code = sms_code;
      }
      console.log('免提直充请求!')
      Request.post('/user/w_recharge',p).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.getWithdrawInfo();
          this.$emit('duihuanSuccess')
          this.popMsg('申请提交成功')
        }else{
          this.popMsg(res.data.msg)
        }
        this.requesting = false;
        this.smsCheckShow = 0;
      }).catch((error) => {
        this.requesting = false;
        this.closeLoading();
        if(error.response && error.response.data && error.response.data.msg){
          if(error.response.data.code == 1000006){
            Request.userOutted(this);
          }else{
            this.popMsg(error.response.data.msg)
          }
        }
      })
    },
    confirmSmsSubmit(sms_code){
      this.confirmSubmit(sms_code);
    },
    closeDuihuanChildPop(){
      this.confirmShow = 0
    },
    confirmSubmit(sms_code){
      if(this.currentMenuId == 'way-2'){
        console.log('way-2')
        this.tixianChongzhiSubmit(sms_code);
        return;
      }
      if(this.requesting) {
        console.log('requesting')
        return;
      }
      let smsCheck = 1 * this.withdrawInfo.sms_check;
      if(smsCheck == 1 && !sms_code){
        this.smsCheckShow = Math.random();
        console.log('sms check')
        return;
      }
      const p = {
        amount:this.dhAmount,
        //3:数字钱包 2:支付宝&加密货币 1:银行卡 
        withdraw_type:1,
        withdraw_account:this.payWayAccount,
      }
      if(sms_code){
        p.code = sms_code;
      }
      const wayId = this.currentMenuId;
      if(wayId == 'way-0'){
        p.withdraw_type = 2;
      }else if(wayId == 'way-1'){
        p.withdraw_type = 1;
      }else if(wayId == 'way-3'){
        p.withdraw_type = 3;
      }
      //#region 以下代码或许可以移除
      else if(wayId == 'way-4'){
        p.withdraw_type = 3;
      }
      else if(this.currMenuItem.wallet == 1 || Tool.walletId.includes(wayId)){
        p.withdraw_type = 3;
      }
      //#region end

      let apiPath = '/user/withdraw'
      //no 钱包
      if(wayId == 139){
        p.withdraw_type = this.noWalletType;
        apiPath = '/user/w_nopay'
      }
      if(this.seletWalletId){
        p.wallet_id = this.seletWalletId;
      }
      this.showLoading();
      this.requesting = true;
      console.log('requesting api')
      Request.post(apiPath,p).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.getWithdrawInfo();
          this.$emit('duihuanSuccess')
          this.closeDuihuanChildPop();
          let successMsg = '申请提交成功';
          if(this.language == 'pt-BR'){
            successMsg = 'Pedido enviado aguarde o processamento';
          }
          this.popMsg(successMsg);
        }else{
          this.popMsg(res.data.msg)
        }
        this.requesting = false;
        this.smsCheckShow = 0;
      }).catch((error) => {
        this.requesting = false;
        this.closeLoading();
        if(error.response && error.response.data &&
          error.response.data.msg){
          this.popMsg(error.response.data.msg)
        }
      }).finally(() => {
        this.requesting = false;
      })
    },
    duihuanSubClick(p){
      this.dhAmount = p.dhAmount;
      this.payWayAccount = p.account;
      this.noWalletType = p.noWalletType;
      this.seletWalletId = p.wallet_id;

      this.confirmShow = Math.random()
    },
    walletBindSuccess(){
      this.updateUserinfo();
    },
    accBindSuccess(){
      this.updateUserinfo();
    },
    alipayBindSuccess(){
      this.updateUserinfo();
    },
    updateUserinfo(){
      Tool.removeLocalUserInfo();
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          Tool.setLocalUserinfo(res.data.data);
          this.userInfo = res.data.data;
        }
      });
    },
    bandingAlipayClick(wayId){
      if(wayId == 'way-0' || wayId == 'way-4'){
        this.isShowBindAlipay = Math.random();
      }else if(wayId == 'way-1'){
        this.isShowBindBank = Math.random();
      }else if(wayId == 'way-3' || this.currMenuItem.wallet == 1 || Tool.walletId.includes(wayId)){
        this.isShowBindDigitalWallet = Math.random();
      }
    },
    showAlipayClick(wayId){
      if(wayId == 'way-0' || wayId == 'way-4'){
        if(this.userInfo && this.userInfo.alipay){
          this.alipayInfo = {
            alipay:this.userInfo.alipay,
            name:this.userInfo.name
          };
          this.isShowBankInfo = Math.random()
        }
      }else if(wayId == 'way-1'){
        this.alipayInfo = null;
        this.isShowBankInfo = Math.random()
      }else if(wayId == 'way-3' || this.currMenuItem.wallet == 1 || Tool.walletId.includes(wayId)){
        this.isShowWalletInfo = Math.random();
      }
    },
    getWithdrawInfo(callback){
      Request.post('/user/get_withdraw_info').then(res => {
        this.withdrawInfo = res.data.data;//sms_check
        if(callback) callback();
      }).catch(() => {
        if(callback) callback();
      })
    },
    getPromoInfo(){
      let localPromo = Tool.getPromLocalInfo();
      if(process.env.NODE_ENV == 'development' && localPromo){
        this.promoInfo = localPromo;
        this.promoDataHandle();
        return;
      }
      Request.post('/promo/info').then(res => {
        if(res.data.data){
          this.promoInfo = res.data.data;
          Tool.setPromLocalInfo(res.data.data);
          this.promoDataHandle();
        }else{
          this.promoInfo = {}
        }
      })
    },
    promoDataHandle(){
      /*
      bank_status :1
      alipay_status：1
      wallet_status:1
      w_recharge_status:1
      顺序 支付宝， 银行卡，加密货币，数字钱包，免提直充
      */
      if(this.promoInfo.alipay_status == 1){
        this.showMenuByid('way-0');
      }else{
        this.removeMenuByid('way-0');
      }
      if(this.promoInfo.bank_status == 1){
        this.showMenuByid('way-1');
      }else{
        this.removeMenuByid('way-1');
      }
      if(this.promoInfo.w_recharge_status == 1){
        this.showMenuByid('way-2');
      }else{
        this.removeMenuByid('way-2');
      }
      // 兑换到数字钱包
      if(this.promoInfo.wallet_status == 1){
        // this.showMenuByid('way-4');
        this.showMenuByid('way-3');
        this.getWalletList(() => {
          this.menuInited = 1;
        });
      }else{
        this.menuInited = 1;
        this.removeMenuByid('way-3');
      }
      

    },
    showMenuByid(id){
      let index = -1;
      for(let i = 0,len = this.menuItems.length; i < len; i++){
        if(this.menuItems[i].id == id){
          index = i;
        }
      }
      if(index > -1){
        this.menuItems[index].show = 1;
      }
    },
    removeMenuByid(id){
      let index = -1;
      for(let i = 0,len = this.menuItems.length; i < len; i++){
        if(this.menuItems[i].id == id){
          index = i;
        }
      }
      if(index > -1){
        this.menuItems.splice(index,1);
      }
    },
    getWalletList(callback){
      const _this = this;
      
      let localList = Tool.getWayWalletList();
      if(process.env.NODE_ENV == 'development' && localList && localList.length) {
        _this.walletThirds = localList;
        if(callback) callback();
        return;
      }

      Request.post('/user/wallet_thirds').then(res => {
        const walletList = res.data.data?res.data.data:[];
        if(walletList.length){
          Tool.setWayWalletList(walletList)
        }
        _this.walletThirds = walletList;
        if(callback) callback();
      }).catch(() => {
        if(callback) callback();
      })
    },
    menuClick(i){
      this.currMenuItem = this.menuItems[i];
      this.menuSelectIndex = i;
    },
    // getCurrWallet(){
    //   if(this.currMenuItem.wallet == 1){
    //     if(this.userInfo.wallets && this.userInfo.wallets.length){
    //       let found = null;
    //       this.userInfo.wallets.forEach(wallet => {
    //         if(wallet.third_id == this.currMenuItem.third_id){
    //           found = wallet;
    //         }
    //       })
    //       if(found){
    //         this.seletWalletId = found.id;
    //       }
    //       else{
    //         this.seletWalletId = this.userInfo.wallets[0].id;
    //       }
    //     }
    //   }else{
    //     this.seletWalletId = 0;
    //   }
    // },
  },
  computed:{
    currentMenuId(){
      if(this.menuItems.length && this.menuItems[this.menuSelectIndex]){
        return this.menuItems[this.menuSelectIndex].id;
      }
    },
  },
  watch:{
    show(n){
      if(n){
        this.isShowPopWidget = n;
        this.getPromoInfo();
        this.showLoading();
        this.getWithdrawInfo(() => this.closeLoading());
        this.userInfo = Tool.getLocalUserinfo()
      }
    }
  },
  components:{
    PopWidget,
    BankInfo,
    BindAlipay,
    DuihuanAmount,
    BindBankCard,
    BindDigitalWallet,
    WalletInfo,
    DuihuanConfirm,
    DuihuanRecord,
    DuihuanSmsCheck,
  }
}
</script>

<style scoped lang="stylus">
.main-content
  width 100%
  height auto
  .menu-wrap
    width 1.8rem
    height 2.28rem
    position relative
    z-index 1
    overflow auto
    &::-webkit-scrollbar
      width 0
    .inner-back
      width 1.23rem
      min-height 100%
      height auto
      background linear-gradient(#EACD9B, #D8A96B)
      box-shadow 0 0 10px 0 rgba(0,0,0,.6)
      padding-bottom .3rem
    .menu-wrap-scroll
      width 100%
      height auto
      padding-top .12rem
      .menu-btn
        width 1.25rem
        height .44rem
        display block
        margin 0 0 .06rem .05rem
        position relative
        &:active
          appearance none
          -moz-appearance none
          -webkit-appearance none
        .w-recharge-rate
          position absolute
          top -.03rem
          padding .03rem 0
          right .11rem
          font-size 12px
          background-image url(../../assets/recharge/zengsong-percent.png)
          background-repeat no-repeat
          background-size 100% 100%
          color white
          border-radius .04rem .04rem 0 0
          width .28rem
          height .3rem
          padding .17rem 0 0 .03rem
          .value-w
            transform scale(.8)
        .select-bg
          display none
        &.select
          .normal-bg
            display none
          .select-bg
            display block
        .normal-bg
          width 1.11rem
          height 100%
          background-image url(../../assets/geren/vip-menu-btn.png)
          background-repeat no-repeat
          background-size 100% 100%
        .select-bg
          width 100%
          height 100%
          background-image url(../../assets/geren/vip-menu-se-btn.png)
          background-repeat no-repeat
          background-size 100% 100%
        .menu-text
          width 1.11rem
          height 100%
          text-align center
          line-height .4rem
          font-weight bold
          font-size .14rem
          color #842404
          position absolute
          z-index 1
          top 0
          left 0
  .m-w-page
    width 6.15rem      
    height 2.28rem
    margin-left -.05rem
    background-image url(../../assets/geren/geren-back.png)
    background-repeat no-repeat
    background-size 100% 100%
    overflow auto
</style>