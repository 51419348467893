<template>
  <EmailConfirm :show="isShowConfirm" :msg="confirmMsg" 
    @e_confirm="confirmHandle"/>
  <EmailDetail :show="showDetails" :email="detailEmail" @delete="emailDeleteClick" />
  <PopWidget :isShow="isShowPopWidget" :isFull="1" width="5.18rem">
    <template v-slot:title>
      <img class="btn-all read" @click="readAllClick" v-if="emailList.length"
        src="../../assets/email/btn-all-read.png" />
      <img class="btn-all del" @click="deleteAllClick" v-if="emailList.length"
        src="../../assets/email/btn-all-delete.png" />

      <img class="l-t-title" 
        src="../../assets/email/email-title.png" />
    </template>
    <template v-slot:content>
      <div class="email-wrap" @scroll="emailWrapScroll">
        <div class="nodata flex" v-if="!emailList.length">
          <img class="no-data-ico" src="../../assets/tuiguang/no-data.png" />
          <div>暂无数据</div>
        </div>
        <div class="e-w-row flex" v-for="(item,itemIndex) of emailList" 
          :class="{readed:item.status == 1}" 
          @click="emailDetailClick(item,itemIndex)">
          <div class="flex status-wrap">
            <div class="read-status" :class="{readed:item.status == 1}"></div>
            <div class="e-title">
              <div class="e-t" :class="{readed:item.status == 1}">
                {{item.msg_title}}
              </div>
              <div :class="{readed:item.status == 1}">
                {{item.msg_sendtime}}
              </div>
            </div>
          </div>
          <div class="goto-details" v-show="item.status != 1"
            @click="showDetailsClick(item,itemIndex)">
            点击查看详情
          </div>
          <img class="readed-ico" v-show="item.status == 1" 
            src="../../assets/email/readed-ico.png" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import EmailConfirm from './EmailConfirm.vue'
import EmailDetail from './EmailDetail.vue'
import { mapMutations } from 'vuex';
import Tool from '@/common/Tool';

export default{
  props:{
    show:Number,
  },
  data(){
    return{
      confirmType:0,// 0全部阅读 1全部删除
      isShowConfirm:0,
      confirmMsg:'',
      isShowPopWidget:0,
      emailList:[],
      cPage:1,
      nextPageGetting:0,
      showDetails:0,
      detailEmail:{},
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.cPage = 1;
      this.emailList = [];
      this.getListApi();
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading','popMsg']),
    emailDetailClick(item,index){
      if(item.status == 1){
        this.showDetailsClick(item,index)
      }
    },
    confirmHandle(){
      this.isShowConfirm = 0;
      //全部阅读
      if(this.confirmType == 0){
        this.showLoading();
        Request.post('/user/inbox_read_all').then(res => {
          this.closeLoading();
          if(res.data.code == 0){
            this.cPage = 1;
            this.emailList = [];
            this.getListApi();
            Tool.removeLocalUserInfo();
            this.popMsg(res.data.msg?res.data.msg:'操作成功')
          }
        }).catch(() => {
          this.closeLoading();
        })
      //全部删除
      }else if(this.confirmType == 1){
        this.showLoading();
        Request.post('/user/inbox_del_all').then(res => {
          this.closeLoading();
          if(res.data.code == 0){
            this.cPage = 1;
            this.emailList = [];
            this.getListApi();
            this.popMsg(res.data.msg?res.data.msg:'操作成功')
          }
        }).catch(() => {
          this.closeLoading();
        })
      }
    },
    emailDeleteClick(){
      this.showLoading();
      Request.post('/user/inbox_del',{ids:this.detailEmail.id}).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.popMsg('删除成功')

          let found = -1;
          this.emailList.forEach((mail,i) => {
            if(this.detailEmail.id == mail.id){
              found = i;
            }
          });
          if(found > -1){
            this.emailList.splice(found,1);
          }
        }
      }).catch(() => {
        this.closeLoading();
      })
    },
    showDetailsClick(data,i){
      this.detailEmail = data;
      this.showDetails = Math.random();
      this.showLoading();
      Request.post('/user/inbox_edit',{ids:data.id}).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.emailList[i].status = 1;
        }
      }).catch(() => {
        this.closeLoading();
      })
    },
    emailWrapScroll(e){
      let sTop = e.target.scrollTop;
      let scrollSpace = sTop + e.target.clientHeight
      let sub = e.target.scrollHeight - scrollSpace;
      if(sub < 5){
        this.getNextPageData();
      }
    },
    getNextPageData(){
      if(!this.dataPager)return;
      const {per_page,total} = this.dataPager;
      const totalPage = Math.ceil(total/per_page);
      if(this.cPage >= totalPage) return;
      if(this.nextPageGetting == 0){
        this.nextPageGetting = 1;
        this.cPage++;
        this.getListApi(this.cPage);
      }
    },
    getListApi(){
      // 假数据
      const testFunc = () => {
        this.emailList = [
          {
            id:'2123',
            msg_title:'eferfwe',
            msg_sendtime:'2023-5-5 6:7:55',
            msg_text:'丰富而服务而服务费单位的',
            status:0,
          }
        ]
        for(let i = 0; i < 30;i++){
          let item = JSON.parse(JSON.stringify(this.emailList[0]));
          item.id = this.emailList.length;
          this.emailList.push(item);
        }
        this.emailList[0].status = 0
      }
      // testFunc();return;
          
      this.showLoading()
      let query = `?page=${this.cPage}`;
      Request.get('/user/inbox'+query).then(res => {
        this.closeLoading()
        this.nextPageGetting = 0;
        if(res.data.data && res.data.data.list){
          this.dataPager = res.data.data.list;
          this.dataPager.data.forEach(item => {
            this.emailList.push(item);
          });
        }
      }).catch(() => {
        this.nextPageGetting = 0;
        this.closeLoading()
      })
    },
    readAllClick(){
      this.confirmMsg = '是否全部阅读？'
      this.isShowConfirm = Math.random();
      this.confirmType = 0;
    },
    deleteAllClick(){
      this.confirmMsg = '是否全部删除？'
      this.isShowConfirm = Math.random();
      this.confirmType = 1;
      
    }
  },
  components:{
    PopWidget,
    EmailConfirm,
    EmailDetail,
  }
}
</script>

<style scoped lang="stylus">
.btn-all
  width .7rem
  left 0
  top .04rem
  position absolute
  &.read
    left .06rem
  &.del
    left .8rem
.email-wrap
  width 100%
  height 2rem
  overflow auto
  padding .12rem 0
  .nodata
    flex-direction column
    justify-content center
    align-items center
    .no-data-ico
      width .38rem
      margin .68rem 0 .1rem
  .e-w-row
    width 94%
    height .48rem
    font-size .12rem
    justify-content space-between
    align-items center
    border-radius .05rem
    margin 0 auto .14rem auto
    background-image linear-gradient(#FFC784,#F9BE78,#F4B56C,#E8A352,#DC9138)
    box-shadow 0 2px 4px 0 rgba(0,0,0,.4)
    color #fff
    padding 0 .2rem
    &.readed
      background-image linear-gradient(#FFF1C4,#FDEDC1,#F9EABA,#F3E1AE,#E5CE95)
    .goto-details
      color #FFEFD9
      border-radius .14rem
      line-height 1
      padding .04rem .06rem
      background #C37100
    .status-wrap
      align-items center
      .e-title
        text-align left
        &>div.readed
          color #B59A76
        .e-t
          margin-bottom .04rem
          &.readed
            color #AC6514
    .readed-ico
      width .32rem
    .read-status
      width .38rem
      height .26rem
      margin-right .12rem
      background-size 100% 100%
      background-image url(../../assets/email/ico-unread.png)
      &.readed
        width .32rem
        margin-left .04rem
        background-image url(../../assets/email/ico-readed.png)
</style>