<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/geren/binding-alipay-title.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <div class="alipay_field flex">
          <div class="label">支付宝账号：</div>
          <input v-model="account" placeholder="请输入支付宝账号" />
        </div>
        <div class="alipay_field flex">
          <div class="label">实名制姓名：</div>
          <input v-model="accName" placeholder="支付宝实名制姓名" />
        </div>
        <div class="alipay_field flex">
          <div style="width:.84rem;height:1px;"></div>
          <div style="font-size:.12rem;text-align: center;color:#897561;">
            *&nbsp;请输入正确的支付宝账号及支付宝实名制姓名.
          </div>
        </div>
      </div>
      
      <img @click="confirmClick()"
        class="l-confirm-btn" src="../../assets/login/confirm-btn.png" />
    </template>
  </PopWidget>
</template>
<script>
import { mapMutations } from 'vuex'
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import PopWidget from '../common/PopWidget.vue'

export default {
  props:{
    aliyName:String,
    alipay:String,
    isShow:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      account:'',
      accName:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirmClick(){
      if(!this.account){
        this.popMsg('请输入支付宝账号')
        return;
      }
      if(!this.accName){
        this.popMsg('请输入支付实名制姓名')
        return;
      }
      let params = {
        alipay_account:this.account,
        alipay_username:this.accName
      }
      Request.post('/user/alipay',params).then(res => {
        if(res.data.code == 0){
          this.popMsg(res.data.msg);
          this.updateUserInfo();
          this.isShowPopWidget = 0;
        }
      }).catch(e => {
        this.popMsg(e.response.data.msg)
      })
    },
    updateUserInfo(){
      Tool.removeLocalUserInfo();
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          Tool.setLocalUserinfo(res.data.data);
          this.$emit('bindSuccess')
        }
      });
    }
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
      this.accName = this.aliyName;
      this.account = this.alipay;
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.l-t-title
  width 2.14rem
  margin-top -.04rem
.l-confirm-btn
  width .94rem
  margin-top .16rem
  transition transform .3s
  &:active
    transform scale(.8)
.l-c-w-i
  display flex
  justify-content center
  width 100%
  border-top 1px solid transparent
  padding 0 .2rem
  flex-wrap wrap
  .alipay_field
    margin .3rem 0 0 0
    align-items center
    .label
      font-size .14rem
      font-weight bold
    &:last-child
      margin-top .1rem
    input
      width 2.2rem
      height .28rem
      background #bd9f76
      border-radius .12rem
      border none
      box-shadow 0 0 5px 0 rgba(0,0,0,.2) inset
      color #fff1df
      line-height .28rem
      padding-left .1rem
      font-weight 100
</style>