import { createApp } from 'vue'
import i18n from './lang/index.js'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.styl'
import localSocket from './common/LocalWebsocket.js'

let app = createApp(App).use(store).use(router).use(i18n);
localSocket.configSocket(app)
window.lVueApp = app;
app.mount('#app');
