<template>
  <YuebaoDetial :show="yebDetailShow" />
  <YuebaoIn :show="yebInShow" :isOut="yebIsOut" @updateYuebao="updateYuebao"
     :yebData="yebDetails" @outConfirm="outConfirm" />
  <ShenfYanzheng :show="sfyzShow" @password="passwordConfirm" 
    @forgotClick="forgetPwdClick" />
  <YebPwdSetting :show="settingPwdShow" :isResetPwd="isResetPwd" @setted="updateYuebao" />
  <PopWidget :isShow="isShowPopWidget" :isFull="1">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/yuebao/yuebao-title.png" />
    </template>
    <template v-slot:content>
      <div class="yuebao-wrapper">
        <div class="min-wrapper">
          <div class="flex h-tool">
            <img class="t-btn help-ico" @click="yuebaoHelpClick"
              src="../../assets/yuebao/help-btn.png" />
            <div class="yb-background flex" 
              :class="{hide:yebDetails.rate_status != 1}">
              <img class="help-laba"
                src="../../assets/yuebao/help-laba.png" />
              <div class="yubao-des flex" ref="yebDescScroller">
                <div class="ybd-inner">
                  {{yebDetails.desc}}
                </div>
              </div>
            </div>
            <img class="t-btn yuebao-details" @click="detailClick"
              src="../../assets/yuebao/yuebao-details.png" />
          </div>
          <div class="flex jine-info">
            <div class="jine-wrap">
              <div class="amount-wrap total">
                {{$t('yuebao.totalAmount')}}：<span>
                  {{yebDetails.balance?yebDetails.balance:'0.00'}}
                </span>
              </div>
              <div class="amount-wrap confirm">
                {{$t('yuebao.confirmAmount')}}：<span>
                  {{ yebDetails.amount?yebDetails.amount:'0.00' }}
                </span>
              </div>
              <div class="amount-wrap w-confirm">
                {{$t('yuebao.beforeConfirm')}}：<span>
                  {{ yebDetails.money?yebDetails.money:'0.00' }}
                </span>
              </div>
            </div>
            <div>
              <div class="prev-shouyi flex">
                <div class="shouyi-ico"></div>
                <div>
                  {{$t('yuebao.lastRoundEarnings')}}
                </div>
              </div>
              <div class="prev-sy-amount">
                <div class="back"></div>
                <div class="amount">
                  {{yebDetails.prev_interest?yebDetails.prev_interest:'0.00'}}
                </div>
                <img class="amount-ico-1" alt=""
                  src="../../assets/yuebao/amount-ico-1.png">
              </div>
            </div>
          </div>

          <div class="flex leiji-sy">
            <div>
              <div>{{$t('yuebao.cumulativeIncome')}}</div>
              <div class="amount lj">
                {{ yebDetails.interest?yebDetails.interest:'0.00' }}
              </div>
            </div>
            <div v-if="yebDetails.rate_status == 1">
              <div>{{$t('yuebao.interestRatePerRound')}}</div>
              <div class="amount ll">
                {{yebDetails.rate?yebDetails.rate:'0.00'}}
              </div>
            </div>
            <div>
              <div>{{$t('yuebao.dailyIncome')}}</div>
              <div class="amount zrsy">
                {{ yebDetails.yesterday?yebDetails.yesterday:'0.00'}}
              </div>
            </div>
          </div>

        </div>
        <div class="flex f-btn-wrap">
          <img class="ease-btn-effe" @click="yuebaoInClick"
            src="../../assets/yuebao/yuebao-btn-in.png" />
          <img class="ease-btn-effe" @click="yuebaoOutClick"
            src="../../assets/yuebao/yuebao-btn-out.png" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import YuebaoDetial from './YuebaoDetial.vue'
import YuebaoIn from './YuebaoIn.vue'
import ShenfYanzheng from './ShenfYanzheng.vue'
import YebPwdSetting from './YebPwdSetting.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import { mapMutations } from 'vuex'

export default{
  inheritAttrs: false,
  props:{
    show:Number,
  },
  emits: ["helpClick"],
  data(){
    return{
      isShowPopWidget:0,
      yebDetailShow:0,
      yebInShow:0,
      yebIsOut:0,
      yebDetails:{},
      dataGot:0,
      sfyzShow:0,
      isResetPwd:0,
      settingPwdShow:0,
      outAmount:0,
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
      this.getData(() => {
        this.scrollRun();
      });
    }
  },
  methods:{
    ...mapMutations([
      'showLoading',
      'closeLoading',
      'popMsg',
      'IsupdateUserCoin',
      'updateAmountAndYeb']),
    updateYuebao(){
      this.dataGot = 0;
      this.getData(() => {
        this.updateAmountAndYeb()
      });
    },
    getData(callback){
      if(this.dataGot == 1) return;
      this.dataGot = 1
      this.showLoading()
      Request.post('/yeb/index').then(res => {
        this.yebDetails = res.data.data;
        this.closeLoading()
        /*
        this.yebDetails.rate_status = 1;
        this.yebDetails.desc = `安静了按理说家具历史爱上了考虑撒
        是爱空间上来看发顺丰级日日福安水分王凯文埅考虑为发动机而快乐我就玩大富翁
        附件为姐来我家佛问为覅偶尔玩诶哦我噢诶我欧文结婚`;
        */ 
        Tool.setLocalYeb(res.data.data);
        
        if(callback)callback()
      }).catch(error => {
        Tool.apiCatchHandle(this,Request,error);
      })
    },
    scrollRun(){
      let ele = this.$refs.yebDescScroller,
        mqWay = 0,hz = 0;
      let preMarqScrollLeft = 0,stopMarquee = false;
      let run = () => {
        if(hz % 1 == 0){
          if(ele.scrollLeft == 0){
            mqWay = 0
          }
          if(mqWay == 0){
            ele.scrollLeft+=1;
          }else if(mqWay == 1){
            ele.scrollLeft-=1;
          }
          if(preMarqScrollLeft == ele.scrollLeft){
            ele.scrollLeft = 0
          }
          preMarqScrollLeft = ele.scrollLeft;
        }
        hz++;
        if(!stopMarquee){
          requestAnimationFrame(run)
        }
      };
      if(ele && this.yebDetails && this.yebDetails.rate_status == 1){
        run();
      }
    },
    forgetPwdClick(){
      this.$emit('forgetButtonClick')
    },
    yuebaoHelpClick(){
      this.$emit('helpClick')
    },
    detailClick(){
      this.yebDetailShow = Math.random();
    },
    passwordConfirm(pwd){
      let param = {
        amount:this.outAmount,
        passwd:pwd
      };
      this.showLoading();
      Request.post('/yeb/transfer_out',param).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.popMsg('转出成功！')
          this.yebInShow = 0;
          this.updateYuebao()
          this.IsupdateUserCoin();
        }else if(res.data.msg){
          this.popMsg(res.data.msg)
        }
      }).catch(err => {
        this.closeLoading();
        if(err.response.data && err.response.data.msg){
          this.popMsg(err.response.data.msg)
        }
      });
    },
    outConfirm(amount){
      this.outAmount = amount;
      this.isResetPwd = 0;
      /* 
        0 是未设置密码，打开设置密码界面 
        1 是已设置密码，打开密码页 
        2 重置密码 */
      if(this.yebDetails.has_pass == 0){
        this.settingPwdShow = Math.random();
      }else if(this.yebDetails.has_pass == 1){
        this.sfyzShow = Math.random();
      }else if(this.yebDetails.has_pass == 2){
        this.isResetPwd = 1;
        this.settingPwdShow = Math.random();
      }
    },
    yuebaoOutClick(){
      this.yebIsOut = 1;
      this.yebInShow = Math.random();
    },
    yuebaoInClick(){
      this.yebIsOut = 0;
      this.yebInShow = Math.random();
    }
  },
  components:{
    PopWidget,
    YuebaoDetial,
    YuebaoIn,
    ShenfYanzheng,
    YebPwdSetting,
  }
}
</script>

<style scoped lang="stylus">
.yuebao-wrapper
  width 100%
  height 2.44rem
  box-shadow 0 0 20px 0 rgba(0,0,0,.6) inset
  .f-btn-wrap
    width 100%
    padding-top .01rem
    justify-content center
    .ease-btn-effe
      width .94rem
      display block
      &:nth-child(2)
        margin-left .5rem
  .min-wrapper
    width 100%
    height auto
    padding .12rem 0 .06rem 0
    background-image url(../../assets/yuebao/yue-bao-m-bg.png)
    background-size 100% 100%
  .h-tool
    justify-content center
    align-items center
  .jine-info
    width 100%
    margin .12rem 0 .02rem 0
    .jine-wrap
      margin 0 0 0 .22rem
      width 2.86rem
    .prev-shouyi
      padding-top .12rem
      align-items center
      font-size .12rem
      color #843D11
      .shouyi-ico
        width .12rem
        height .16rem
        margin-right .08rem
        background-image url(../../assets/yuebao/rmb-qbao.png)
        background-size 100% 100%
    .prev-sy-amount
      width 1.04rem
      height .33rem
      margin-top .13rem
      font-size .2rem
      font-weight bold
      position relative
      .amount-ico-1
        display block
        width .47rem
        position absolute
        bottom .06rem
        right -.32rem
      .amount
        width 100%
        position absolute
        top 0
        left 0
        z-index 1
      .back
        width 100%
        height .24rem
        background-image url(../../assets/yuebao/yueb-amount-shadow.png)
        background-size 100% 100%
        position absolute
        bottom 0
        left 0
    .amount-wrap
      line-height .25rem
      margin .03rem 0
      background-size contain
      padding-left .2rem
      text-align left
      width 2.1rem
      background-repeat no-repeat
      &.total
        background-image url(../../assets/yuebao/edu--back1.png)
      &.confirm 
        background-image url(../../assets/yuebao/edu--back2.png)
      &.w-confirm
        background-image url(../../assets/yuebao/edu--back3.png)
      span
        color red
  .leiji-sy
    width 100%
    height .64rem
    color #592C0B
    font-size .12rem
    padding .07rem .46rem 0
    justify-content space-around
    background-image url(../../assets/yuebao/yuebao-shouyi-bg.png)
    background-size 100% 100%
    .amount
      font-size .16rem
      margin-top .04rem
      &.lj
        color #06C140
      &.ll
        color red
      &.zrsy
        color #945603
    
  .t-btn
    width .6rem
  .yb-background
    width 2.8rem
    height .25rem
    margin 0 .2rem
    align-items center
    background-image url(../../assets/yuebao/yuebao-bg.png)
    background-size 100% 100%
    &.hide
      visibility hidden
      .yubao-des
        display none
    .help-laba
      width .22rem
    .yubao-des
      width 2.5rem
      margin-left .1rem
      font-size .12rem
      overflow hidden
      .ybd-inner
        flex-shrink 0
</style>