<template>
  <div class="amount" :class="{yeb:isYeb}" @click="amountClick">
    <RechargeCenter :isShow="isShowRecharge" />
    <div class="coin-ico" :class="{yeb:isYeb}"></div>
    <div class="amount-v">
      {{ getShowValue() }}
    </div>
    <div class="refresh-ico" v-if="!isYeb"
      :class="{refreshing:userInfoRefreshing}"
      @click="refresh_amount">
    </div>
    <div @click="rechargeClick" class="add-p-ico" v-if="!isYeb"></div>
    <div class="amount-pop-msg" v-show="popsShow" @click.stop="popsShow = 0">
      <div class="pop-back" :class="{vip:isVip}" @click.stop="">
        <div class="m-row" @click="goDetailsClick(0)" v-show="userInfoLocal.recharge_promo * 1">
          <img class="lock-ico" src="../../assets/home/top/lock-ico.png" />
          充值优惠待解锁:{{userInfoLocal.frozen_recharge}}
          <img class="go-details" src="../../assets/home/top/go-details.png" />
        </div>
        <div class="m-row" @click="goDetailsClick(1)">
          <img class="lock-ico" src="../../assets/home/top/lock-ico.png" />
          推广奖励待解锁:{{userInfoLocal.frozen_agent}}
          <img class="go-details" src="../../assets/home/top/go-details.png" />
        </div>
        <img class="pop-back-triangle" src="../../assets/home/top/pop-back-triangle.png" />
      </div>
    </div>
  </div>
</template>
<script>
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import UserInfo from '../../common/UserInfo.js'
import RechargeCenter from '../recharge/RechargeCenter.vue'
import { mapGetters, mapMutations } from 'vuex'

export default{
  props:{
    isYeb:Number,
    amount:Number|String,
    frozenValue:Number|String,
    showRecharge:Number,
    isRefresAmount:Number,
    isVip:Number,
  },
  data(){
    return {
      frozenPrize:'',
      userCoin:'',
      userInfoRefreshing:false,
      isShowRecharge:0,
      yebDetails:{},
      getUserInfoTime:0,
      popsShow:0,
      userInfoLocal:{},
    }
  },
  mounted(){
    this.getYebData();
    this.soketSub();
  },
  methods:{
    ...mapMutations(['popMsg']),
    goDetailsClick(type){
      this.$emit('goDetails',type);
      this.popsShow = 0;
    },
    amountClick(){
      if(this.isYeb){
        this.popsShow = Math.random();
        UserInfo.getUserInfoData(data => this.userInfoLocal = data)
      }
    },
    soketSub(){
      const userInfo = Tool.getLocalUserinfo();
      if(userInfo && userInfo.hashid && this.$socket && this.$socket.private){
        this.$socket.private('user.'+userInfo.hashid).listen('.money', (e)=>{
          Tool.removeLocalYeb()
          Tool.removeLocalUserInfo()
          this.setUserCoin(e.money)
          this.yebDetails.balance = e.yeb_balance
        })
      }
    },
    getShowValue(){
      if(this.isYeb){
        return this.frozenPrize;
        // if(this.yebDetails.balance){
        //   return (1*this.yebDetails.balance).toFixed(2)
        // }else{
        //   return '0.00'
        // }
      }else{
        return this.userCoin;
      }
    },
    rechargeClick(){
      UserInfo.getUserInfoData(data => {
        if(data.is_tourist){
          this.$emit('touristShow')
        }else{
          if(this.isYeb){
            this.$emit('yebClick')
          }else{
            this.isShowRecharge = Math.random();
          }
        }
      })
    },
    transferOutAllAfter(){
      clearTimeout(this.getUserInfoTime)
      this.getUserInfoTime = setTimeout(() => {
        UserInfo.getUserInfoData((userInfo) => {
          if(userInfo){
            this.setUserCoin(userInfo.coin)
            this.setFrozenPrize(userInfo.frozen_prize)
          }
          this.userInfoRefreshing = false;
        },'update')
      }, 1000);
    },
    refresh_amount(){
      if(this.isYeb)return;
      UserInfo.getUserInfoData(data => {
        if(data.is_tourist){
          this.$emit('touristShow')
        }else{
          this.userInfoRefreshing = true;
          Request.post('/platform/transfer_out_all').then(() => {
            this.transferOutAllAfter();
          }).catch(() => {
            this.transferOutAllAfter();
          })
        }
      })
    },
    getYebData(isRefresh,callback){
      let localYeb = Tool.getLocalYeb();
      if(localYeb && !isRefresh){
        this.yebDetails = localYeb;
        if(callback)callback();
        return;
      }
      Tool.removeLocalYeb();
      Request.post('/yeb/index').then(res => {
        this.yebDetails = res.data.data;
        Tool.setLocalYeb(res.data.data);
        if(callback)callback();
      }).catch(() => {
        if(callback)callback();
      });
    },
    //callback刷新余额时传递
    getUserInfo(callback){
      let token = Tool.getApiToken();
      if(!token){
        Request.userOutted(this)
        if(callback) callback();
        return;
      }
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.setUserCoin(res.data.data.coin)
          this.setFrozenPrize(res.data.data.frozen_prize)
          Tool.setLocalUserinfo(res.data.data);
          if(callback) callback();
        }
      });
    },
    setUserCoin(v){
      this.userCoin = (v*1).toFixed(2);
    },
    setFrozenPrize(v){
      this.frozenPrize = (v*1).toFixed(2);
    },
  },
  watch:{
    isRefresAmount(n){
      if(n){
        this.refresh_amount();
      }
    },
    isUpdateAmountAndYeb(){
      if(this.isYeb){
        this.getYebData();
      }else{
        if(!this.userInfoRefreshing){
          this.userInfoRefreshing = true;
          this.getUserInfo(() => {
            this.userInfoRefreshing = false;
          });
        }
      }
    },
    isPopRecharge(n){
      if(!this.isYeb){
        this.isShowRecharge = Math.random();
      }
    },
    frozenValue(n){
      this.setFrozenPrize(n)
    },
    amount(n){
      this.setUserCoin(n)
    },
    showRecharge(n){
      if(n){
        if(!this.isYeb){
          this.isShowRecharge = Math.random();
        }
      }
    },
    isUpdateUserCoin(n){
      if(n){
        if(!this.isYeb){
          Tool.removeLocalUserInfo();
          this.userInfoRefreshing = true;
          this.getUserInfo(() => {
            this.userInfoRefreshing = false;
          });
        }
      }
    }
  },
  computed:{
    ...mapGetters({
      isPopRecharge:'getIsPopRecharge',
      isUpdateAmountAndYeb:'getUpdateAmountAndYeb',
      isUpdateUserCoin:'getIsUpdateUserCoin'
    }),
  },
  components:{
    RechargeCenter
  }
}
</script>

<style scoped lang="stylus">
@keyframes loading_btn {
  from{
    transform rotateZ(1deg)
  }
  to{
    transform rotateZ(360deg)
  }
}
.amount
  width 1.4rem
  height .44rem
  margin-left .12rem
  display flex
  background-image url('../../assets/home/top/amount_bg.png')
  background-size 100% 87%
  background-repeat no-repeat
  background-position-y .03rem
  border-top 1px solid transparent
  &.yeb
    width 1.08rem
  .amount-pop-msg
    width 100%
    height 100%
    position fixed
    top 0
    left 0
    z-index 5
    background-color rgba(0,0,0,.25)
    .pop-back
      width 2.1rem
      height .94rem
      position absolute
      left 2.66rem
      top .46rem
      &.vip
        left 3.11rem
      .pop-back-triangle
        width .15rem
        position absolute
        top 0
        left 50%
        transform translateX(-50%)
      .m-row
        display flex
        align-items center
        width 100%
        height .42rem
        position relative
        font-size .14rem
        font-weight bold
        background-image url(../../assets/home/top/item-back.png)
        background-size 100% 100%
        border-radius 0 0 .04rem .04rem
        &:first-child
          margin-top .09rem
          border-radius .04rem .04rem 0 0
        .lock-ico
          width .21rem
          margin 0 .1rem
        .go-details
          position absolute
          width .36rem
          right .04rem
  .coin-ico
    width .25rem
    height .3rem
    margin-top .1rem
    background-image url('../../assets/home/top/species_ico.png')
    background-size cover
    &.yeb
      width .21rem
      height .22rem
      margin-left .04rem
      margin-top .09rem
      background-image url('../../assets/home/top/receive_coins_ico.png')
  .amount-v
    width .7rem
    height .3rem
    text-align center
    overflow hidden
    font-size .16rem
    margin-top .13rem
    color #F0F0B7
  .refresh-ico  
    width .24rem
    height .24rem
    margin-top .09rem
    background-image url('../../assets/home/top/amount_refresh_icon.png')
    background-size cover
    &.refreshing
      animation loading_btn 1s infinite linear
  .add-p-ico
    width .26rem
    height .26rem
    margin-top .09rem
    background-image url('../../assets/home/top/top_add_ico.png')
    background-size cover
    transition transform .2s
    &:active
      transform scale(.9)
</style>
