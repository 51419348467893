export default {
  signUp:{
    labelTelephone:'手机号码',
    telPlaceholder:'请输入手机号',
    smsCode:'短信验证码',
    smsPlaceholder:'请输入短信验证码',
    smsCodeCountingdown:'7d7秒后可重发',
    inputPwd:'输入密码',
    pwdPlaceholder:'请设置6-20位字符',
    inputConfirmPwd:'确定密码',
    confirmPwdPlaceholder:'请在此输入确定密码',
    inviteCode:'邀请码',
    mobileError:'请输入正确的手机号',
    picValidCode:'验证码',
    picVCodeHolder:'请输入图形验证码'
  },
  userCenter:{
    menu:{
      userMessage:'个人信息',
      levelEquity:'等级特权',
      vipDetails:'VIP详情',
      amountDetails:'资金明细',
      betQuery:'注单查询',
      userReport:'个人报表',
    },
    info:{
      nickname:'昵称',
      gameid:'游戏ID',
      gender:'性别',
      music:'音乐',
      musicOn:'开',
      musicOff:'关',
      labelTelephone:'手机号',
      bankAccount:'银行卡',
      alipay:'支付宝',
      wallet:'数字钱包',
      genderMale:'男',
      genderFemale:'女',
      telPlaceholder:'请绑定手机号',
      bankPlaceholder:'请绑定银行卡',
      alipayPlaceholder:'请绑定支付宝',
      bound:'已绑定',
      unbound:'未绑定',
      bind:'绑定',
      quitConfirm:'确定退出当前账号？',
      nicknameInputLabel:'请输入昵称',
    },
    vip:{
      subDesc:'还差',
      subDesc1:'打码即可升级到',
      vipDescription:'尊敬的VIP7d7会员，您可以享受以下权益',
      currLevelReceive:'当前等级可领取',
      promoteAmount:'晋级彩金',
      weekAmount:'周礼金',
      monthAmount:'月礼金',
      extraTopupOffer:'额外充值优惠',
      amountUnit:'元',
      extraUnit:'%',
    },
    amountDetails:{
      filterDate:'时间',
      filterToday:'今天',
      filterYesterdy:'昨天',
      dateChoice:'选择时间',
      filterType:'类型',
      typeChoice:'请选择类型',
      filterAllType:'全部类型',
      tbDatetime:'时间',
      tbTurnoverAmount:'流水金额',
      afterAmount:'之后金额',
      tbIOtype:'收支类型',
      amounttype:'资金类型',
      remargik:'备注'
    },
    bet:{
      tabQipai:'棋牌游戏',
      tabBuyu:'捕鱼游戏',
      tabDianzi:'电子游艺',
      tabRealMan:'真人视讯',
      tabCaipiao:'彩票游戏',
      tabSport:'体育赛事',
      platform:'平台',
      platformChoice:'平台选择',
      allPlatform:'全部平台',
      game:'游戏',
      gameChoice:'选择游戏',
      gameAll:'全部游戏',
      tbStartTime:'开始时间',
      tbEndTime:'结束时间',
      juHao:'局号',
      gameType:'游戏类别',
      activeBet:'有效投注',
      windLostAmount:'输赢金额',
      summation:'合计'
    }
  },
  recharge:{
    zxsf:'专享闪付',
    quickRecharge:'快捷充值',
    lineChoicer:'线路选择',
    receiveBank:'收款银行',
    receiveName:'收款姓名',
    receiveAccount:'收款账号',
    reAmount:'充值金额',
    amountInputTip:'请输入最小7min7元，最大7max7元',
    payMan:'付款人',
    payManInputTip:'请输入付款人姓名',
  },
  yuebao:{
    totalAmount:'总金额',
    confirmAmount:'已确认金额',
    beforeConfirm:'待确认金额',
    lastRoundEarnings:'上轮收益（元）',
    cumulativeIncome:'累计收益',
    interestRatePerRound:'每轮利率（万元）',
    dailyIncome:'每日收益（元）',
  },
  yebDetail:{
    all:'全部',
    income:'收入',
    transferIn:'转入',
    transferOut:'转出',
  },
  yebInOut:{
    yebBalance:'余额宝余额（元）',
    accountBalance:'账户余额（元）',
    transferOLabel:'转出金额',
    transferILabel:'转入金额',
    transferOPlaceholder:'请输入转出金额',
    transferIPlaceholder:'请输入转入金额',
    firstIncomeDes:'预计第一笔收益到账时间：',
    inRule1:'转入金额必须≥1',
    inRule2:'无转入次数限制;',
    inRule3:'转入金额可累加，转入余额宝的金额按照活存获取流程，以万元每日利率进行计算。',
    outRule:'转出金额大于余额宝内金额，则无法转出。',
    transferInSuccess:'转入成功！',
    securitDes:'为了您的账户安全请输入您的',
    yuebaoPwd:"余额宝交易密码",
    yebTranserPwd:'交易密码',
    pwdPlaceholder:'请输入6位数字交易密码',
    commonProblem:'常见问题',
    customerOnLine:'在线客服',
    yebEmptyDes:'请输入交易密码',
    transferOutEnptyDes:'请输入转出金额',
    transferInEnptyDes:'请输入转入金额',
  },
  nodata:'暂无数据',
};
