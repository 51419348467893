<template>
  <div class="tuiguang-bg flex" :class="{show:localShow}" 
    @touchmove.stop="" @touchstart.stop="">
    <DirectMember :show="isShowDirMember" />
    <LingquRecord :show="isShowLingquJilu" />
    <div class="tg-big-bg" :class="{show:isShowBigBg}">
      <img class="big-bg-close" @click="isShowBigBg = 0"
          src="../../assets/tuiguang/tuiguang-close.png" alt="">
      <div class="big-content flex">
        <div class="qrcode-wrap">
          <img v-if="agentYeildInfo.qr_code" :src="agentYeildInfo.qr_code"/>
        </div>
        <div class="o-wrapper-des">
          <div class="home-url">
            官方网址：<span>{{agentYeildInfo.url}}</span>
          </div>
          <div class="home-u-des">
            如遇扫码访问失败的情况，请<br/>
            尝试使用“<span class="browser">浏览器</span>”或者‘<span class="er-code">二<br>
            维码扫APP</span>’重新扫码。
          </div>
        </div>
        <div class="flex">
          <img @click="shareWechatClick" class="share-wechat-btn ease-btn-effe" 
            src="../../assets/tuiguang/tuiguang-wechat-btn.png" alt="">
          <img @click="shareWechatClick" class="share-wechat-btn ease-btn-effe" 
            src="../../assets/tuiguang/tuiguang-pyq-btn.png" alt="">
        </div>
      </div>
    </div>
    <div class="cont-container" :class="{show:beforeShow}">
      <img class="title" src="../../assets/tuiguang/tuiguang-h-title.png" alt="">
      <img class="close" src="../../assets/tuiguang/tuiguang-close.png" alt=""
        @click="tuiguangCloseClick">
      <div class="head-w flex">
        <div v-for="hItem of headList"
          @click="headClick(hItem.id)" 
          :class="{select:headItemId == hItem.id}">
          {{ hItem.title }}
        </div>
      </div>
      <div class="my-tuiguang flex" v-if="headItemId == 0">
        <div>
          <div class="flex info-wrap">
            <div class="fanli-info p-relative">
              <div>
                昨日总返利：
                <span>
                  {{agentYeildInfo.yes_total?agentYeildInfo.yes_total:0}}
                </span>
              </div>
              <div>
                昨日直属会员返利：
                <span>
                  {{agentYeildInfo.yes_direct?agentYeildInfo.yes_direct:0}}
                </span>
              </div>
              <div>
                昨日其他返利：
                <span>
                  {{agentYeildInfo.yes_indirect?agentYeildInfo.yes_indirect:0}}
                </span>
              </div>
              <div class="agent-info-desc" :class="{show:isShowAgentInfoDesc}">
                {{agentYeildInfo.desc}}
              </div>
            </div>
            <div class="member-info">
              <div>
                直属会员：<span>
                  {{agentYeildInfo.sub_count?agentYeildInfo.sub_count:0}}人
                </span>
              </div>
              <div>
                其他会员：<span>
                  {{agentYeildInfo.indirect_sub_count?agentYeildInfo.indirect_sub_count:0}}人
                </span>
              </div>
            </div>
          </div>
          <div class="history p-relative">
            历史总奖励：<span>{{agentYeildInfo.history?agentYeildInfo.history:0}}</span>
            &nbsp;&nbsp;
            可领取奖励：<span>{{agentYeildInfo.apply?agentYeildInfo.apply:0}}</span>
            <img class="bonus-alert-icon" src="../../assets/tuiguang/bonus-alert-icon.png" @click="isShowAgentInfoDesc = isShowAgentInfoDesc?0:1" />
          </div>
        </div>
        <div class="qrcode-wrapper flex">
          <img class="border-line"
            src="../../assets/tuiguang/tuiguang-border-line.png" />
          <div class="qrcode-d-title">点击图片预览大图</div>
          <div class="qrcode" @click="isShowBigBg=1">
            <img v-if="agentYeildInfo.qr_code" :src="urlPrevHandle(agentYeildInfo.qr_code)"/>
          </div>
          <input class="promo-input" ref="promoInput" readonly v-model="shareLink" />
          <div @click="copyBtnClick" class="copy-btn ease-btn-effe flex">
            复制链接
          </div>
        </div>
        <img class="liji-get-bonus ease-btn-effe" v-if="agentYeildInfo.apply"
            src="../../assets/tuiguang/liji-get-bonusbtn.png"
            @click="linquBonusClick">
        <img class="liji-get-bonus" v-else
            src="../../assets/tuiguang/liji-get-bonus.png">
        <img @click="gotoLinquRecord"
          class="go-btn" src="../../assets/tuiguang/gobtn-get-record-btn.png" />
        <img  @click="gotoDirMember"
          class="go-btn d-member" src="../../assets/tuiguang/gobtn-dir-member.png" />
      </div>
      <div class="my-tuiguang recommend" v-if="headItemId == 3">
        <div class="flex f-wrapper">
          <div class="re-t-head p-relative">
            总金额：<span class="amount-wrap">{{earningInfo.total_amount}}</span>
            已领取：<span class="amount-wrap got">{{earningInfo.claimed_amount}}</span>
            <img class="receive-rule-btn" @click="receiveRuleBtnClick" src="../../assets/tuiguang/receive-rule-btn.png" />
          </div>
          <div>
            <div class="r-data-row r-h">
              <div>会员</div>
              <div>达到等级</div>
              <div>解锁奖励</div>
            </div>
            <div class="scroller-data-t">
              <div class="r-data-row" v-for="item of earningList">
                <div>{{item.username}}</div>
                <div>VIP{{item.grade}}</div>
                <div>{{item.amount}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="unlocked p-relative">
          <div>已解锁：<span>{{earningInfo.unlocked_amount}}</span></div>
          <div class="received-btn" :class="{disabled:!earningInfo.unlocked_amount || earningInfo.unlocked_amount < 1}" @click="receiveBonusClick">
          </div>
        </div>
      </div>
      <div class="tuiguang-j-cheng" v-if="headItemId == 1">
        <img class="pic" src="../../assets/tuiguang/tuiguang-des-txt.png" />
      </div>
      <div class="profit-list-wrapper" v-if="headItemId == 2">
        <div class="p-l-row head flex">
          <div>排名</div>
          <div>昵称</div>
          <div>获利金额</div>
        </div>
        <div class="p-l-wscroller">          
          <div class="p-l-row row flex" v-for="(agent,i) of agentYieldList">
            <div class="num-ico flex">
              <img v-if="i == 0" src="../../assets/tuiguang/list-ico1.png" />
              <img v-else-if="i == 1" src="../../assets/tuiguang/list-ico2.png" />
              <img v-else-if="i == 2" src="../../assets/tuiguang/list-ico3.png" />
              <img v-else src="../../assets/tuiguang/list-ico4.png" />
              <div class="num-wrap" v-if="i > 2">
                {{ i + 1 }}
              </div>
            </div>
            <div>{{ agent.nickname }}</div>
            <div>{{agent.amount}}</div>
          </div>
        </div>
      </div>
    </div>

    <PopWidget width="5.66rem" :isFull="1" :isShow="receiveRuleShow">
      <template v-slot:title>
        <img class="l-t-title" src="../../assets/tuiguang/bonus-rule-title.png" />
      </template>
      <template v-slot:content>
        <div class="rule-main-wrapper">
          <div class="rule-img-des flex">
            <div class="des-r-item">
              <div class="d-r-i-title">分享好友</div>
              <div>将您的推广链接或二维码</div>
              <div>分享给好友</div>
            </div>
            <div class="des-r-item p-relative">
              <img class="jt" src="../../assets/tuiguang/jiantou.png" />
              <div class="d-r-i-title">获得{{rewardSum}}元奖励</div>
              <div>奖励将会暂时锁定</div>
            </div>
            <div class="des-r-item p-relative">
              <img class="jt" src="../../assets/tuiguang/jiantou.png" />
              <div class="d-r-i-title">升级解锁</div>
              <div>每次当您推荐的好友升级时</div>
              <div>将会解锁您的奖励</div>
            </div>
          </div>
          <div class="flex row head">
            <div class="f-r-ceil">等级</div>
            <div class="f-r-ceil">需要打码</div>
            <div class="f-r-ceil">解锁金额</div>
          </div>
          <div class="f-r-d-scroll">
            <div class="flex row" v-for="item of earningRuleList">
              <div class="f-r-ceil">VIP{{item.grade}}</div>
              <div class="f-r-ceil">{{item.exp}}</div>
              <div class="f-r-ceil">{{item.reward}}</div>
            </div>
          </div>
        </div>
      </template>
    </PopWidget>

  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import DirectMember from './DirectMember.vue'
import LingquRecord from './LingquRecord.vue'
import PopWidget from '../common/PopWidget.vue'

export default{
  props:{
    show:Number,
    tabcode:String|Number,
  },
  data(){
    return{
      localShow:0,
      beforeShow:0,
      isShowBigBg:0,
      headList:[
        {title:'我的推广',id:0},
        {title:'推荐奖励',id:3},
        {title:'推广教程',id:1},
        {title:'返利榜单',id:2},
      ],
      headItemId:0,
      agentYeildInfo:{},
      agentYieldList:[],
      isShowLingquJilu:0,
      isShowDirMember:0,
      earningInfo:{},
      earningList:[],
      earningRuleList:[],
      receiveRuleShow:0,
      rewardSum:0,
      isShowAgentInfoDesc:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg','alert','showLoading','closeLoading']),
    urlPrevHandle(url){
      return Tool.urlProtocalHandle(url);
    },
    receiveRuleBtnClick(){
      if(!this.rewardSum) return
      this.receiveRuleShow = Math.random()
    },
    receiveBonusClick(){
      if(this.earningInfo.unlocked_amount > 0){
        this.getClaimEarnings();
      }
    },
    gotoLinquRecord(){
      this.isShowLingquJilu = Math.random();
    },
    gotoDirMember(){
      this.isShowDirMember = Math.random();
    },
    linquBonusClick(){
      Request.post('/agent/get_yield').then(res => {
        if(res.data.code == 1){
          this.popMsg(res.data.msg);
        }
      }).catch(error => {
        this.popMsg(error.response.data.msg);
      })
    },
    copyBtnClick(){
      Tool.copy(this.$refs.promoInput)
      this.popMsg('复制成功!')
    },
    shareWechatClick(){
      Tool.copy(this.$refs.promoInput)
      this.alert('复制成功，如微信未能启动，请手动打开微信。')
      setTimeout(() => {
        Tool.openWechat()
      },200)
    },
    getAgentInfo(){
      let cach = null // Tool.getAgentYieldInfo();
      if(cach){
        this.agentYeildInfo = cach;
        return;
      }
      Request.post('/agent/yield_info').then(res => {
        this.agentYeildInfo = res.data.data;
        Tool.setAgentYieldInfo(res.data.data);
      })
    },
    headClick(id){
      this.headItemId = id;
      if(id == 2){
        this.getAgentYieldList()
      }
      if(id == 3){
        this.getAgentEarningInfo()
      }
    },
    getAgentYieldList(){
      let p = {page:1,}
      Request.post('/agent/yield_list',p).then(res => {
        if(res.data && res.data.data && res.data.data.data){
          this.agentYieldList = res.data.data.data;
        }
      })
    },
    getAgentEarningInfo(refresh){
      let dataHandle = (data) => {
        this.earningInfo = data;
        this.earningList = this.earningInfo.list;
        this.earningRuleList = this.earningInfo.rules;

        let ruleSum = 0;
        this.earningRuleList.forEach(rule => {
          ruleSum += rule.reward;
        });
        this.rewardSum = ruleSum;
      }
      if(process.env.NODE_ENV == 'development' && !refresh){
        let localData = Tool.getLocalEarningInfo();
        if(localData){
          dataHandle(localData)
          return;
        }
      }
      this.showLoading()
      Request.post('/agent/earningInfo').then(res => {
        if(res.data && res.data.data){
          Tool.setLocalEarningInfo(res.data.data);
          dataHandle(res.data.data)
        }
      }).finally(() => {
        this.closeLoading()
      })
    },
    getClaimEarnings(){
      this.showLoading()
      Request.post('/agent/claimEarnings').then(res => {
        if(res.data){
          if(res.data.code == 0){
            this.popMsg('领取成功!')
            this.getAgentEarningInfo('refresh')
          }else{
            this.popMsg(res.data.msg);
          }
        }
      }).catch(error => {
        this.popMsg(error.response.data.msg);
      }).finally(() => {
        this.closeLoading()
      })
    },
    tuiguangCloseClick(){
      this.beforeShow = 0;
      this.localShow = 0
      this.headItemId = 0
    }
  },
  watch:{
    tabcode(n){
      let code = n;
      if(n.split){
        code = n.split('-')[0];
      }

      if(code == 7){
        this.headClick(2)
      }
      else if(code == 3){
        this.headClick(3)
      }
      else{
        this.headClick(0)
      }
    },
    show(n){
      if(n){
        this.localShow = n;
        setTimeout(() => {
          this.beforeShow = n
        }),
        this.getAgentInfo();
      }
    }
  },
  computed:{
    shareLink(){
      return this.agentYeildInfo.promo_url?
        this.agentYeildInfo.promo_url:this.agentYeildInfo.url;
    }
  },
  components:{
    DirectMember,
    LingquRecord,
    PopWidget,
  }
}
</script>
<style scoped lang="stylus">
.tuiguang-bg
  width 100%
  height 100%
  top 0
  left 0
  position absolute
  background-color rgba(0,0,0,.25)
  z-index 3
  display none
  .rule-main-wrapper
    height 2.32rem
    background-color #EEE1C5
    font-size .12rem
    .f-r-d-scroll
      width 100%
      height 1.14rem
      margin-left .01rem
      overflow-y auto
      padding-bottom .12rem
    .row
      width 5rem
      margin auto
      justify-content space-between
      color #55483A
      line-height .24rem
      &.head
        color white
        line-height .26rem
      &:nth-child(even)
        background-color #BD9E77
      &:nth-child(odd)
        background-color #DBBF98
      .f-r-ceil
        width 33.33%
  .rule-img-des
    width 5rem
    height .91rem
    margin auto
    justify-content space-between
    align-items center
    color #55483A
    .des-r-item
      width 1.47rem
      height .8rem
      font-size .12rem
      border-radius .06rem
      background-image linear-gradient(#FFF8DE,#FDF2D4,#F6EBC6,#EFE1B4,#EADBA9)
      padding-top .12rem
      .jt
        position absolute
        width .30rem
        left -.27rem
        top 50%
        transform translateY(-50%)
      .d-r-i-title
        color #9D6425
        font-size .14rem
        font-weight bold
  .tg-big-bg
    position absolute
    z-index 2
    top 0
    left 0
    width 100%
    height 100%
    background-image url(../../assets/tuiguang/tuiguang-big-bg.png)
    background-size 100% 100%
    display none
    &.show
      display block
    .big-bg-close
      width .38rem
      position absolute
      right .52rem
      top .12rem
    .big-content
      position absolute
      bottom .64rem
      right .6rem
      width 4.6rem
      height .93rem
      padding-left .1rem
      align-items center
      
      .share-wechat-btn
        width .5rem
        margin 0 0 0 .1rem
      .o-wrapper-des
        margin-left .1rem
      .home-url
        color #DEAD32
      .home-u-des
        margin-top .05rem
        color #ffffff
        .browser
          color #1EA7EA
        .er-code
          color #00CD44
      .qrcode-wrap
        padding .04rem
        border-radius .04rem
        background #FFCD95
        img
          width .66rem
  &.show
    display flex
  .cont-container
    width 4.72rem
    height 2.74rem
    margin auto
    background-image url(../../assets/tuiguang/tuiguang-background.png)
    background-size 100% 100%
    position relative
    .title
      width 3.44rem
      position absolute
      top -.4rem
      left 50%
      transform translateX(-50%)
    .close
      width .3rem
      position absolute
      top 0
      right -.46rem
    .head-w
      width 4rem
      height .28rem
      border-radius .3rem
      background-color #3E0F00
      border 1px solid #76432D
      top .48rem
      position absolute
      left .36rem
      justify-content space-around
      align-items center
      & > div
        width 25%
        height 78%
        font-size .12rem
        display flex
        justify-content center
        align-items center
        border-radius .3rem
        color #FFDBC0
        &.select
          background-color #FFDBC0
          color #512914
    .tuiguang-j-cheng
      width 4rem
      height 1.48rem
      position absolute
      top .85rem
      left .35rem
      .pic
        width 4rem
    .profit-list-wrapper
      width: 3.77rem;
      height: 1.64rem
      padding-bottom: .02rem;
      position: absolute;
      top: .72rem
      left: .47rem;
      padding-top: .06rem;
      .p-l-wscroller
        width 100%
        height 1.3rem
        overflow-y: auto;
      .p-l-row
        width 100%
        height .26rem
        align-items center
        justify-content flex-start
        font-size .12rem
        & > div
          width 33.3%
          text-align center
        .num-ico
          justify-content center
          align-items center
          position relative
          img
            width .16rem
          .num-wrap
            text-align center
            width 100%
            position absolute
        &.head
          color #EABE97
          background-color #995E45
        &.row
          color #FEDAC2
          &:nth-child(even)
            background #BE7F5D
          &:nth-child(odd)
            background #B47652
    .my-tuiguang
      padding .24rem 0 0 0
      position absolute
      top .68rem
      left .29rem
      width 3.82rem
      &.recommend
        top .78rem
        width 4.02rem
        padding 0 0 0 .12rem
        .re-t-head
          width 90%
          text-align center
          height .22rem
          line-height .22rem
          margin 0 auto
          font-size .12rem
          color white
          background-image linear-gradient(to right,#AE805B00,#AE805B 12%,#AE805B 88%,#AE805B00)
          span
            color #FFF100
          .amount-wrap
            display inline-block
            width .6rem
            text-align left
            margin-right .12rem
            &.got
              margin-right .7rem
          .receive-rule-btn
            width .88rem
            position absolute
            right -.23rem
            top 0
        .f-wrapper
          width 100%
          height 1.26rem
          flex-direction column
          justify-content space-around
      .scroller-data-t
        width 100%
        max-height .8rem
        min-height .5rem
        overflow-x hidden
        overflow-y auto
        padding-bottom .12rem
        border-radius 0 0 .06rem .06rem
      .unlocked
        width 100%
        text-align center
        color #FFF100
        font-size .12rem
        .received-btn
          width 1.18rem
          height .36rem
          margin .05rem auto 0 auto
          background-image url(../../assets/tuiguang/liji-get-bonusbtn.png)
          background-size 100% 100%
          &.disabled
            background-image url(../../assets/tuiguang/liji-get-bonus.png)
      .r-data-row
        width 100%
        height .2rem
        display flex
        justify-content space-around
        align-items center
        color #74411B
        font-size .12rem
        &:nth-child(even)
          background-color #AE805B
        &:nth-child(odd)
          background-color #C39875
        &.r-h
          height .22rem
          background-color #AE805B
          color white
          border-radius .06rem .06rem 0 0
        & > div
          width 33.33%
          text-align center
      .qrcode-wrapper
        position relative
        padding-left .08rem
        margin-left .08rem
        font-size .12rem
        flex-direction column
        align-items center
        .border-line
          display block
          height 1.28rem
          position absolute
          top 0
          left 0
        .qrcode
          width .86rem
          height auto
          padding .06rem 0
          margin .05rem 0
          border-radius .04rem
          background linear-gradient(to right, #b17451, #db9b77);
          img
            width .7rem
            display block
            margin auto
        .promo-input
          width .74rem
          height .22rem
          background none
          position absolute
          bottom -.06rem
          left .2rem
          z-index 1
        .copy-btn
          width .74rem
          height .22rem
          justify-content center
          align-items center
          position absolute
          bottom -.06rem
          left .2rem
          color #FEE4CD
          background #935A3E
          border-radius .12rem
          z-index 1
        .qrcode-d-title
          color #622913
      .history
        display flex
        justify-content center
        align-items center
        margin-top .29rem
        text-align center
        width 2.7rem
        color #F7E8D6
        background-image url(../../assets/tuiguang/score-background.png)
        background-size 100% 100%
        font-size .12rem
        line-height .2rem
        span
          color #ff0000
        .bonus-alert-icon
          width .17rem
          margin-left .05rem
      .liji-get-bonus
        width 1.1rem
        position absolute
        bottom -.38rem
        left .8rem
      .go-btn
        width .6rem
        position absolute
        bottom -.43rem
        right 1.04rem
        &.d-member
          right .4rem
      .info-wrap
        height .81rem
        color #F2C4A8
        font-size .12rem
        margin-left .22rem
        &>div
          padding .05rem 0 0 .05rem
          &>div
            margin-bottom .1rem
      .fanli-info
        width 1.4rem
        height 100%
        border-radius .04rem
        background linear-gradient(to right, rgb(177,116,81) ,#DB9B77);
        z-index 1
        span
          color #F3C012
        .agent-info-desc
          position absolute
          width 2.02rem
          bottom -.2rem
          color #fbd2b8
          color red
          left .04rem
          margin-bottom 0
          visibility hidden
          &.show
            visibility visible
      .member-info
        width 1rem
        height 100%
        margin-left .08rem
        border-radius .04rem
        overflow hidden
        background linear-gradient(to right, rgb(177,116,81) ,#DB9B77);
        span
          color #05D202
</style>