<template>
  <div class="container pre">
    <Confirm :show="isShowAddHomeConfirm" :bgNoHide="1"
      style="z-index:6;" width='2.7rem' @confirm="confirmCall" @cancelConfirm="cancelConfirm">
      <template v-slot:confirm-content>
        <div class="confirm-wrapper">
          将此网站添加到桌面以提供更好的体验
        </div>
      </template>
    </Confirm>

    <div class="add-home-touris" :class="{horizontal:!isShuPing}" v-show="showTouris">
      <div class="scroll-wrapper">
        <div class="scroll-close-btn" @click="tourisCloseCall"></div>
        <div class="ios scroller" v-show="isMacIphone">
          <div class="t-title">
            1、如下图所示，在Safari中点击分享按钮
          </div>
          <img src="../../assets/preloading/step1.jpg">
          <div class="t-title">
            2、如下图所示，滚动到底部点击'添加到主屏幕'
          </div>
          <img src="../../assets/preloading/step2.jpg">
          <div class="t-title">
            3、点击'添加'
          </div>
          <img src="../../assets/preloading/step3.jpg">
        </div>
        <div class="android scroller" v-show="!isMacIphone">
          <div style="padding:.2rem;font-size:.2rem">
            请搜索对应机型以及浏览器的添加到桌面的方式
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from './Confirm.vue'

export default {
  props:{
    isShuPing:Boolean,
  },
  data(){
    return{
      isShowAddHomeConfirm:0,
      showTouris:0,
      isConfirmCall:0,
      isMacIphone:0,
    }
  },
  mounted(){
    this.getIsMacIphone();
  },
  methods:{
    confirmCall(){
      this.isConfirmCall = 1;
      this.isShowAddHomeConfirm = 0;
      this.showTouris = Math.random()
    },
    cancelConfirm(){
      if(this.isConfirmCall){
        this.isConfirmCall = 0;
      }else{
        this.$emit('preloadClose')
      }
    },
    tourisCloseCall(){
      this.showTouris = 0
      this.$emit('preloadClose')
    },
    getIsMacIphone(){
      this.isMacIphone = 0;
      let uaStr = navigator.userAgent.toLowerCase();
      ['iphone','mac os','ios'].forEach(item => {
        if(uaStr.indexOf(item) > -1){
          this.isMacIphone = 1;
        }
      });

    }
  },
  computed:{
    ...mapGetters({
      preloaded:'getPreloaded'
    })
  },
  watch:{
    preloaded(n){
      if(n){
        let pageLoaded = sessionStorage.getItem('already-preloaded')
        if(!pageLoaded){
          this.isShowAddHomeConfirm = Math.random();
          sessionStorage.setItem('already-preloaded',1);
        }else{
          this.$emit('preloadClose')
        }

        // 隐藏进度条
        let time = 0;
        if(pageLoaded){
          time = 0;
        }
        let precessingEle = document.getElementById('preloadingWrap');
        window.customeDict.preloadingEnded = 1
        window.customeDict.preloading('100%');
        setTimeout(() => {
          if(precessingEle){
            precessingEle.style.display = 'none'
          }
        },time)

      }
    }
  },
  components:{
    Confirm
  }
}
</script>

<style lang="stylus" scoped>
.container
  width 100%
  height 100%
  top 0
  left 0
  position absolute
  z-index 2000
  display flex
  .add-home-touris
    width 100%
    height 100%
    top 0
    left 0
    position absolute
    z-index 1
    background white
    &.horizontal
      width 100vh
      height 100vw
      transform-origin 0 0
      transform rotateZ(-90deg) translateX(-100%)
    .scroll-wrapper
      width 90%
      height 100%
      margin auto
      position relative
      .scroll-close-btn
        width .4rem
        height .4rem
        position absolute
        right -.19rem
        z-index 1
        top 0
        background-image url(../../assets/home/tiyanjin-close.png)
        background-size 100% 100%
    .scroller
      width 100%
      height 100%
      overflow-y auto
      overflow-x hidden
      padding-bottom .4rem
      .t-title
        padding .2rem 0 .1rem 0 
        font-size .16rem
      img
        display block
        width 90%
        margin-left .3rem
</style>