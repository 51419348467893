<template>
  <div class="pop-message-container" 
    :class="[language,localShow?'show':'',disBlock?'display-b':'']">
    {{msg}}
  </div>
</template>
<script>
export default {
  props:{
    msg:String,
    show:Number,
    time:Number
  },
  data(){
    return {
      localShow:0,
      disBlock:0,
      timeout2:0,
      timeout3:0,
    }
  },
  watch:{
    show(n,o){
      const _this = this;
      let hideTime = this.time ? this.time : 2500;
      this.disBlock = 1;
      
      clearTimeout(_this.timeout2)
      _this.timeout2 = setTimeout(() => {
        _this.localShow = 0;
        clearTimeout(_this.timeout3)
        _this.timeout3 = setTimeout(() => {
          _this.disBlock = 0;
        },333)
      },hideTime)

      _this.localShow = n;

    }
  },
  computed:{
    language(){
      return localStorage.getItem('language')
    }
  }
}
</script>
<style scoped lang="stylus">
.pop-message-container
  background-image url(../../assets/pop-background.png)
  background-size 100% 100%
  top 0
  left 50%
  transform translateX(-50%) scale(.0001)  translateY(0)
  position fixed
  padding .1rem .4rem
  max-width 100%
  min-width 4rem
  text-align center
  max-height 1rem
  font-size .12rem
  color white
  border-radius .05rem
  transition transform .3s
  z-index 500
  &.pt-BR
    background-image url(../../assets/pop-background-pt-BR.png)
  &.display-b
    display block
  &.show
    transform translateX(-50%) scale(1)  translateY(1.24rem)
</style>