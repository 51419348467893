<template>
  <PopWidget :isShow="isShowPopWidget" :style="style">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/geren/binding-wallet-info.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <div class="flex h row">
          <div class="platform">钱包平台</div>
          <div class="wallet-addr">钱包地址</div>
        </div>
        <div class="flex row" v-for="wallet of wallets">
          <div class="platform">
            {{ wallet.wallet_name }}
          </div>
          <input readonly v-model="wallet.address" class="wallet-addr" 
            :ref="`walletAddressEle_${wallet.id}`" />
          <img @click="copyClick(wallet.id)"
            class="copy-btn" src="../../assets/geren/copy-btn.png" />
        </div>
      </div>
      <img @click="confirmClick()"
        class="l-confirm-btn" src="../../assets/login/confirm-btn.png" />
    </template>
  </PopWidget>
</template>
<script>
import { mapMutations } from 'vuex'
import Tool from '../../common/Tool.js'
import PopWidget from '../common/PopWidget.vue'

export default {
  props:{
    style:Object,
    isShow:Number,
    wallets:{},
  },
  data(){
    return {
      isShowPopWidget:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    copyClick(walletId){
      let eleRkey = `walletAddressEle_${walletId}`;
      let eles = this.$refs[eleRkey];
      if(eles && eles.length){
        let copyText = eles[0];
        Tool.copy(copyText);
        this.popMsg('已复制!')
      }
    },
    confirmClick(){
      this.isShowPopWidget = 0
    }
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.l-t-title
  width 2.14rem
  margin-top -.04rem
.l-confirm-btn
  width .94rem
  margin-top .16rem
  transition transform .3s
  &:active
    transform scale(.8)
.l-c-w-i
  display flex
  justify-content center
  flex-direction column
  width 100%
  border-top 1px solid transparent
  font-size .12rem
  padding 0 .03rem
  .row
    padding .04rem 0;
  .h
    background-color #fde675
  .platform
    width 1.08rem
  .wallet-addr
    width 2.9rem
    background none
    border none
    color #a56615
  .copy-btn
    width .5rem
</style>