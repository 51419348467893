<template>
  <Confirm :show="show" style="z-index:4" @confirm="confirm">
    <template v-slot:confirm-content>
      <div class="content">
        <div class="inner">
          此次提现扣除<span class="xzfei">行政费{{xzFei}}元</span>，
          <span class="shouxu">手续费{{shouxuFei}}元</span>，
          <span class="youhui">优惠回收{{yhhshou}}元</span>，再投注
          <span class="bet">{{debtAmount}}元</span>，
          即可不被扣除行政费与优惠回收费!
        </div>
        <div class="yesorno">
          是否确认提交提现申请？
        </div>
      </div>
    </template>
  </Confirm>
</template>
<script>
import { mapMutations } from 'vuex'
import Confirm from '../common/Confirm.vue'
export default {
  props:{
    show:Number,
    dhAmount:Number,
    withdrawInfo:Object,
  },
  data(){
    return{
      shouxuFei:0,
      xzFei:0,
      yhhshou:0,
      debtAmount:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirm(){
      this.$emit('confirm')
    },
    getShouxufei(info){
      let result = 0;
      let {
        out_real_charge_rate,
        out_charge_upper_limit,
      } = info.chargeout_setting;
      out_real_charge_rate = out_real_charge_rate * 1;
      out_charge_upper_limit = out_charge_upper_limit * 1;
      let {recycle_admin,recycle_privilege,debt} = this.withdrawInfo;

      let aValue = out_real_charge_rate / 100 * this.dhAmount;
      if(out_charge_upper_limit > 0){
        if(aValue < out_charge_upper_limit){
          result = aValue;
        }else if(aValue > out_charge_upper_limit){
          result = out_charge_upper_limit;
        }
      }
      this.shouxuFei = result.toFixed(2);
      this.xzFei = recycle_admin;
      this.yhhshou = recycle_privilege;
      this.debtAmount = debt;
    },
  },
  watch:{
    show(n){
      if(n){
        if(this.withdrawInfo){
          this.getShouxufei(this.withdrawInfo);
        }
      }
    },
    withdrawInfo(info){
      if(info){
        this.getShouxufei(info);
      }
    }
  },
  components:{
    Confirm
  }
}
</script>
<style lang="stylus" scoped>
.content
  width 100%
  text-align center
  .inner
    color #675A52
    font-size .14rem
    width 80%
    margin .2rem auto 0 auto
    
    .xzfei
      color #0C58C6
    .youhui
      color #B602DD
    .shouxu
      color #0AA30A
    .bet
      color #F2082D
  .yesorno
    width 100%
    margin-top .2rem
    color #685954
    font-size .12rem
</style>