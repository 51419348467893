<template>
  <PopWidget :isShow="isShowPopWidget" @closed="closed"
    @hideClosed="hideHandle" :isFull="1">
    <template v-slot:title>
      <img class="l-t-title" src="../../assets/binding-title-a.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i">
        <div class="l-c-w-sc" v-html="localMsg">

        </div>
      </div>
    </template>
  </PopWidget>
  
</template>
<script>
import { mapMutations } from 'vuex';
import PopWidget from './PopWidget.vue'
export default{
  props:{
    msg:String,
  },
  data(){
    return {
      isShowPopWidget:0,
      localMsg:'',
    }
  },
  methods:{
    ...mapMutations(['msgAlertClose']),
    closed(){
      this.isShowPopWidget = 0;
    },
    hideHandle(){
      this.msgAlertClose()
    }
  },
  components:{
    PopWidget
  },
  watch:{
    msg(n){
      this.isShowPopWidget = Math.random();
      this.localMsg = n.split('-16888-')[0];
    }
  }
}
</script>

<style scoped lang="stylus">
.l-t-title
  width 2.14rem
  margin-top -.04rem
.l-c-w-i
  width 100%
  height 1.58rem
  font-size .14rem
  display flex
  justify-content center
  align-items center
  .l-c-w-sc
    width 100%
    max-height 100%
    padding .15rem .1rem .15rem .1rem
    overflow auto
</style>