<template>
  <PopWidget :isShow="isShowPopWidget" :isFull="1" style="z-index:4;" width="5rem">
    <template v-slot:title>
      <img class="l-t-title"
        src="../../assets/yuebao/sfyz-title.png" />
    </template>
    <template v-slot:content>
      <div class="yeb-io-wrap" :class="{isout:isOut}">
        <div class="row-yeb flex">
          <img class="yeb-io-icode" src="../../assets/yuebao/yeb-io-icode.png" /> 
          {{$t('yebInOut.securitDes')}}
        </div>
        <div class="row-yeb flex amount">
          {{$t('yebInOut.yuebaoPwd')}}
        </div>
        <div class="row-yeb flex">
          <div>
            {{$t('yebInOut.yebTranserPwd')}}
          </div>
          <form class="yeb-wrapper" autocomplete="off">
            <input autocomplete="false" name="hidden" style="display:none;">
            <input class="yeb-io-input" :placeholder="$t('yebInOut.pwdPlaceholder')" 
              v-model="transactionPwd" autocomplete="new-password"
              type="password" />
            <img @click="forgotPwdClick" class="all-out-btn ease-btn-effe" 
              src="../../assets/yuebao/sfyz-forgot-pwd.png" />
          </form>
        </div>
        <img class="confirm-btn ease-btn-effe" 
          src="../../assets/confirm-btn.png" @click="confirmClick" />
      </div>
    </template>
  </PopWidget>
</template>

<script>
import { mapMutations } from 'vuex';
import PopWidget from '../common/PopWidget.vue'
export default{
  props:{
    show:Number,
    isOut:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
      transactionPwd:'',
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirmClick(){
      if(!this.transactionPwd){
        this.popMsg(this.$t('yebInOut.yebEmptyDes'));return;
      }
      this.$emit('password',this.transactionPwd)
      this.isShowPopWidget = 0;
      this.transactionPwd = '';
    },
    forgotPwdClick(){
      this.$emit('forgotClick')
    }
  },
  watch:{
    show(n){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget,
  }
}
</script>

<style scoped lang="stylus">
.yeb-io-wrap
  width 100%
  height 2.08rem
  padding-top .2rem
  &.isout
    padding-top .22rem
  .confirm-btn
    width 1.2rem
    margin-top .2rem
  .yeb-io-icode
    width .2rem
    margin-right .08rem
  .row-yeb
    color #A4682A
    font-size .18rem
    justify-content center
    align-items center
    &.amount
      margin .08rem 0 .24rem 0
      color #EF5000
      font-size .24rem
    .yeb-wrapper
      position relative
    .yeb-io-input
      width 2.68rem
      height .32rem
      padding-left .1rem
      margin-left .2rem
      border-radius .14rem
      background-color #BCA078
    .all-out-btn
      position absolute
      right -.04rem
      top 0
      width .92rem
</style>